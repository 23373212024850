import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Title from '../../Home/Title';
import styles from './style.module.scss';

const Information = () => {
  const { t } = useTranslation();
  return (
    <>
      {/* Item 1 */}
      <div
        className={styles.item}
        style={{ backgroundImage: `url("./Frame.png")` }}
      >
        <div data-aos="fade-right">
          <div className={styles.itemWrap}>
            <div className={styles.left}>
              <Title
                title={t('information.biosphere_res')}
                subTitle={t('information.dong_nai')}
                background={false}
                reverse={false}
                border={false}
              />
              <div className={styles.content}>
                <p>{t('information.intro')}</p>
                <p>
                  {t('information.intro2')}
                  <ul>
                    <li>
                      <span>- {t('information.core_area')}:</span>{' '}
                      {t('information.Cat_Tien_national')}
                    </li>
                    <li>
                      <span>- {t('information.buffer_zone')}:</span>{' '}
                      {t('information.hectare')}
                    </li>
                    <li>
                      <span>- {t('information.transition_zone')}:</span> 297.994
                      {' ha'}
                    </li>
                    {/* <li>- {t('information.there_are_more')}</li> */}
                  </ul>
                </p>
              </div>
            </div>
            <div className={styles.right} style={{ marginTop: '110px' }}>
              <img src="/images/Rectangle3.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      {/* Item 2 */}
      <div className={styles.item}>
        <div data-aos="fade-left">
          <div className={classnames(styles.itemWrap, styles.reverse)}>
            <div className={styles.left}>
              <Title
                title={t('information.the_features_of')}
                subTitle={t('information.biosphere_reserve')}
                background={false}
                reverse={false}
                border={false}
              />
              <div className={styles.content}>
                <ul>
                  <li>- {t('information.there_is_biodiversity')}</li>
                  <li>+ {t('information.species_of_higher')}</li>
                  <li>+ {t('information.species_of_wild_animals')}</li>
                  <li>- {t('information.sistuated_at_the_center')}</li>
                  <li>- {t('information.formerly_a_revolutionary')}</li>
                </ul>
                <div className={styles.flexRight}>
                  <img src="/images/Rectangle5.png" alt="" />
                </div>
              </div>
            </div>
            <div className={styles.right}>
              <img src="/images/Rectangle4.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      {/* Item 3 */}
      <div className={classnames(styles.item, styles.backgroundGreen)}>
        <div data-aos="fade-right">
          <div className={classnames(styles.itemWrap)}>
            <div className={styles.left}>
              <Title
                title={t('information.orientation_of_development')}
                subTitle=""
                background={false}
                reverse={false}
                border={false}
              />
              <div className={styles.content}>
                <ul>
                  <li>- {t('information.biosphere_reserve_is_a_model')}</li>
                  <li>- {t('information.inter_sectoral_coordination')}</li>
                  <li>{t('information.economic_of_qualities')}</li>
                </ul>
              </div>
            </div>
            <div className={styles.right}>
              <img src="/images/Rectangle6.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      {/* Item 4 */}
      <div className={classnames(styles.item)}>
        <div data-aos="fade-left">
          <div className={classnames(styles.itemWrap)}>
            <div className={styles.left}>
              <img src="/images/Rectangle7.png" alt="" />
            </div>
            <div className={styles.right}>
              <Title
                title={t('information.action_plan')}
                subTitle=""
                background={false}
                reverse={false}
                border={false}
              />
              <div className={styles.content}>
                <ul>
                  <li>+ {t('information.communication_env_education')}</li>
                  <li>+ {t('information.enhancing_the_livelihood')}</li>
                  <li>+ {t('information.study_the_biodiverity')}</li>
                  <li>+ {t('information.developing_brand_awareness')}</li>
                  <li>+ {t('information.cooperation_in_investment')}</li>
                  <li>+ {t('information.human_resource_training')}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Item 5 */}
      <div className={classnames(styles.item)}>
        <div data-aos="fade-right">
          <div className={classnames(styles.itemWrap)}>
            <div className={styles.left}>
              <Title
                title={t('information.management_mechanism')}
                subTitle=""
                background={false}
                reverse={false}
                border={false}
              />
              <div className={styles.content}>
                <ul>
                  <li>{t('information.biosphere_reserve_has_organized')} </li>
                  <li>
                    <span>
                      - {t('information.biosphere_reserve_management_board')}
                    </span>
                    {t('information.biosphere_reserve_management_board1')}
                  </li>
                  <li>
                    <span>- {t('information.advisory_council')} </span>
                    {t('information.president_vice')}
                  </li>
                  <li>
                    <span>- {t('information.secretariat')} </span>
                    {t('information.assisting_the_management')}
                  </li>
                </ul>
              </div>
            </div>
            <div className={styles.right}>
              <img src="/images/Rectangle8.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      {/* Item 6 */}
      <div className={classnames(styles.item, styles.backgroundGreen)}>
        <div className={classnames(styles.itemWrap, styles.reverse)}>
          <div className={styles.left}>
            <Title
              title={t('information.coordination_mechanism')}
              subTitle={t('information.organizations_and_departments')}
              background={false}
              reverse
              border={false}
            />
            <div className={styles.content}>
              <p>{t('information.because_the_biosphere_reserve')}</p>
              <p>{t('information.the_activities')}</p>
            </div>
          </div>
          <div className={styles.right}>
            <img src="/images/Rectangle9.png" alt="" />
          </div>
        </div>
      </div>
      {/* Item 7 */}
      <div className={styles.item}>
        <div className={styles.itemWrap}>
          <div className={styles.left}>
            <Title
              title={t('information.responsibilities_of_people')}
              subTitle={t('information.biosphere_reserve')}
              background={false}
              reverse={false}
              border={false}
            />
            <div className={styles.content}>
              <ul>
                <li>- {t('information.environmental_protection')}</li>
                <li>- {t('information.having_the_right')}</li>
                <li>- {t('information.responsible_for_the_protection')}</li>
              </ul>
            </div>
            <div className={styles.flexRight}>
              <img src="/images/Rectangle11.png" alt="" />
            </div>
          </div>
          <div className={styles.right}>
            <img src="/images/Rectangle10.png" alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Information;
