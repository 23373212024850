import i18n from 'i18next';
import { useTranslation, initReactI18next } from 'react-i18next';

import translationEN from './en.json';
import translationVI from './vi.json';

const resources = {
  en: {
    translation: translationEN,
  },
  vn: {
    translation: translationVI,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'vn',
  fallbackLng: 'vn',
  debug: false,
  interpolation: {
    escapeValue: false,
  },
});

type Join<S1, S2> = S1 extends string
  ? S2 extends string
    ? `${S1}.${S2}`
    : never
  : never;

export type Paths<T> = {
  [K in keyof T]: T[K] extends Record<string, unknown>
    ? Join<K, Paths<T[K]>>
    : K;
}[keyof T];

type TranslationKeys = Paths<typeof translationEN>;

interface DateTranslationType {
  time?: Date;
  date?: Date;
}

export const useTypeSafeTranslation = () => {
  const { t } = useTranslation();

  return {
    t: (s: TranslationKeys, f?: DateTranslationType) => t(s),
  };
};

const storedLanguage = localStorage.getItem('lang');

if (storedLanguage) {
  i18n.changeLanguage(storedLanguage);
}

export default i18n;
