import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';

type TProps = {
  image?: string;
  title?: string;
  subTitle?: string;
};

const Slider = (props: TProps) => {
  const { image, title, subTitle } = props;
  const { t } = useTranslation();
  return (
    <div className={styles.background}>
      <div className={styles.title}>
        <div data-aos="fade-left">
          <p>{title ?? t('slide.category')}</p>
          <h2>{subTitle ?? t('tab.about')}</h2>
        </div>
      </div>
      <img
        src={image ? `/images/${image}` : '/images/Rectangle2.jpg'}
        alt="Rectangle2"
      />
      <img className={styles.glass} src="./images/glass.png" alt="glass" />
    </div>
  );
};

export default Slider;
