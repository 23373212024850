import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getListTour } from '../Service/service';
import { Tour } from '../../models/tour';
import { LanguageState } from '../../reducer/languageReducer';

export const useService = () => {
  const { id } = useParams();
  const language = useSelector(
    (state: LanguageState) => state.language.language,
  );
  const [currentTour, setCurrenTour] = useState<Tour>();
  useEffect(() => {
    const listTour = getListTour();
    const currentTours = listTour.find((item) => item.id === id);
    setCurrenTour(currentTours);
  }, [language]);
  return {
    currentTour,
  };
};
