import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Item from '../../components/News/Item';
import styles from './style.module.scss';
import { useService } from './service';
import Pagination from '../../components/Pagination';

const News = () => {
  const { news, handleNavigate, getListNews } = useService();
  const { t } = useTranslation();

  console.log(news);

  return (
    <div className={styles.news}>
      <div className={styles.background}>
        <p>{t('pages/News.home_news')}</p>
        <img src="/background/newBg.jpg" alt="newBg" />
      </div>
      <div className={styles.news__wrap}>
        <div className={styles.content}>
          <div className={styles.left}>
            {!news?.DataList.length && <p>Không có tin tức</p>}
            {news?.DataList.length && news?.DataList[0] && (
              <Item
                imageUrl={news?.DataList[0].Image.replace(
                  'https://dongnaibr.vn',
                  'http://beta.dongnaibr.vn',
                )}
                key={news?.DataList[0].ID}
                title={news?.DataList[0].Name}
                description={news?.DataList[0].Description}
                id={news?.DataList[0].ID || 0}
                handleNavigate={handleNavigate}
                fullStyle
              />
            )}
            <div className={styles.listItem}>
              {news?.DataList.map(
                (newsItem, index) =>
                  index >= 1 && (
                    <Item
                      imageUrl={newsItem.Image.replace(
                        'https://dongnaibr.vn',
                        'http://beta.dongnaibr.vn',
                      )}
                      key={newsItem.ID}
                      title={newsItem.Name}
                      description={newsItem.Description}
                      id={newsItem.ID}
                      handleNavigate={handleNavigate}
                    />
                  ),
              )}
            </div>
          </div>
        </div>
        {news?.DataList && news?.DataList.length > 0 && (
          <Pagination
            pageCount={news.TotalPage}
            onPageChange={(selected: number) => {
              getListNews(selected + 1);
              window.scrollTo(0, 0);
            }}
          />
        )}
      </div>
      <div className={styles.backgroundBottom}>
        <img src="/images/maskGroup.png" alt="backgroundNews" />
      </div>
    </div>
  );
};

export default News;
