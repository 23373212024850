import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import styles from './style.module.scss';
import { useService } from './service';

const Payment2 = () => {
  const { state } = useLocation();
  const price = state;
  const [count, setCount] = useState({
    people: 0,
  });
  const { t } = useTranslation();
  const { currentTour } = useService();

  function incrementCount() {
    setCount({ ...count, people: count.people + 1 });
  }
  function decrementCount() {
    setCount({ ...count, people: count.people - 1 });
  }

  function className(arrowTopRight: string): string | undefined {
    throw new Error('Function not implemented.');
  }

  return (
    <div className={styles.service}>
      <div className={styles.background}>
        <img src="/images/backgroundService.png" alt="p21" />
      </div>
      <div className={styles.service__wrap}>
        <div className={styles.content}>
          <div className={styles.title}>
            <h2>{t('pages/Payment2.payment')}</h2>
          </div>
          <div className={styles.payment}>
            <span>{t('pages/Payment2.point_the_camera')}</span>
            <div className={styles.qrImage}>
              <img src="./images/qrcode.png" alt="qrcode" />
              <p>{t('pages/Payment2.scan_to_pay')}</p>
              <div className={classnames(styles.arrowTopLeft, styles.arrown)} />
              <div
                className={classnames(styles.arrowTopRight, styles.arrown)}
              />
              <div
                className={classnames(styles.arrowBottomLeft, styles.arrown)}
              />
              <div
                className={classnames(styles.arrowBottomRight, styles.arrown)}
              />
            </div>
            <div className={styles.total}>{`${price ?? 0} VNĐ`}</div>
            <div className={styles.note}>
              <p>{t('pages/Payment2.other_payment_methods')}:</p>
              <span>VNPAY, VIETCOMBANK, TECHCOMBANK</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment2;
