import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Slider from '../../components/Aboutme/Slider';
import styles from './style.module.scss';
import { useTypeSafeTranslation } from '../../i18n/i18n';

const Gallery = () => {
  const [title, setTitle] = useState('');
  const location = useLocation();
  const { t } = useTypeSafeTranslation();

  useEffect(() => {
    if (location.state) {
      setTitle(
        `${options[location.pathname.split('/')[2]]} > ${location.state.title}`,
      );
    } else {
      setTitle(options[location.pathname.split('/')[2]]);
    }
  }, [location]);

  const options: any = {
    'thuc-vat': t('header.plants'),
    'dong-vat': t('header.animals'),
  };

  return (
    <div className={styles.test}>
      <div className={styles.bg}>
        <Slider
          image="tree-bg.png"
          title={t('header.library')}
          subTitle={title}
        />
      </div>
      <div className={styles.gallery}>
        <img src="/images/glass-bg.png" alt="" />
        <div className={styles.gallery__wrap}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Gallery;
