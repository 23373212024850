import React, { useEffect } from 'react';
import Donate from '../../components/Home/Donate';
import Info from '../../components/Home/Info';
import News from '../../components/Home/News';
import Slider from '../../components/Home/Slider';
import Travel from '../../components/Home/Travel';
import styles from './style.module.scss';
import { useService } from './service';

const Home = () => {
  const { introduce, services, news, isLoading, handleNavigate } = useService();

  return (
    <div className={styles.test}>
      <Slider />
      <Info introduce={introduce} isLoading={isLoading} />
      <News
        services={services}
        isLoading={isLoading}
        news={news}
        handleNavigate={handleNavigate}
      />
      <Travel />
      <Donate />
    </div>
  );
};

export default Home;
