import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getListTour } from '../Service/service';
import { Tour } from '../../models/tour';

export const useService = () => {
  const { id } = useParams();
  const [currentTour, setCurrenTour] = useState<Tour>();
  useEffect(() => {
    const listTour = getListTour();
    const currentTours = listTour.find((item) => item.id === id);
    setCurrenTour(currentTours);
  }, []);
  return {
    currentTour,
  };
};
