import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Tabs from '../../components/DetailService/Tabs';
import styles from './style.module.scss';
import { useService } from './service';
import { Price } from '../../models/tour';

type customer =
  | 'children'
  | 'peopleWithDisabilities'
  | 'elderly'
  | 'pupil'
  | 'student'
  | 'adults';

const Payment = () => {
  const { state } = useLocation();
  const { t } = useTranslation();
  const price = state.price as Price;
  const [children, setChildren] = useState({
    people: 0,
  });
  const [peopleWithDisabilities, setPeopleWithDisabilities] = useState({
    people: 0,
  });
  const [elderly, setElderly] = useState({
    people: 0,
  });
  const [pupil, setPupil] = useState({
    people: 0,
  });
  const [student, setStudent] = useState({
    people: 0,
  });
  const [adults, setAdults] = useState({
    people: 0,
  });

  const { currentTour } = useService();
  const navigate = useNavigate();

  function incrementCount(type: customer) {
    switch (type) {
      case 'children':
        setChildren({ ...children, people: children.people + 1 });
        break;
      case 'peopleWithDisabilities':
        setPeopleWithDisabilities({
          ...peopleWithDisabilities,
          people: peopleWithDisabilities.people + 1,
        });
        break;
      case 'elderly':
        setElderly({ ...elderly, people: elderly.people + 1 });
        break;
      case 'pupil':
        setPupil({ ...pupil, people: pupil.people + 1 });
        break;
      case 'student':
        setStudent({ ...student, people: student.people + 1 });
        break;
      case 'adults':
      default:
        setAdults({ ...adults, people: adults.people + 1 });
    }
  }

  function decrementCount(type: customer) {
    switch (type) {
      case 'children':
        setChildren({ ...children, people: children.people - 1 });
        break;
      case 'peopleWithDisabilities':
        setPeopleWithDisabilities({
          ...peopleWithDisabilities,
          people: peopleWithDisabilities.people - 1,
        });
        break;
      case 'elderly':
        setElderly({ ...elderly, people: elderly.people - 1 });
        break;
      case 'pupil':
        setPupil({ ...pupil, people: pupil.people - 1 });
        break;
      case 'student':
        setStudent({ ...student, people: student.people - 1 });
        break;
      case 'adults':
      default:
        setAdults({ ...adults, people: adults.people - 1 });
    }
  }

  const submit = () => {
    navigate('/hoan-thanh', { state: getAllPrice().toLocaleString('vi-VN') });
  };

  function getAllCustomer() {
    let total = '';
    if (adults.people > 0) {
      total += `${adults.people} ${t('pages/Payment.adult')}`;
    }
    if (elderly.people > 0) {
      total += `, ${elderly.people} ${t('pages/Payment.elder')}`;
    }
    if (children.people > 0) {
      total += `, ${children.people} ${t('pages/Payment.children')}`;
    }
    if (student.people > 0) {
      total += `, ${student.people} ${t('pages/Payment.university_student')}`;
    }
    if (peopleWithDisabilities.people > 0) {
      total += `, ${peopleWithDisabilities.people} ${t(
        'pages/Payment.people_with_disabilities',
      )}`;
    }
    if (pupil.people > 0) {
      total += `, ${pupil.people} ${t('pages/Payment.student')}`;
    }
    return total;
  }

  const getAllPrice = () =>
    adults.people * price.adults +
    elderly.people * price.elderly +
    children.people * price.children +
    student.people * price.student +
    peopleWithDisabilities.people * price.peopleWithDisabilities +
    pupil.people * price.pupil;

  return (
    <div className={styles.service}>
      <div className={styles.background}>
        <img src="/images/backgroundService.png" alt="p21" />
      </div>
      <div className={styles.service__wrap}>
        <div className={styles.content}>
          <div className={styles.title}>
            <h2>{t('pages/Payment.tour_booking_infomation')}</h2>
          </div>
          <div className={styles.info}>
            <p className={styles.total}>Số lượng người</p>
            <div className={styles.info__wrap}>
              <div className={styles.item}>
                <div className={styles.left}>
                  <p>{t('pages/Payment.adult')}</p>
                  <span>{t('pages/Payment.16_years')}</span>
                </div>
                <div className={styles.right}>
                  <button
                    onClick={() => decrementCount('adults')}
                    type="button"
                  >
                    -
                  </button>
                  <div className={styles.count}>{adults.people ?? 0}</div>
                  <button
                    onClick={() => incrementCount('adults')}
                    type="button"
                  >
                    +
                  </button>
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.left}>
                  <p>{t('pages/Payment.elder')}</p>
                  <span>{t('pages/Payment.60_years')}</span>
                </div>
                <div className={styles.right}>
                  <button
                    onClick={() => decrementCount('elderly')}
                    type="button"
                  >
                    -
                  </button>
                  <div className={styles.count}>{elderly.people ?? 0}</div>
                  <button
                    onClick={() => incrementCount('elderly')}
                    type="button"
                  >
                    +
                  </button>
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.left}>
                  <p>{t('pages/Payment.children')}</p>
                  <span>{t('pages/Payment.2_years')}</span>
                </div>
                <div className={styles.right}>
                  <button
                    onClick={() => decrementCount('children')}
                    type="button"
                  >
                    -
                  </button>
                  <div className={styles.count}>{children.people ?? 0}</div>
                  <button
                    onClick={() => incrementCount('children')}
                    type="button"
                  >
                    +
                  </button>
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.left}>
                  <p>{t('pages/Payment.university_student')}</p>
                  <span>{t('pages/Payment.school')}</span>
                </div>
                <div className={styles.right}>
                  <button
                    onClick={() => decrementCount('student')}
                    type="button"
                  >
                    -
                  </button>
                  <div className={styles.count}>{student.people ?? 0}</div>
                  <button
                    onClick={() => incrementCount('student')}
                    type="button"
                  >
                    +
                  </button>
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.left}>
                  <p>{t('pages/Payment.people_with_disabilities')}</p>
                  <span>
                    {t('pages/Payment.physical_or_mental_impairments')}
                  </span>
                </div>
                <div className={styles.right}>
                  <button
                    onClick={() => decrementCount('peopleWithDisabilities')}
                    type="button"
                  >
                    -
                  </button>
                  <div className={styles.count}>
                    {peopleWithDisabilities.people ?? 0}
                  </div>
                  <button
                    onClick={() => incrementCount('peopleWithDisabilities')}
                    type="button"
                  >
                    +
                  </button>
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.left}>
                  <p>{t('pages/Payment.student')}</p>
                  <span>
                    {t('pages/Payment.physical_or_mental_impairments')}
                  </span>
                </div>
                <div className={styles.right}>
                  <button onClick={() => decrementCount('pupil')} type="button">
                    -
                  </button>
                  <div className={styles.count}>{pupil.people ?? 0}</div>
                  <button onClick={() => incrementCount('pupil')} type="button">
                    +
                  </button>
                </div>
              </div>
            </div>
            <p className={styles.total}>
              {t('pages/Payment.total_cost')}{' '}
              <span>{`${getAllCustomer()}`}</span>
            </p>
            <h2>{`${getAllPrice().toLocaleString('vi-VN')} VNĐ`}</h2>
            <span>* {t('pages/Payment.this_payment')}</span>
            <p className={styles.address}>{state?.address}</p>
            <p className={styles.phone}>
              <i>
                <img src="/images/phone.svg" alt="" />
              </i>
              {t('pages/Payment.telepphone')}: (+84) 2513 961
            </p>
          </div>
          <div className={styles.form}>
            <p>{t('pages/Payment.contact_information')}</p>
            <div className={styles.formItem}>
              <div className={styles.label}>
                {t('pages/Payment.full_name')}
                <span>*</span>
              </div>
              <input type="text" />
            </div>
            <div className={styles.formItem}>
              <div className={styles.label}>
                {t('pages/Payment.full_name')}
                <span>*</span>
              </div>
              <input type="text" />
            </div>
            <div className={styles.formItem}>
              <div className={styles.label}>
                {t('pages/Payment.full_name')}
                <span>*</span>
              </div>
              <input type="text" />
            </div>
            <span>*{t('pages/Payment.the_information')}</span>
            <div className={styles.buttonWrap}>
              <button className={styles.button} type="submit" onClick={submit}>
                {t('pages/Payment.continue')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
