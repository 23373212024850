import React, { useEffect } from 'react';
import Information from '../../components/Aboutme/Information';
import Slider from '../../components/Aboutme/Slider';
import Tours from '../../components/Aboutme/Tours';
import styles from './style.module.scss';

const Aboutme = () => {
  return (
    <div className={styles.test}>
      <Slider />
      <Information />
      <Tours />
    </div>
  );
};

export default Aboutme;
