import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../Button';
import styles from './style.module.scss';

type Props = {
  imageUrl: string;
  title: string;
  handleNavigate: (id: number) => void;
  id: number;
};
const Item = ({ imageUrl, title, handleNavigate, id }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrap}>
      <div className={styles.image}>
        <img src={imageUrl} alt="dongco" />
        <div className={styles.sale}>
          SALE <br />
          40%
        </div>
      </div>
      <h2>{title}</h2>
      <Button text={t('info.detail')} onclick={() => handleNavigate(id)} />
    </div>
  );
};

export default Item;
