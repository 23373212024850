import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '../../Button';
import styles from './style.module.scss';
import Title from '../Title';

const Info = ({
  introduce,
  isLoading,
}: {
  introduce: string | undefined;
  isLoading: boolean;
}) => {
  const { t } = useTranslation();
  const nagigate = useNavigate();

  const onClick = () => {
    nagigate('/aboutme');
  };

  return (
    <div className={styles.info}>
      <div className={styles.infoWrap}>
        <div className={styles.infoWrap__left}>
          <div className={styles.title}>
            <div data-aos="fade-left">
              <Title
                title={t('info.biosphere_reserve')}
                subTitle={t('info.dong_nai')}
                background={false}
                reverse={false}
              />
            </div>
          </div>
          <div data-aos="fade-right">
            {introduce && (
              <p style={{ maxLines: 7, overflow: 'hidden' }}>
                {introduce.split('\n')[1]}
                {introduce.split('\n')[2]}...
              </p>
            )}
          </div>
          <div className={styles.button}>
            <Button text={t('info.detail')} onclick={onClick} />
          </div>
        </div>
        <div className={styles.infoWrap__right}>
          <img src="/images/monkey.png" alt="monkey" />
        </div>
      </div>
      <img className={styles.glass} src="./images/glass.png" alt="glass" />
    </div>
  );
};

export default Info;
