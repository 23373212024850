import React from 'react';
import styles from './style.module.scss';
import { ListSchedule } from '../../../models/tour';

type Props = {
  schedule: ListSchedule;
};
const Schedule = ({ schedule }: Props) => {
  return (
    <>
      <h2 className={styles.scheduleTitle}>
        {`NGÀY ${Number(schedule.day) + 1}`}
      </h2>
      <ul className={styles.scheduleList}>
        {schedule.schedule.map((item) => {
          return (
            <div key={item.time}>
              <li>
                {`${item.time ?? ''} ${item.time && `:`} ${item.title ?? ''}`}
              </li>
              {item.image.map((image) => {
                return (
                  <div key={image} className={styles.img}>
                    <img src={image} alt="" />
                  </div>
                );
              })}
            </div>
          );
        })}
      </ul>
    </>
  );
};

export default Schedule;
