import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Item from '../../components/News/Item';
import styles from './style.module.scss';
import { useService } from './service';
import Pagination from '../../components/Pagination';
import { TNew, TNews } from '../../models/news';

const PAGE_LIMIT = 3;
const OFF_SET_DF = 0;

const Documents = () => {
  const [limit] = useState(PAGE_LIMIT);
  const [offsetDf] = useState(OFF_SET_DF);
  const [data, setData] = useState<TNew[]>();
  const { news, documents } = useService();
  const { t } = useTranslation();

  const cutDataPerPage = useCallback(
    (dataNews: TNews, offset?: number) => {
      const valOffset = offset || offsetDf;
      if (dataNews.DataList.length) {
        setData(dataNews.DataList.slice(valOffset, valOffset + limit));
      }
    },
    [limit, offsetDf],
  );

  useMemo(() => {
    if (news?.DataList.length) {
      cutDataPerPage(news);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [news]);

  return (
    <div className={styles.documents}>
      <div className={styles.background}>
        <p>{t('pages/News.home_documents')}</p>
        <img src="/background/documentsBg.jpg" alt="documentsBg" />
      </div>
      <div className={styles.documents__wrap}>
        <ul>
          {documents?.DataList.length &&
            documents?.DataList.map((item) => {
              return (
                <li key={item.ID}>
                  <a href={item.Description} target="_blank" rel="noreferrer">
                    <img src="/background/pdf.svg" alt="pdf" />
                    {item.Name}
                    <img src="/background/download.svg" alt="download" />
                  </a>
                </li>
              );
            })}
        </ul>
      </div>
      <div className={styles.backgroundBottom}>
        <img src="/images/maskGroup.png" alt="backgroundNews" />
      </div>
    </div>
  );
};

export default Documents;
