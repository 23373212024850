import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LanguageState } from '../../reducer/languageReducer';
import axios from '../../lib/axios';
import { NEWS_DETAIL, NEWS } from '../../lib/endpoint';
import { Animals } from '../../models/animals';

type Props = {
  id: number;
};

export const useService = (props: Props) => {
  const { id } = props;
  const language = useSelector(
    (state: LanguageState) => state.language.language,
  );
  const [animal, setAnimal] = useState<Animals>();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    Promise.all([getListNews()])
      .then((responses) => {
        setAnimal(responses[0]);
      })
      .catch((e) => {
        throw e;
      })
      .finally(() => setIsLoading(false));
  }, [language]);
  async function getListNews(): Promise<Animals> {
    const res = await axios.post(NEWS_DETAIL, {
      id,
      lang: language,
    });
    return res.data.d;
  }

  return {
    animal,
    getListNews,
    isLoading,
    setIsLoading,
  };
};
