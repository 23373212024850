import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LanguageState } from '../../reducer/languageReducer';
import axios from '../../lib/axios';
import { SERVICE, NEWS } from '../../lib/endpoint';
import { Animals } from '../../models/animals';
import { TNews } from '../../models/news';

type Props = {
  categoryId: number;
  title: string;
};

export const useService = (props: Props) => {
  const { t } = useTranslation();
  const { categoryId, title } = props;
  const language = useSelector(
    (state: LanguageState) => state.language.language,
  );
  const [animals, setAnimals] = useState<[]>();
  const [animalItem, setAnimalsItem] = useState<TNews>();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const options: any = {
    'thuc-vat': 274,
    'dong-vat': 271,
    'sinh-canh-nui-rung': 296,
    'sinh-canh-song-nuoc': 297,
  };
  useEffect(() => {
    Promise.all([getListService(), getListNews()])
      .then((responses) => {
        setAnimals(responses[0]);
        setAnimalsItem(responses[1]);
      })
      .catch((e) => {
        throw e;
      })
      .finally(() => setIsLoading(false));
  }, [location, categoryId, language]);
  const handleNavigate = (Id: number, type: string, Title: string) => {
    window.scrollTo(0, 0);
    const param = location.pathname.split('/')[2];
    if (type === 'animal') {
      navigate(`/thu-vien/${param}`, { state: { id: Id, title: Title } });
    } else if (type === 'animalItem') {
      navigate(`/chi-tiet-${param}`, {
        state: {
          id: Id,
          title,
          name: param === 'thuc-vat' ? t('header.plants') : t('header.animals'),
          categoryId,
        },
      });
    }
  };
  async function getListNews(): Promise<TNews> {
    const res = await axios.post(NEWS, {
      categoryid: categoryId || 0,
      lang: language,
      currentpage: '1',
      pageSize: '10',
    });
    return res.data.d;
  }
  async function getListService(): Promise<[]> {
    const res = await axios.post(SERVICE, {
      categoryid: options[location.pathname.split('/')[2]],
      lang: language,
      currentpage: '1',
      pageSize: '50',
    });
    return res.data.d;
  }

  return {
    animals,
    animalItem,
    isLoading,
    setIsLoading,
    handleNavigate,
  };
};
