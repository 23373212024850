import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styles from './style.module.scss';

const Donate = () => {
  const { state } = useLocation();

  const { t } = useTranslation();

  return (
    <div className={styles.service}>
      <div className={styles.background}>
        <p>{t('pages/News.home_donate')}</p>
        <img src="/background/donateBg.jpg" alt="donateBg" />
      </div>
      <div className={styles.service__wrap}>
        <div className={styles.content}>
          <p className={styles.number}>
            {/* <span className={styles.index}>1</span> */}
            {t('donate.bonus_to_address')}
          </p>
          <img src="/background/logoDongnai.svg" alt="logoDongnai" />
          <p className={styles.title}>{t('donate.dong_nai_area')}</p>
          <span>{t('donate.address')}</span>
          <span> {t('donate.phone')} 0251 3961 484 / Fax: 0251 3961 484</span>
          <span>
            {' '}
            Email: <a href="mailto:dongnaibr@gmail.com">dongnaibr@gmail.com</a>
          </span>
          {/* <p className={styles.number}>
            <span className={styles.index}>2</span>
            {t('donate.bonus_to_bank')}
          </p>
          <p className={styles.title}>
            {t('donate.dong_nai_bank')} <br />
            {t('donate.bank_acc')} 09876543210
          </p>
          <span>{t('donate.qr_method')}</span>
          <img src="./images/qrcode.png" alt="qrcode" />
          <p className={styles.title}>{t('donate.thanks')}</p> */}
        </div>
      </div>
    </div>
  );
};

export default Donate;
