import React from 'react';
import { useTranslation } from 'react-i18next';
import Title from '../Title';
import Item from './item';
import styles from './style.module.scss';
import SubItem from './subItem';
import SubItemLoading from './subItemLoading';
import { Services } from '../../../models/services';
import { TNews } from '../../../models/news';
import { itemContent } from './news.state';

const News = ({
  services,
  isLoading,
  news,
  handleNavigate,
}: {
  services: Services[];
  isLoading: boolean;
  news: TNews | undefined;
  handleNavigate: (id: number) => void;
}) => {
  const { t } = useTranslation();
  console.log(services[0]?.Description);

  return (
    <div className={styles.news}>
      <div className={styles.news__wrap}>
        <div className={styles.title}>
          <Title
            title={t('news.news')}
            subTitle={t('news.and_comments_from_experts')}
            background={false}
            reverse
          />
        </div>
        <div className={styles.itemWrap}>
          {news?.DataList.length &&
            news.DataList.slice(0, 3).map((item) => {
              return (
                <Item
                  key={item.ID}
                  news={item}
                  handleNavigate={handleNavigate}
                />
              );
            })}
        </div>
        <div className={styles.title2}>
          <Title
            title={t('news.travel_services')}
            subTitle={t('news.at_the_biosphere_reserve')}
            background
            reverse={false}
          />
        </div>

        <div className={styles.itemWrap2}>
          <div className={styles.left}>
            {isLoading ? (
              <div>
                <SubItemLoading />
                <SubItemLoading />
              </div>
            ) : (
              <div>
                <div data-aos="fade-left">
                  <SubItem
                    title={services[0]?.Name ?? ''}
                    des={t('home/news.item1')}
                  />
                  <SubItem
                    title={services[1]?.Name ?? ''}
                    des={t('home/news.item2')}
                  />
                </div>
              </div>
            )}
          </div>
          <div className={styles.center}>
            <img src="/images/imagesChild.png" alt="imagesChild" />
          </div>
          <div className={styles.right}>
            {isLoading ? (
              <div>
                <SubItemLoading right />
                <SubItemLoading right />
              </div>
            ) : (
              <div>
                <div data-aos="fade-right">
                  <SubItem
                    title={services[2]?.Name ?? ''}
                    des={t('home/news.item3')}
                    right
                  />
                  <SubItem
                    title={services[3]?.Name ?? ''}
                    des={t('home/news.item4')}
                    right
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default News;
