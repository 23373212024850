export const images = 'src/assets/icon/';

export const img_logo = `${images}img_logo.png`;
export const ic_filter = `${images}ic_filter.png`;
export const flag_eng = `${images}flag_eng.svg`;
export const flag_vn = `${images}flag_vn.svg`;
export const ic_unselect_language = `${images}ic_unselect_language.svg`;
export const ic_select_language = `${images}ic_select_language.svg`;
export const im_splash = `${images}im_splash.png`;
export const ic_logo_app = `${images}ic_logo_app.svg`;
export const ic_line_splash = `${images}ic_line_splash.svg`;

export const ic_navigation_br_select = `${images}ic_navigation_br_select.svg`;
export const ic_navigation_br_unselect = `${images}ic_navigation_br_unselect.svg`;
export const ic_navigation_home_select = `${images}ic_navigation_home_select.svg`;
export const ic_navigation_home_unselect = `${images}ic_navigation_home_unselect.svg`;
export const ic_navigation_news_select = `${images}ic_navigation_news_select.svg`;
export const ic_navigation_news_unselect = `${images}ic_navigation_news_unselect.svg`;
export const ic_navigation_setting_select = `${images}ic_navigation_setting_select.svg`;
export const ic_navigation_setting_unselect = `${images}ic_navigation_setting_unselect.svg`;
export const ic_navigation_tour_select = `${images}ic_navigation_tour_select.svg`;
export const ic_navigation_tour_unselect = `${images}ic_navigation_tour_unselect.svg`;

export const im_backgroud_bg_tab = `${images}im_backgroud_bg_tab.png`;

export const ic_setting_fb = `${images}ic_setting_fb.svg`;
export const ic_setting_location = `${images}ic_setting_location.svg`;
export const ic_setting_mail = `${images}ic_setting_mail_.svg`;
export const ic_setting_mail_gift = `${images}ic_setting_mail_gift.svg`;
export const ic_setting_phone = `${images}ic_setting_phone.svg`;
export const ic_setting_translate = `${images}ic_setting_translate.svg`;
export const ic_setting_tutorial = `${images}ic_setting_tutorial.svg`;
export const ic_setting_zalo = `${images}ic_setting_zalo.svg`;
export const ic_setting_arrow = `${images}ic_setting_arrow.svg`;
export const im_loading_test = `${images}im_loading_test.png`;
export const ic_news_arrow = `${images}ic_news_arrow.svg`;
export const ic_back = `${images}ic_back.svg`;

export const ic_action_camp = `${images}ic_action_camp.svg`;
export const ic_action_community = `${images}ic_action_community.svg`;
export const ic_action_cycling = `${images}ic_action_cycling.svg`;
export const ic_action_ecological = `${images}ic_action_ecological.svg`;
export const ic_action_education = `${images}ic_action_education.svg`;
export const ic_action_hiking = `${images}ic_action_hiking.svg`;
export const ic_action_history = `${images}ic_action_history.svg`;
export const ic_action_rowing = `${images}ic_action_rowing.svg`;
export const ic_action_swim = `${images}ic_action_swim.svg`;
export const ic_action_visit = `${images}ic_action_visit.svg`;
export const ic_action_wading_stream = `${images}ic_action_wading_stream.svg`;
export const ic_action_car = `${images}ic_action_car.svg`;

export const image_tour_1 = `${images}image_tour_1.png`;
export const image_tour_2 = `${images}image_tour_2.png`;
export const image_tour_3 = `${images}image_tour_3.png`;
export const image_tour_4 = `${images}image_tour_4.png`;
export const image_tour_5 = `${images}image_tour_5.png`;

export const image_tour_6 = `${images}image_tour_6.png`;
export const image_tour_7 = `${images}image_tour_7.png`;
export const image_tour_8 = `${images}image_tour_8.png`;
export const image_tour_9 = `${images}image_tour_9.png`;
export const image_tour_10 = `${images}image_tour_10.png`;
export const image_tour_11 = `${images}image_tour_11.png`;
export const image_tour_12 = `${images}image_tour_12.png`;
export const image_tour_13 = `${images}image_tour_13.png`;
export const dtsq_1_image = `${images}dtsq_1_image.png`;
export const dtsq_2_image = `${images}dtsq_2_image.png`;
export const dtsq_3_image = `${images}dtsq_3_image.png`;
export const iv_tour_see_more = `${images}iv_tour_see_more.jpg`;

export const ic_call_now = `${images}ic_call_now.svg`;
export const ic_back_tour = `${images}ic_back_tour.svg`;
export const ic_tour_overview_location = `${images}ic_tour_overview_location.svg`;

export const ic_tour_note_regulations = `${images}ic_tour_note_regulations.svg`;
export const ic_tour_note_skin = `${images}ic_tour_note_skin.svg`;
export const ic_tour_note_weather = `${images}ic_tour_note_weather.svg`;
export const ic_loading = `${images}ic_loading.png`;
export const ic_book_tour_minus = `${images}ic_book_tour_minus.svg`;
export const ic_book_tour_plus = `${images}ic_book_tour_plus.svg`;
export const im_qr_code_bank = `${images}im_qr_code_bank.png`;
export const ic_x_filter = `${images}ic_x_filter.svg`;
export const ic_setting_share = `${images}ic_setting_share.svg`;
export const ic_home_detail = `${images}ic_home_detail_2.svg`;

export const ic_change_language_small_en = `${images}ic_change_language_small_en.svg`;
export const ic_change_language_small_vn = `${images}ic_change_language_small_vn.svg`;
export const bg_not_internet = `${images}bg_not_internet.png`;
export const ic_not_internet = `${images}ic_not_internet.svg`;
