import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';
import { ListSchedule, Note, Tour } from '../../../models/tour';
import Schedule from './Schedule';

type Props = {
  des1: string;
  des2: string;
  listSchedule: ListSchedule[] | undefined;
  note: Note | undefined;
  currentTour: Tour | undefined;
};
const Tabs = ({ des1, des2, listSchedule, note, currentTour }: Props) => {
  const [indexTab, setIndexTab] = useState(1);
  const { t } = useTranslation();
  const handleSetTab = (index: number) => {
    if (index === indexTab) return;
    setIndexTab(index);
  };
  const isActive = (index: number) => index === indexTab;

  function getSchedule() {
    return (
      <>
        <p>{des1}</p>
        <p>{des2}</p>
        {listSchedule?.map((item) => {
          return <Schedule key={item.day} schedule={item} />;
        })}
      </>
    );
  }

  function getNote() {
    return (
      <>
        {note?.regulations.map((item, index) => {
          return <p key={Number(index)}>{item}</p>;
        })}
        {note?.skin.map((item, index) => {
          return <p key={Number(index)}>{item}</p>;
        })}
        {note?.weather.map((item, index) => {
          return <p key={Number(index)}>{item}</p>;
        })}
      </>
    );
  }

  function getContact() {
    const name = currentTour?.address.split('\n')[0];
    const address = currentTour?.address.split('\n')[1];
    return (
      <>
        <h2 className={styles.contactTitle}>{name}</h2>
        <ul className={styles.contactList}>
          {currentTour?.tel && (
            <li>
              <span>Phone : </span> {currentTour?.tel}
            </li>
          )}
          {currentTour?.fax && (
            <li>
              <span>Fax : </span> {currentTour?.fax}
            </li>
          )}
          {currentTour?.facebook && (
            <li>
              <span>Facebook : </span> {currentTour?.facebook}
            </li>
          )}
          {currentTour?.contact1 && <li>{currentTour?.contact1}</li>}
          {currentTour?.contact1 && <li>{currentTour?.contact2}</li>}
          {/* <li>
            <i>
              <img src="/images/EnvelopeSimple.svg" alt="" />
            </i>
            <span>{t('tab.email')}:</span> dongnaibr@gmail.com
          </li> */}
          {/* <li>
            <i>
              <img src="/images/headphone.svg" alt="" />
            </i>
            <span>{t('tab.online_support')}:</span>
            <ul className={styles.contactList__child}>
              <li>
                <i>
                  <img src="/images/zalo.svg" alt="" />
                  zalo
                </i>
              </li>
              <li>
                <i>
                  <img src="/images/facebook.svg" alt="" />
                  facebook
                </i>
              </li>
              <li>
                <i>
                  <img src="/images/messenger.svg" alt="" />
                  messenger
                </i>
              </li>
              <li>
                <i>
                  <img src="/images/phone.svg" alt="" />
                  (+84) 2513 961
                </i>
              </li>
            </ul>
          </li> */}
          {/* <li>
            <i>
              <img src="/images/ShareNetworkBlack.svg" alt="" />
            </i>
            <span>{t('tab.share_tour')}:</span>{' '}
            <ul className={styles.contactList__child}>
              <li>
                <i>
                  <img src="/images/ShareNetwork.svg" alt="" />
                  {t('tab.share_information')}
                </i>
              </li>
            </ul>
          </li> */}
        </ul>
      </>
    );
  }

  const handleCurrentTab = () => {
    switch (indexTab) {
      case 1:
        return getSchedule();
      case 2:
        return getNote();
      case 3:
        return getContact();
      default:
        return getSchedule();
    }
  };
  return (
    <>
      <ul className={styles.tabs}>
        <li className={isActive(1) ? styles.active : ''}>
          <button
            type="button"
            className={styles.btn}
            onClick={() => handleSetTab(1)}
          >
            {t('tab.itinerary')}
          </button>
        </li>
        <li className={isActive(2) ? styles.active : ''}>
          <button
            type="button"
            className={styles.btn}
            onClick={() => handleSetTab(2)}
          >
            {t('tab.note')}
          </button>
        </li>
        <li className={isActive(3) ? styles.active : ''}>
          <button
            type="button"
            className={styles.btn}
            onClick={() => handleSetTab(3)}
          >
            {t('tab.contact')}
          </button>
        </li>
      </ul>
      <article className={styles.article}>{handleCurrentTab()}</article>
    </>
  );
};

export default Tabs;
