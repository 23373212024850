import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import styles from './style.module.scss';

const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handelClick = () => {
    navigate('thu-vien/dong-vat');
  };

  return (
    <>
      <div
        className={styles.footer}
        style={{
          backgroundImage: `url("./images/backgroundFooter.jpg")`,
          backgroundSize: 'cover',
        }}
      >
        <img src="/images/footer.png" alt="footer" />
        <div className={styles.outSide}>
          <div className={styles.social}>
            <ul>
              <li>
                <img src="/images/phone.png" alt="phone" />
              </li>
              <li>
                <li>
                  <a href="https://www.facebook.com/dongnaibiospherereserve">
                    <img src="/images/facebook.png" alt="facebook" />
                  </a>
                </li>
              </li>
              <li>
                <img src="/images/messenger.png" alt="messenger" />
              </li>
              <li>
                <a href="mailto:dongnaibr@gmail.com">
                  <img src="/images/email.png" alt="email" />
                </a>
              </li>
            </ul>
          </div>
          <div className={styles.footerWrap}>
            <div className={styles.infomation}>
              <div className={styles.infomation__wrap}>
                <ul>
                  <li>
                    <div className={styles.logoLeft}>
                      <img src="/images/dongnai.png" alt="dongnai" />
                      <h2>{t('footer.biosphere_reserve')}</h2>
                    </div>
                    <div className={styles.logoright}>
                      <p>{t('footer.address')}</p>
                      <p>
                        {t('footer.phone')} 0251 3961 484 / Fax: 0251 3961 484
                      </p>
                      <p style={{ wordWrap: 'break-word' }}>
                        Facebook:
                        https://www.facebook.com/dongnaibiospherereserve
                      </p>
                    </div>
                  </li>
                  {/* <li>
                    <div className={styles.logoLeft}>
                      <img src="/images/dongnai.png" alt="dongnai" />
                      <h2>{t('footer.biosphere_reserve2')}</h2>
                    </div>
                    <div className={styles.logoright}>
                      <p>{t('footer.address2')}</p>
                      <p>{t('footer.phone')} 02513 861 290</p>
                    </div>
                  </li> */}
                  <div
                    style={{
                      display: 'flex',
                      paddingTop: '50px',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                    }}
                    className={styles.listLogo}
                  >
                    <li>
                      <a
                        href="https://dongnaibr.vn"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className={styles.logoLeft}>
                          <img
                            src="/images/dongnai.png"
                            alt="dongnai"
                            style={{ width: 62 }}
                          />
                        </div>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.dongnai.gov.vn/Pages/home.aspx"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className={styles.logoLeft}>
                          <img
                            src="/images/logo/dongnai.svg"
                            alt="dongnai"
                            style={{ width: 62 }}
                          />
                        </div>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://monre.gov.vn/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className={styles.logoLeft}>
                          <img
                            src="/images/logo/tainguyen.svg"
                            alt="tainguyen"
                          />
                        </div>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://cattiennationalpark.com.vn/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className={styles.logoLeft}>
                          <img src="/images/logo/cattien.svg" alt="cattien" />
                        </div>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://en.unesco.org/fieldoffice/hanoi"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className={styles.logoLeft}>
                          <img src="/images/logo/unesco.png" alt="unesco" />
                        </div>
                      </a>
                    </li>
                    <li>
                      <div className={styles.logoLeft}>
                        <img src="/images/logo/mab.png" alt="mab" />
                      </div>
                    </li>
                  </div>
                </ul>
              </div>
            </div>
            <div className={styles.menuGallery}>
              <div className={styles.menu}>
                <div className={styles.menuTitle}>{t('footer.category')}</div>
                <div className={styles.listMenu}>
                  <ul>
                    <li>
                      <Link to="/aboutme">{t('footer.introduce')}</Link>
                    </li>
                    <li>
                      <Link to="/tin-tuc">{t('footer.news')}</Link>
                    </li>
                    {/* <li>
                      {t('footer.event')}
                    </li> */}
                    <li>
                      <Link to="/dich-vu">{t('footer.tourism')}</Link>
                    </li>
                    <li>
                      <Link to="thu-vien/thuc-vat">{t('footer.plants')}</Link>
                    </li>
                    <li>
                      <Link to="thu-vien/dong-vat">{t('footer.animals')}</Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Link to="thu-vien/sinh-canh-nui-rung">
                        {t('footer.landscape')}
                      </Link>
                    </li>
                    {/* <li>{t('footer.culture_history')}</li> */}
                    {/* <li>{t('footer.advertisement')}</li>
                    <li>{t('footer.q_and_a')}</li> */}
                    <li>
                      <Link to="lien-he">{t('footer.contact')}</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={styles.gallery}>
                <div className={styles.menuTitle}>
                  <p>{t('footer.library')}</p>
                </div>
                <div
                  onClick={() => window.scrollTo(0, 0)}
                  onKeyDown={() => window.scrollTo(0, 0)}
                  role="button"
                  className={styles.top}
                  tabIndex={0}
                >
                  <img src="/images/goUp.svg" alt="goUp" />
                </div>
                <div className={styles.listGallery}>
                  <ul>
                    <li
                      onClick={handelClick}
                      onKeyDown={handelClick}
                      role="presentation"
                    >
                      <img src="/images/library/Mask group.png" alt="" />
                    </li>
                    <li
                      onClick={handelClick}
                      onKeyDown={handelClick}
                      role="presentation"
                    >
                      <img src="/images/library/Mask group-1.png" alt="" />
                    </li>
                    <li
                      onClick={handelClick}
                      onKeyDown={handelClick}
                      role="presentation"
                    >
                      <img src="/images/library/Mask group-2.png" alt="" />
                    </li>
                    <li
                      onClick={handelClick}
                      onKeyDown={handelClick}
                      role="presentation"
                    >
                      <img src="/images/library/Mask group-3.png" alt="" />
                    </li>
                    <li
                      onClick={handelClick}
                      onKeyDown={handelClick}
                      role="presentation"
                    >
                      <img src="/images/library/Mask group-4.png" alt="" />
                    </li>
                    <li
                      onClick={handelClick}
                      onKeyDown={handelClick}
                      role="presentation"
                    >
                      <img src="/images/library/Mask group-5.png" alt="" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.copyRight}>
        <div className={styles.copyRightWrap}>{t('footer.copyright')}</div>
      </div>
    </>
  );
};

export default Footer;
