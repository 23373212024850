import classnames from 'classnames';
import React from 'react';
import styles from './style.module.scss';

const SubItem: React.FC<{ title: string; des: string; right?: boolean }> = ({
  title,
  des,
  right = false,
}) => {
  return (
    <div className={styles.subItem}>
      <div className={classnames(styles.desTitle, right && styles.textRight)}>
        <div className={styles.icon}>
          <img src="/images/icon.png" alt="icon" />
        </div>
        {title}
      </div>
      <div className={styles.desSubItem}>{des}</div>
    </div>
  );
};

export default SubItem;
