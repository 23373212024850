/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import styles from './style.module.scss';
import './style.css';

const Hexagon: React.FC<{
  text: string;
  des: string;
  img: string;
  id: string;
}> = ({ text, des, img, id }) => {
  return (
    <>
      <div id={`hexegon+${id}`} className={styles.hexagon}>
        <img src={`./images/${img}`} alt="" />
        <div className={styles.imageText}>{text}</div>
      </div>
      <Tooltip
        anchorId={`hexegon+${id}`}
        place="top"
        html={`<div class='customTooltip'>${des}</div>`}
        style={{ backgroundColor: '#FFFFFF', color: '#005B38' }}
      />
    </>
  );
};

export default Hexagon;
