import classnames from 'classnames';
import React from 'react';
import styled from 'styled-components';

import Skeleton from 'react-loading-skeleton';
import styles from './style.module.scss';
import 'react-loading-skeleton/dist/skeleton.css';

const SubItemLoading: React.FC<{ right?: boolean }> = ({ right = false }) => {
  return (
    <div className={styles.subItem}>
      <div className={classnames(styles.desTitle, right && styles.textRight)}>
        <div className={styles.icon}>
          <img src="./images/icon.png" alt="icon" />
        </div>
        <Skeleton height={24} width={300} wrapper={Box} />
        <Skeleton height={14} count={3} />
      </div>
    </div>
  );
};
export default SubItemLoading;

const Box = styled.span`
  display: inline-block;
  margin-bottom: 4px;
  width: 100%;
`;
