export const SET_LANGUAGE = 'SET_LANGUAGE';

export interface Language {
  [key: string]: string;
}

export const setLanguage = (language: string) => {
  return {
    type: SET_LANGUAGE,
    payload: language,
  };
};

export interface LanguageState {
  language: Language;
}
const lang = localStorage.getItem('lang') || 'en';
export const languageReducer = (
  state: Language = { language: lang },
  action: { type: string; payload: any },
): Language => {
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    default:
      return state;
  }
};
