import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from '../../lib/axios';
import { NEWS } from '../../lib/endpoint';
import { TNews } from '../../models/news';
import { LanguageState } from '../../reducer/languageReducer';

export const useService = () => {
  const [news, setNews] = useState<TNews>();
  const [documents, setDocuments] = useState<TNews>();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const language = useSelector(
    (state: LanguageState) => state.language.language,
  );
  useEffect(() => {
    Promise.all([getListNews(), getListDocument()])
      .then((responses) => {
        setNews(responses[0]);
        setDocuments(responses[1]);
      })
      .catch((e) => {
        throw e;
      })
      .finally(() => setIsLoading(false));
  }, []);
  const handleNavigate = (id: number) => {
    navigate(`/chi-tiet/${id}`);
  };

  async function getListNews(): Promise<TNews> {
    const res = await axios.post(NEWS, {
      categoryid: '95',
      lang: language,
      currentpage: '1',
      pageSize: '10',
    });
    return res.data.d;
  }

  async function getListDocument(): Promise<TNews> {
    const res = await axios.post(NEWS, {
      categoryid: '269',
      lang: language,
      currentpage: '1',
      pageSize: '50',
    });
    return res.data.d;
  }

  return {
    news,
    getListNews,
    isLoading,
    setIsLoading,
    handleNavigate,
    documents,
  };
};
