import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LanguageState } from '../../reducer/languageReducer';
import axios from '../../lib/axios';
import { NEWS, NEWS_DETAIL } from '../../lib/endpoint';
import { TNew, TNews } from '../../models/news';

export const useService = () => {
  const { id } = useParams();
  const [news, setNews] = useState<TNew>();
  const [newsOther, setNewsOther] = useState<TNews>();

  const [isLoading, setIsLoading] = useState(true);
  const language = useSelector(
    (state: LanguageState) => state.language.language,
  );
  useEffect(() => {
    Promise.all([getListNews(), getListNewsOther()])
      .then((responses) => {
        console.log();

        setNews(responses[0]);
        setNewsOther(responses[1]);
      })
      .catch((e) => {
        throw e;
      })
      .finally(() => setIsLoading(false));
  }, [language]);

  async function getListNews(): Promise<TNew> {
    const res = await axios.post(NEWS_DETAIL, {
      id,
      lang: language,
    });
    return res.data.d;
  }

  async function getListNewsOther(): Promise<TNews> {
    const res = await axios.post(NEWS, {
      categoryid: '95',
      lang: language,
      currentpage: '1',
      pageSize: '10',
    });
    return res.data.d;
  }

  return {
    news,
    getListNews,
    isLoading,
    setIsLoading,
    newsOther,
  };
};
