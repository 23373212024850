import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LanguageState } from '../../reducer/languageReducer';
import axios from '../../lib/axios';
import { INTRODUCE, NEWS, SERVICE } from '../../lib/endpoint';
import { Services } from '../../models/services';
import { rec } from '../../utils/html';
import { TNews } from '../../models/news';

export const useService = () => {
  const [services, setServices] = useState<Services[]>([]);
  const [introduce, setIntroduce] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);
  const [news, setNews] = useState<TNews>();
  const navigate = useNavigate();
  const language = useSelector(
    (state: LanguageState) => state.language.language,
  );
  async function getListService(): Promise<Array<Services>> {
    const res = await axios.post(SERVICE, {
      categoryid: '270',
      lang: language,
      currentpage: '1',
      pageSize: '50',
    });
    return res.data.d;
  }

  async function getIntroduce(): Promise<string> {
    const res = await axios.post(INTRODUCE, {
      id: '170',
      lang: language,
    });
    const a: string = res.data.d.Content.replaceAll('/r/n');
    const parser = new DOMParser();
    const doc = parser.parseFromString(a, 'text/html');
    const body = doc.getElementsByTagName('td')[0];
    const text = rec(body.children);
    return text;
  }

  async function getListNews(): Promise<TNews> {
    const res = await axios.post(NEWS, {
      categoryid: '95',
      lang: language,
      currentpage: '1',
      pageSize: '10',
    });
    return res.data.d;
  }
  useEffect(() => {
    Promise.all([getListService(), getIntroduce(), getListNews()])
      .then((responses) => {
        console.log(responses);

        setServices(responses[0]);
        setIntroduce(responses[1]);
        setNews(responses[2]);
      })
      .catch((e) => {
        throw e;
      })
      .finally(() => setIsLoading(false));
  }, [language]);

  const handleNavigate = (id: number) => {
    navigate(`/chi-tiet/${id}`);
  };

  return {
    services,
    introduce,
    news,
    getListService,
    isLoading,
    setIsLoading,
    handleNavigate,
  };
};
