import React from 'react';
import ReactDOM from 'react-dom';
import { SkeletonTheme } from 'react-loading-skeleton';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import ScrollToTop from './lib/scrollToTop';
import initialize from './reducer';
import './i18n/i18n';
import './scss/index.scss';

const store = initialize();

ReactDOM.render(
  <SkeletonTheme baseColor="#fffdfd12" highlightColor="#077b4e">
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop />
        <App />
      </BrowserRouter>
    </Provider>
  </SkeletonTheme>,
  document.getElementById('root'),
);
