import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../Button';
import styles from './style.module.scss';

type Props = {
  imageUrl?: string;
  title?: string;
  description?: string;
  handleNavigate: (id: number) => void;
  id: number;
  fullStyle?: boolean;
};
const Item = ({ ...props }: Props) => {
  const { imageUrl, title, description, handleNavigate, id } = props;
  const { t } = useTranslation();
  console.log(imageUrl);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      style={props.fullStyle ? { width: '100%' } : {}}
      className={styles.wrap}
      onClick={() => handleNavigate(id)}
    >
      <div className={styles.image}>
        <img src={imageUrl} alt="" />
      </div>
      <h2>{title}</h2>
      <div className={styles.des}>{description}</div>
      <Button type="black" text={t('info.detail')} />
    </div>
  );
};

export default Item;
