import React from 'react';
import Flickity from 'react-flickity-component';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styles from './style.module.scss';

const backgroundImg = [
  { imageUrl: `url("./images/background.png")` },
  { imageUrl: `url("./images/slider/1.jpg")` },
  { imageUrl: `url("./images/slider/2.jpg")` },
  { imageUrl: `url("./images/slider/3.jpg")` },
  { imageUrl: `url("./images/slider/4.jpg")` },
  { imageUrl: `url("./images/slider/5.jpg")` },
];
const MainSlider = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const changeLink = (url: string) => {
    navigate(`/thu-vien/${url}`);
  };

  const genSlider = () => {
    return backgroundImg.map((item) => (
      <div
        className={styles.background}
        style={{ backgroundImage: item.imageUrl }}
        key={item.imageUrl}
      />
    ));
  };

  return (
    <div className={styles.slider}>
      <Flickity options={{ autoPlay: true }}>{genSlider()}</Flickity>
      <div className={styles.content}>
        <div data-aos="fade-left">
          <div className={styles.subTitle}>{t('slider.biosphere_reserve')}</div>
        </div>
        <div data-aos="fade-right">
          <div className={styles.title}>{t('slider.dong_nai')}</div>
        </div>
        <div data-aos="fade-in">
          <div className={styles.des}>
            <h2>{`"${t('slider.conservation_for_development1')}`}</h2>
            <h2 className={styles.right}>
              {`${t('slider.conservation_for_development2')}"`}
            </h2>
          </div>
        </div>
      </div>
      <div
        className={styles.listItem}
        style={{ backgroundImage: `url("./images/backgroundItem.png")` }}
      >
        <ul>
          <li
            onClick={() => {
              changeLink('thuc-vat');
            }}
            onKeyDown={() => {
              changeLink('thuc-vat');
            }}
            role="presentation"
          >
            <img src="/images/Tree.png" alt="" />
            <p>{t('header.plants')}</p>
            <button
              type="button"
              style={{ backgroundImage: `url("./images/yellow.png")` }}
            >
              <img src="/images/ArrowRight.png" alt="" />
            </button>
          </li>
          <li
            onClick={() => {
              changeLink('dong-vat');
            }}
            onKeyDown={() => {
              changeLink('dong-vat');
            }}
            role="presentation"
          >
            <img src="/images/Horse.png" alt="" />
            <p>{t('header.animals')}</p>
            <button
              type="button"
              style={{ backgroundImage: `url("./images/yellow.png")` }}
            >
              <img src="/images/ArrowRight.png" alt="" />
            </button>
          </li>
          <li
            onClick={() => {
              changeLink('sinh-canh-nui-rung');
            }}
            onKeyDown={() => {
              changeLink('sinh-canh-nui-rung');
            }}
            role="presentation"
          >
            <img src="/images/Image.png" alt="" />
            <p>{t('header.landscape')}</p>
            <button
              type="button"
              style={{ backgroundImage: `url("./images/yellow.png")` }}
            >
              <img src="/images/ArrowRight.png" alt="" />
            </button>
          </li>
          <li
            onClick={() => {
              changeLink('sinh-canh-song-nuoc');
            }}
            onKeyDown={() => {
              changeLink('sinh-canh-song-nuoc');
            }}
            role="presentation"
          >
            <img src="/images/ClockCounterClockwise.png" alt="" />
            <p>{t('slider.culture_history')}</p>
            <button
              type="button"
              style={{ backgroundImage: `url("./images/yellow.png")` }}
            >
              <img src="/images/ArrowRight.png" alt="" />
            </button>
          </li>
        </ul>
      </div>
      <img className={styles.glass} src="./images/glass.png" alt="glass" />
    </div>
  );
};

export default MainSlider;
