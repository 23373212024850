export const rec = (children: HTMLCollection) => {
  let text = '';
  for (let i = 0; i < children.length; i += 1) {
    const child = children[i];
    if (child.children.length) {
      text += `${rec(child.children).trim()}\n`;
    } else if (child && (child as HTMLElement).innerText.length > 0) {
      text += (child as HTMLElement).innerText.trim();
    }
  }
  return text;
};
