import React from 'react';
import styles from './style.module.scss';

type TProps = {
  image: string;
  title: string;
  id: number;
  type: string;
  handleNavigate: (id: number, type: string, title: string) => void;
};
const GalleryItem = ({ image, title, handleNavigate, id, type }: TProps) => {
  return (
    <div
      className={styles.item}
      onClick={() => handleNavigate(id, type, title)}
      role="presentation"
    >
      <div className={styles.img}>
        <img src={`${image}`} alt={image} />
      </div>
      <p>{title}</p>
    </div>
  );
};

export default GalleryItem;
