import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LanguageState } from '../../reducer/languageReducer';
import axios from '../../lib/axios';
import { NEWS } from '../../lib/endpoint';
import { TNews } from '../../models/news';
import { removeVietnameseTones } from '../../utils/func';

export const useService = () => {
  const params = new URLSearchParams(window.location.search);
  const searchValue = params.get('search');
  const [news, setNews] = useState<TNews>();
  const [isLoading, setIsLoading] = useState(true);
  const language = useSelector(
    (state: LanguageState) => state.language.language,
  );
  const navigate = useNavigate();
  useEffect(() => {
    Promise.all([getListNews()])
      .then((responses) => {
        if (searchValue) {
          const lstFilter = responses[0].DataList.filter((item) =>
            removeVietnameseTones(item.Name.toLowerCase()).includes(
              removeVietnameseTones(searchValue.toLowerCase()),
            ),
          );
          return setNews({ ...responses[0], DataList: lstFilter });
        }
        setNews(responses[0]);
      })
      .catch((e) => {
        throw e;
      })
      .finally(() => setIsLoading(false));
  }, [language, searchValue]);
  const handleNavigate = (id: number) => {
    navigate(`/chi-tiet/${id}`);
  };
  async function getListNews(page?: number): Promise<TNews> {
    const res = await axios.post(NEWS, {
      categoryid: '95',
      lang: language,
      currentpage: page || 1,
      pageSize: '9',
    });

    if (page && res.data.d) {
      setNews(res.data.d);
    }
    return res.data.d;
  }

  return {
    news,
    getListNews,
    isLoading,
    setIsLoading,
    handleNavigate,
  };
};
