import React from 'react';
import ReactPaginate from 'react-paginate';
import './style.css';

type Props = {
  pageCount: number;
  onPageChange: (selected: number) => void;
};
const Pagination = ({ pageCount, onPageChange }: Props): JSX.Element => {
  return (
    <ReactPaginate
      pageCount={pageCount}
      onPageChange={(data) => onPageChange(data.selected)}
      activeClassName="active"
      containerClassName="pagination"
      previousLabel="Previous"
      nextLabel="Next"
    />
  );
};

export default Pagination;
