import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  ic_action_camp,
  ic_action_car,
  ic_action_community,
  ic_action_cycling,
  ic_action_ecological,
  ic_action_education,
  ic_action_hiking,
  ic_action_history,
  ic_action_rowing,
  ic_action_swim,
  ic_action_visit,
  ic_action_wading_stream,
  image_tour_1,
} from '../../utils/res';
import { Tour } from '../../models/tour';
import { LanguageState } from '../../reducer/languageReducer';
import { useTypeSafeTranslation } from '../../i18n/i18n';

export const useService = () => {
  const { t } = useTypeSafeTranslation();

  const navigate = useNavigate();
  const language = useSelector(
    (state: LanguageState) => state.language.language,
  );
  const [listTour, setListTour] = useState<Tour[]>([]);
  const listAction = [
    {
      value: t('pages/DetailService.hiking'),
      label: t('pages/DetailService.hiking'),
    },
    {
      value: t('pages/DetailService.camping'),
      label: t('pages/DetailService.camping'),
    },
    {
      value: t('pages/DetailService.ecological'),
      label: t('pages/DetailService.ecological'),
    },
    {
      value: t('pages/DetailService.sightseeing'),
      label: t('pages/DetailService.sightseeing'),
    },
    {
      value: t('pages/DetailService.rowing'),
      label: t('pages/DetailService.rowing'),
    },
    {
      value: t('pages/DetailService.cycling'),
      label: t('pages/DetailService.cycling'),
    },
    {
      value: t('pages/DetailService.swim'),
      label: t('pages/DetailService.swim'),
    },
    {
      value: t('pages/DetailService.wading'),
      label: t('pages/DetailService.wading'),
    },
  ];

  const listTime = [
    {
      value: t('pages/DetailService.time'),
      label: t('pages/DetailService.time'),
    },
    {
      value: t('pages/Service.extended_time'),
      label: t('pages/Service.extended_time'),
    },
  ];

  const listTourType = [
    {
      value: t('pages/DetailService.discovery'),
      label: t('pages/DetailService.discovery'),
    },
  ];

  const handleNavigate = (id: number) => {
    navigate(`/dich-vu/${id}`);
  };
  useEffect(() => {
    const listTours = getListTour();
    setListTour(listTours);
  }, [language]);
  return {
    tours: tours[language],
    listTour,
    handleNavigate,
    listTime,
    listAction,
    listTourType,
  };
};
const tours: Record<string, Record<string, string>> = {
  vn: {
    // tour 1
    other_news: 'Tin tức khác',
    camp: 'Cắm trại',
    car: 'Ôtô',
    visit: 'Tham quan',
    cycling: 'Đạp xe',
    ecological: 'Sinh thái',
    history: 'Lịch sử',
    community: 'Cộng đồng',
    education: 'Giáo dục',
    hiking: 'Đi bộ',
    swim: 'Bơi',
    tour_hot: 'Tour hot',
    discover_dong_nai: 'Khám phá Đồng Nai',
    detail: 'Chi tiết',
    call_now: 'Gọi ngay',
    animal_photos: 'Ảnh động vật',
    plant_photo: 'Ảnh thực vật',
    rowing: 'Chèo thuyền',
    wading_stream: 'Lội suối',
    culture_history: 'Văn hoá - lịch sử',
    overview: 'Tổng quan',
    schedule: 'Lịch trình',
    map: 'Bản đồ',
    note: 'Lưu ý',
    photo_library: 'Thư viện ảnh',
    weather: 'Thời tiết',
    skin: 'Trang phục',
    regulations: 'Quy định',
    book_now: 'Đặt ngay',
    sale_off: 'Giảm',
    call: 'Gọi điện',
    day: 'Ngày',
    type_tour_1: 'Dịch vụ khám phá thiên nhiên',
    type_tour_2: 'Dịch vụ khám phá thiên nhiên',
    tour_1_title:
      'KHU BẢO TỒN THIÊN NHIÊN – VĂN HÓA ĐỒNG NAI TRUNG TÂM SINH THÁI – VĂN HÓA – LỊCH SỬ CHIẾN KHU Đ ',
    tour_1_address: 'Ấp 5 - Xã Mã Đà - Huyện Vĩnh Cửu - Tỉnh Đồng Nai',
    tour_1_synopsys_1:
      'Đồng Nai, vùng đất “địa linh nhân kiệt” nổi tiếng với nhiều thắng cảnh đẹp. Những điểm du lịch nổi tiếng có một không hai của Khu bảo tồn có thể kể đến: thắng cảnh non nước Hồ Trị An, rừng nhiệt đới Mã Đà, chiến khu Đ',
    tour_1_synopsys_2:
      'Đồng Nai, vùng đất “địa linh nhân kiệt” nổi tiếng với nhiều thắng cảnh đẹp. Một trong những điểm du lịch nổi tiếng có một không hai, phải kể đến thắng cảnh non nước Hồ Trị An.Với diện tích 32.400 ha, được bao bọc bởi khu dự trữ sinh quyển Đồng Nai. Nơi đây được thiên nhiên ưu ái ban tặng cho nguồn nước trong xanh như ngọc, được điểm tô bởi 72 hòn đảo lớn nhỏ. Trên du thuyền, quý khách có thể ngắm nhìn những cánh rừng trải dài đến bất tận, những cánh cò bay từng đàn cùng những hoạt động mưu sinh của bà con ngư dân lênh đên trên hồ nước đại ngàn.\n Ngoài ra, hồ Trị An còn là hồ nhân tạo thu hút nhiều du khách đến tham quan, check-in và tổ chức nhiều hoạt động ngoài trời như cắm trại, câu cá, chèo thuyền,... Đến với hồ Trị An cũng là cơ hội để du khách tiếp cận rừng nhiệt đới Mã Đà - một trong những khu rừng mưa cuối cùng bảo vệ thành công các động vật quý hiếm như: Voi, bò tót, gấu chó... Khu rừng có thảm động thực vật phong phú và đa dạng nên đã được UNESCO công nhận là khu dự trữ sinh quyển Thế giới.\n Nơi đây một thời vang dội với bản anh hùng ca “Đại ngàn chiến khu Đ”. Với hai khu di tích lịch sử cấp quốc gia; Chiến khu D và Trung Ương cục Miền Nam nằm sâu trong khu rừng Mã Đà.\n Chính nét đặc sắc về địa hình và cảnh quan về rừng, sông – hồ, văn hóa, lịch sử đã tạo nên vẻ đẹp độc nhất vô nhị về thiên nhiên hoang dã. Hồ Trị An chắc chắn sẽ là điểm đến thú vị dành cho quý khách đến học tập, nghiên cứu, trải nghiệm và khám phá về hệ sinh thái rừng nhiệt đới. ',
    tour_1_schedule_day_1_time_1: '08h00',
    tour_1_schedule_day_1_title_1:
      'Hướng dẫn viên đón đoàn tại Văn phòng Trung Tâm Sinh Thái Bà Hào, ấp 5 xã Mã Đà, huyện Vĩnh Cửu, tỉnh Đồng Nai.',
    tour_1_schedule_day_1_time_2: '08h15-08h45',
    tour_1_schedule_day_1_title_2:
      'Hướng dẫn viên cho đoàn di chuyển vào Khu di tích lịch sử Chiến Khu Đ tìm hiểu về vùng đất anh hung, Miền Đông gian lao mà Anh dũng.',
    tour_1_schedule_day_1_time_3: '09h00-10h30',
    tour_1_schedule_day_1_title_3:
      'Hướng dẫn viên cho đoàn dâng hương tưởng nhớ các anh hung liệt sĩ và thuyết minh hướng dẫn về khu di tích Chiến Khu Đ anh dũng kiên cường.',

    tour_1_schedule_day_1_des_3: '',

    tour_1_schedule_day_1_time_4: '10h30-11h00',
    tour_1_schedule_day_1_title_4:
      'Đoàn lên xe di chuyển ra Trung tâm Sinh thái Bà Hào, đoàn dung cơm trưa và nghỉ ngơi tham quan chụp hình tại Trung tâm Sinh thái Bà Hào với diện tích 15.7h với nhiều cảnh tươi đẹp chỉ có tại Khu Bảo tồn Thiên nhiên- Văn hóa Đồng nai',

    tour_1_schedule_day_1_des_4: '',

    tour_1_schedule_day_1_time_5: '13h00-13h30',
    tour_1_schedule_day_1_title_5:
      'Đoàn lên xe di chuyển ra khu vực trảng B rừng mã đà.',
    tour_1_schedule_day_1_time_6: '13h30 -15h00',
    tour_1_schedule_day_1_title_6:
      'Hướng dẫn viên sinh thái sẽ dẫn đoàn đi bộ lội suối, tham quan rừng và học tập cùng nghe các chú Kiểm lâm thuyết minh về đa dạng sinh học, về hệ sinh thái ở khu rừng, và các kỹ năng khi đi rừng, kỹ năng sinh tồn, và một số liệu pháp trị bệnh như là tắm rừng (Shinrin-yoku) cảm nhận thiên nhiên nghe hơi thở của rừng tại Khu Bảo tồn Thiên nhiên – Văn hóa Đồng nai.',
    //
    // tour_1_schedule_day_1_des_6:
    //   ' - Gala, giao lưu sinh hoạt lửa trại hương rừng\n-        Tổng kết hoạt động ngày một – nghỉ đêm',
    //
    tour_1_schedule_day_1_time_7: '15h00-15h30',
    tour_1_schedule_day_1_title_7: 'Tạm biệt chia tay đoàn!Hẹn ngày gặp lại.',
    // tour_1_schedule_day_2_time_2: '06:30 – 08:30: ',
    // tour_1_schedule_day_2_title_2:
    //   ' ăn sáng trên thuyền, tham quan làng bè Suối Tượng ',
    //
    // tour_1_schedule_day_2_des_2:
    //   'Làng bè Suối Tượng có khoảng hơn 100 hộ dân, là làng bè của người việt kiều Campuchia họ đã sống làm nghề chài lưới và đã bám trụ với hồ Trị An trên 30 năm, tạo thành nét văn hóa trên hồ cùng làng ghề chài lưới rất sinh động.)  \n- Giao lưu với người dân kiều bào nơi đây (có thể tặng quà cho người ghèo, tặng dụng cụ học tập cho các em học sinh.)\n- Tham quan bến cá nơi tập kết tất cả thủy sản tươi sống tại hồ Trị An. Tại đây quý khách có thể mua thủy sản về làm quà.',
    //
    // tour_1_schedule_day_2_time_3: '10:00 – 11:30: ',
    // tour_1_schedule_day_2_title_3:
    //   ' đi bộ Trecking khám phá hệ sinh thái khu rừng nhiệt đới Mã Đà.',
    // tour_1_schedule_day_2_des_3:
    //   'Check in cây cầy 500 năm tuổi, cùng lội suối giữa rừng.',
    //
    // tour_1_schedule_day_2_time_4: '12h00: ',
    // tour_1_schedule_day_2_title_4:
    //   ' ăn trưa nghỉ ngơi tại Khu bảo tồn Thiên nhiên và Văn hóa Đồng Nai.',
    //
    // tour_1_notice:
    //   'Mũ, nước uống, quần áo thể thao đạp xe, đồ bơi, giầy, đèn pin, kính lúp, ống nhòm, kem chống nắng, thuốc chống côn trùng, đồ dùng vệ sinh cá nhân và ít nhất 02 bộ đồ thay.',

    // tour 2

    tour_2_title: 'ĐẠP XE CHIẾN KHU Đ – TRẢNG B ',
    tour_2_address: 'Ấp 5 - Xã Mã Đà - Huyện Vĩnh Cửu - Tỉnh Đồng Nai',
    tour_2_synopsys_1:
      'Lăn bánh trên địa danh lịch sử quan trọng trên chiến trường Chiến khu Đ trong hai cuộc kháng chiến chống Thực dân Pháp, và Đế quốc Mỹ xâm lược, tìm hiểu dấu son của vùng đất  “miền Đông gian lao mà anh dũng” ',
    tour_2_synopsys_2:
      'Lăn bánh trên địa danh lịch sử quan trọng trên chiến trường Chiến khu Đ trong hai cuộc kháng chiến chống Thực dân Pháp, và Đế quốc Mỹ xâm lược, tìm hiểu dấu son của vùng đất  “miền Đông gian lao mà anh dũng” ',
    tour_2_schedule_day_1_time_1: '08h00',
    tour_2_schedule_day_1_title_1:
      'Kiểm lâm đón đoàn Văn phòng Khu Bảo tồn thiên nhiên – Văn hóa Đồng Nai, ấp 1 xã Mã Đà, huyện Vĩnh Cửu, tỉnh Đồng Nai.',
    tour_2_schedule_day_1_time_2: '08h00-08h30',
    tour_2_schedule_day_1_title_2:
      'Hướng dẫn viên đưa đoàn di chuyển đến Bờ đập Hiếu Liêm, địa điểm nhận xe đạp.Hướng dẫn viên phổ biến chương trình đạp xe và những điều cần lưu ý. ',
    tour_2_schedule_day_1_des_2: '',
    tour_2_schedule_day_1_time_3: '08h30-11h00',
    tour_2_schedule_day_1_title_3:
      '   Đoàn nhận xe đạp và bắt đầu đạp xe khoảng 18km. Trên đường đi đoàn được ngắm những cảnh đẹp của rừng Khu Bảo tồn và được nghe giới thiệu về động thực vật tại rừng Khu bảo tồn. ',

    tour_2_schedule_day_1_time_4: '11h00-12h30',
    tour_2_schedule_day_1_title_4: 'Đoàn dùng cơm trưa tại gốc cây Kơnia ',

    tour_2_schedule_day_1_des_4: '',

    tour_2_schedule_day_1_time_5: '12h30-13h00',
    tour_2_schedule_day_1_title_5: 'Đoàn nghỉ ngơi tại chỗ.',

    tour_2_schedule_day_1_time_6: '13h00-13h30',
    tour_2_schedule_day_1_title_6:
      ' Đoàn di chuyển bằng xe đạp quay ra đường tỉnh lộ 761 (cự ly 1km).',

    tour_2_schedule_day_1_time_7: '13h30',
    tour_2_schedule_day_1_title_7:
      'Kết thúc chương trình.Tạm biệt HDV đoàn lên xe trở về điểm khởi hành ban đầu.',
    // tour_2_schedule_day_1_time_8: '21h00:',
    // tour_2_schedule_day_1_title_8: ':Nghỉ ngơi tại Di tích.',
    //
    // tour_2_schedule_day_2_time_1: '07:00 ',
    // tour_2_schedule_day_2_title_1: 'Ăn sáng.',
    //
    // tour_2_schedule_day_2_time_2: '08:30 ',
    // tour_2_schedule_day_2_title_2:
    //   ' Đi bộ tham quan rừng tự nhiên (đi bộ 06 km).',
    //
    // tour_2_schedule_day_2_time_3: '11:30 ',
    // tour_2_schedule_day_2_title_3: 'Ăn trưa.',
    // tour_2_schedule_day_2_time_4: '14:30 ',
    // tour_2_schedule_day_2_title_4:
    //   'Tạm biệt rừng Chiến khu Đ kết thúc chuyến tham quan về nguồn.',
    //
    // tour_2_notice: 'Cự ly: 20km (địa hình khó đi)',

    // tour 3

    tour_3_title:
      'Tuyến từ Trung tâm đi Cây Tung, Bằng Lăng 5 ngọn, Cây Gõ Bác Đồng, Cây Đa lộc giao',
    tour_3_address:
      'ấp 1, xã Nam Cát Tiên, huyện Tân Phú, Tỉnh Đồng Nai, Vietnam',
    tour_3_synopsys_1:
      'Trên cùng một trục đường rừng thuận tiện nhất, cây Tung đại thụ mang dáng vẻ kỳ vĩ của rừng già, cây gõ đỏ quý hiếm hàng trăm năm tuổi, cây đa lộc giao như một chú nhện khổng lồ và đan xen là những vạt rừng điểm xuyết bởi lớp vỏ sáng màu đẹp đẽ của quần thể bằng lăng đang chờ đón bước chân du khách khám phá rừng mưa nhiệt đới Cát Tiên. ',
    tour_3_synopsys_2:
      '\n- Độ dài tuyến: 3 km\n- Phương thức di chuyển trên tuyến: Ô tô hoặc xe đạp và đi bộ xuyên rừng\n- Hoạt động chính trên tuyến:\n + Quan sát sinh cảnh rừng, ngắm các loài động vật hoang dã (Sáng sớm hoặc chiều tà): linh trưởng, heo, nai, chim… chụp ảnh.\n+ Trải nghiệm thiên nhiên rừng nhiệt đới: mùa mưa ngắm hoa Bằng Lăng, hoa Đào; mùa khô ngắm rừng thay lá, cây kì dị Dây Bàm Bàm…\n- Ý nghĩa: Tuyến du lịch tạo ra cơ hội việc làm cho cộng đồng từ việc sử dụng phương tiện vận chuyển (xe đạp, ô tô) ăn uống, mua sản phẩm địa phương, hướng dẫn viên; góp phần bảo tồn văn hóa bản địa từ xem chương trình biểu diễn văn hóa truyền thống; đa dạng hóa sản phẩm du lịch, tăng thu nhập cho Vườn; tuyên truyền bảo vệ môi trường rừng. ',

    tour_3_schedule_day_0_time_1:
      'Thời gian dự kiến: 2 - 4 tiếng và đi về trong ngày',
    tour_3_schedule_day_0_title_1:
      'Lịch trình dự kiến: Khởi hành từ Trung tâm Vườn- Cây Tung - Bằng Lăng 5 ngọn- Cây Gõ Bác Đồng- Cây Đa lộc giao - Trung tâm Vườn. ',

    tour_3_notice:
      '- Tuyến dành cho du khách có ít thời gian và thích khám phá và trải nghiệm thiên nhiên hoang dã \n- Du khách không được xả rác, viết hoặc chạm khắc trên cây, gây tiếng ồn làm ảnh hưởng đến thú rừng',

    // tour 4

    tour_4_title:
      'Tuyến Cây Si trăm thân, quần thể Bằng Lăng lộc bình, sinh cảnh rừng Đại Phong Tử',
    tour_4_address:
      'ấp 1, xã Nam Cát Tiên, huyện Tân Phú, Tỉnh Đồng Nai, Vietnam',
    tour_4_synopsys_1:
      '17km xuyên rừng là quãng đường tương đối đủ để du khách cảm nhận được không khí trong lành của rừng nguyên sinh rộng lớn nhất miền Nam. Xuyên suốt tuyến đường là sinh cảnh thường xanh và rừng hỗn giao với những loài thực vật đặc trưng của rừng mưa nhiệt đới. Du khách sẽ được ngắm nhìn cụm Bằng lăng lộc bình với hình thù độc đáo kỳ dị do bàn tay thiên nhiên tác tạo và nhiều cây rừng lâu năm to lớn kỳ vĩ. Bên cạnh đó, rừng Đại Phong Tử với sinh cảnh đất ngập nước theo mùa và có dòng suối nhỏ xanh mát quanh năm là nơi lý tưởng cho hoạt động dã ngoại, cắm trại qua đêm.',
    tour_4_synopsys_2:
      '\n- Độ dài tuyến: 16 km\n- Phương thức di chuyển trên tuyến: Ô tô hoặc xe đạp và đi bộ xuyên rừng\n- Hoạt động trên tuyến: Quan sát sinh cảnh ngắm các loài động vật hoang dã: linh trưởng, heo, nai, chim …; tắm Hồ Cây Si; chụp ảnh và trải nghiệm thiên nhiên rừng nhiệt đới: mùa mưa ngắm hoa Bằng Lăng, hoa Đào; mùa khô ngắm rừng thay lá …\n- Ý nghĩa: Tạo ra cơ hội việc làm cho cộng đồng từ việc lưu trú, ăn uống, mua sản phẩm địa phương, hướng dẫn viên; góp phần bảo tồn văn hóa bản địa từ xem chương trình biểu diễn văn hóa truyền thống; giảm thiểu việc xâm hại môi trường rừng do sinh kế; đa dạng hóa sản phẩm du lịch, tăng thu nhập cho Vườn; tuyên truyền bảo vệ môi trường rừng.',
    tour_4_schedule_day_0_time_1:
      '- Thời gian dự kiến: 3- 4 tiếng hoặc 6- 8 tiếng đi về trong ngày (ăn trưa tại Trạm Đắc Lua',
    tour_4_schedule_day_0_title_1:
      '- Lịch trình dự kiến: Khởi hành từ Trung tâm Vườn- cây Tung cổ thụ- cây Gõ Bác Đồng- cây Đa lộc giao- ngã 3 E7- quần thể Bằng Lăng lục bình- trảng Đại Phong Tử (Vùng ngập nước ven suối, ngắm cá…) - cây Si trăm thân - Trung tâm Vườn. ',

    tour_4_notice:
      '- Tuyến dành cho du khách thích mạo hiểm, khám phá và trải nghiệm thiên nhiên (lưu ý là cần có sức khoẻ tốt, có khả năng đi bộ dài)\n- Du khách không được xả rác, viết hoặc khắc trên cây.\n- Du khách không được gây tiếng ồn làm ảnh hưởng đến thú rừng.\n- Đoàn phù hợp tham gia tour này là dưới 20 khách. Số lượng, thành phần của đoàn khách sẽ chịu sự điều phối của Trung tâm diễn giải môi trường và dịch vụ của Vườn. Việc này nhằm đảm bảo khai thác du lịch trong khuôn khổ sức tải môi trường, giảm thiểu tối đa các tác động lên hệ sinh thái của Vườn quốc gia Cát Tiên',

    // tour 5

    tour_5_title: 'Tuyến xem thú đêm từ Trung tâm đi Núi Tượng',
    tour_5_address:
      'ấp 1, xã Nam Cát Tiên, huyện Tân Phú, Tỉnh Đồng Nai, Vietnam',
    tour_5_synopsys_1:
      'Cát Tiên là nơi duy nhất ở Việt Nam, bạn có thể nhìn thấy động vật hoang dã ngay trong môi trường sống tự nhiên của chúng. Du khách sẽ đi qua những trảng cỏ bạt ngàn - sinh cảnh lý tưởng của các loài thú móng guốc và thú ăn thịt nhỏ, tận mắt nhìn ngắm các loài nai, nhím, thỏ, chồn... đang đi kiếm ăn. Tùy theo mùa và thời tiết trong năm, cộng thêm một chút may mắn, đoàn du khách có thể gặp được đàn heo rừng hay đàn bò tót sừng sững một vẻ đẹp khỏe mạnh đầy hoang dã.',
    tour_5_synopsys_2:
      '\n- Độ dài tuyến: 6 km\n- Phương thức di chuyển trên tuyến: Ô tô\nHoạt động trên tuyến: Quan sát sinh cảnh rừng, ngắm các loài động vật hoang dã, thú ăn cỏ, chim… chụp ảnh và trải nghiệm thiên nhiên rừng nhiệt đới ban đêm.\nTạo ra cơ hội việc làm cho cộng đồng từ việc lưu trú, ăn uống, mua sản phẩm địa phương, hướng dẫn viên; góp phần bảo tồn văn hóa bản địa từ xem chương trình biểu diễn văn hóa truyền thống; giảm thiểu việc xâm hại môi trường rừng do sinh kế; đa dạng hóa sản phẩm du lịch, tăng thu nhập cho Vườn; tuyên truyền bảo vệ môi trường rừng.',
    tour_5_schedule_day_0_time_1: '- Thời gian dự kiến: 1 tiếng',
    tour_5_schedule_day_0_title_1:
      '- Lịch trình dự kiến: Khởi hành từ Trung tâm Vườn - Trung tâm cứu hộ Gấu - Khu vực xem thú đêm - Trạm kiểm lâm Núi Tượng (Nhà nghỉ Kơ Nia) - Trung tâm vườn.',
    tour_5_notice:
      '- Khách lưu tại Vườn, hoặc resorts và homestay vùng đệm. \n- Phù hợp cả khách quốc tế và nội địa.\n- Tần suất tour trong ngày, số lượng, thành phần của đoàn khách sẽ chịu sự điều phối của Trung tâm diễn giải môi trường và dịch vụ của Vườn. Việc này nhằm đảm bảo khai thác du lịch trong khuôn khổ sức tải môi trường, giảm thiểu tối đa các tác động lên hệ sinh thái của Vườn quốc gia Cát Tiên',

    // tour 6

    tour_6_title:
      'Tuyến trải nghiệm sinh cảnh sông Đồng Nai, từ Trung tâm Vườn đi Tà Lài',
    tour_6_address:
      'ấp 1, xã Nam Cát Tiên, huyện Tân Phú, Tỉnh Đồng Nai, Vietnam',
    tour_6_synopsys_1:
      '12km theo dòng sông nội địa dài nhất Việt Nam bọc ranh giới rừng Cát Tiên để đến với nền văn hóa bản địa lâu đời tại Đồng Nai luôn có sức hấp dẫn riêng. Du khách có dịp nhìn ngắm đoạn sông Đồng Nai tạo nên ranh giới tự nhiên cho VQG Cát Tiên, và ngay bên kia bờ là vùng đệm tiếp nối bởi lớp lớp những rẫy những vườn cây ăn trái xanh tốt hứa hẹn mùa màng bội thu. Cộng đồng người dân Châu Mạ, người Stiêng bản địa của xứ này sẽ đón tiếp du khách tại nhà dài truyền thống, với tiếng đàn dây mộc mạc và tấm lòng hiếu khách nồng nhiệt và tươi tắn như những hoa văn thổ cẩm thắm sắc.',
    tour_6_synopsys_2:
      '\n- Độ dài tuyến: 12 km\n- Phương thức di chuyển trên tuyến: Thuyền, ô tô (Lượt đi dùng thuyền, lượt về ô tô)\n- Trải nghiệm sinh cảnh sông nước, các loài động vật hoang dã, chim…, cánh đồng Tà Lài, Làng dân tộc Tà Lài… chụp ảnh và trải nghiệm thiên nhiên rừng nhiệt đới.\n-Tạo ra cơ hội việc làm cho cộng đồng từ việc lưu trú, ăn uống, mua sản phẩm địa phương, hướng dẫn viên; góp phần bảo tồn văn hóa bản địa từ xem chương trình biểu diễn văn hóa truyền thống; giảm thiểu việc xâm hại môi trường rừng do sinh kế; đa dạng hóa sản phẩm du lịch, tăng thu nhập cho Vườn; tuyên truyền bảo vệ môi trường rừng.',
    tour_6_schedule_day_0_time_1:
      '- Thời gian dự kiến:  4 - 6 tiếng đi về trong ngày (Mùa Khô)',
    tour_6_schedule_day_0_title_1:
      '- Lịch trình dự kiến: Khởi hành từ Trung tâm Vườn, trải nghiệm 11 km trên sông Đồng Nai: ngắm bãi bồi dân trồng rau củ quả và ghé mua, ghé hộ dân ven sông mua chuối mít, bưởi …; mô hình nhà nuôi yến, mô hình homestay- làng dân tộc bản địa Tà Lài - Trung tâm Vườn.',

    tour_6_notice:
      '- Du khách tuyệt đối không xả rác xuống sông, gây tiếng ồn làm ảnh hưởng đến thú rừng. \n- Đoàn phù hợp tham gia tour này là dưới 20 khách. Số lượng, thành phần của đoàn khách sẽ chịu sự điều phối của Trung tâm diễn giải môi trường và dịch vụ của Vườn. Việc này nhằm đảm bảo khai thác du lịch trong khuôn khổ sức tải môi trường, giảm thiểu tối đa các tác động lên hệ sinh thái của Vườn quốc gia Cát Tiên\n- Du khách tuân thủ tuyệt đối các nguyên tắc an toàn khi tham gia giao thông đường thủy ',

    // tour 7

    tour_7_title:
      'Tuyến quan sát Linh trưởng từ Thác Trời - Đà Cộ - Vườn thực vật',
    tour_7_address:
      'ấp 1, xã Nam Cát Tiên, huyện Tân Phú, Tỉnh Đồng Nai, Vietnam',
    tour_7_synopsys_1:
      'Thác Trời, Đà Cộ và những tuyến Vườn thực vật của rừng Cát Tiên là những cái tên không thể thiếu nếu du khách muốn nhìn ngắm các loài linh trưởng, đặc biệt là những loài đặc hữu như vượn đen má vàng, voọc chà vá chân đen, voọc bạc Trường Sơn, cu li… Một vạt rừng tĩnh lặng, êm đềm, chỉ vọng vang tiếng chim kêu vượn hót, tiếng bầy chà vá nối nhau chuyền cành sẽ là điểm đến - điểm dừng chân du lãng tuyệt vời cho những du khách yêu thiên nhiên.',
    tour_7_synopsys_2:
      '\n- Độ dài tuyến: 15 km\n- Phương thức di chuyển trên tuyến: Ô tô, xe đạp và đi bộ xuyên rừng\n- Quan sát sinh cảnh rừng, các loài Linh trưởng đoạn Thác Trời - Đà Cộ… chụp ảnh và trải nghiệm thiên nhiên rừng nhiệt đới.\nTạo ra cơ hội việc làm cho cộng đồng từ hướng dẫn viên; góp phần bảo tồn văn hóa bản địa từ xem chương trình biểu diễn văn hóa truyền thống; giảm thiểu việc xâm hại môi trường rừng do sinh kế; đa dạng hóa sản phẩm du lịch, tăng thu nhập cho Vườn; tuyên truyền bảo vệ môi trường rừng.        ',
    tour_7_schedule_day_0_time_1:
      ': 3 tiếng (Ô tô và đi bộ}, 4 tiếng (xe đạp và đi bộ). ',
    tour_7_schedule_day_0_title_1:
      '- Thời gian xuất phát phù hợp: sáng sớm hoặc chiều tà.\n- Lịch trình dự kiến: Khởi hành từ Trung tâm Vườn, Bến Cự, Vườn thực vật, Thác Trời, Rừng Bằng Lăng cổ thụ - Trung tâm Vườn. ',
    tour_7_notice:
      '- Khách thích khám phá thiên nhiên, đặc biệt yêu linh trưởng (lưu ý là cần có sức khoẻ tốt, có khả năng đi bộ dài)\n- Phù hợp cả khách quốc tế và nội địa.\n- Du khách không được cho động vật hoang dã ăn.\n- Đoàn phù hợp tham gia tour này là dưới 10 khách. Số lượng, thành phần của đoàn khách sẽ chịu sự điều phối của Trung tâm diễn giải môi trường và dịch vụ của Vườn. Việc này nhằm đảm bảo khai thác du lịch trong khuôn khổ sức tải môi trường, giảm thiểu tối đa các tác động lên hệ sinh thái của Vườn quốc gia Cát Tiên',

    tour_8_title: 'Tuyến đi bộ xuyên rừng Tà Lài đi Bàu Sấu',
    tour_8_address:
      'ấp 1, xã Nam Cát Tiên, huyện Tân Phú, Tỉnh Đồng Nai, Vietnam',
    tour_8_synopsys_1:
      'Tuyến đi bộ xuyên rừng từ Tà Lài đến Bàu Sấu là dịp để du khách trực tiếp quan sát, cảm nhận nét văn hóa bản địa của đồng bào Châu Mạ, Stiêng qua kiến trúc nhà dài, nghề dệt thổ cẩm thủ công, và thưởng thức tiếng đàn dây truyền thống. Ở chặng thứ 2 của hành trình, du khách tận mắt chứng kiến nhiều cây gỗ đại thụ, dây leo thân gỗ, quan sát được nhiều loài chim, thú, và tìm hiểu về loài cá sấu nước ngọt quý hiếm chỉ còn sinh tồn trong tự nhiên tại VQG Cát Tiên. ',
    tour_8_synopsys_2:
      '\n- Độ dài tuyến: 14 km\n- Phương thức di chuyển trên tuyến: Đi bộ xuyên rừng\n-Tham quan cảnh quan làng dân tộc, đồng lúa, trải nghiệm các nét văn hoá các dân tộc Mạ và S’Tiêng. Ngắm các loài linh trưởng, chim, thú ăn cỏ: Bò Tót, Nai, Heo… chụp ảnh và trải nghiệm thiên nhiên rừng nhiệt đới.\nTạo ra cơ hội việc làm cho cộng đồng từ việc lưu trú, ăn uống, mua sản phẩm địa phương, hướng dẫn viên; góp phần bảo tồn văn hóa bản địa từ xem chương trình biểu diễn văn hóa truyền thống; giảm thiểu việc xâm hại môi trường rừng do sinh kế; đa dạng hóa sản phẩm du lịch, tăng thu nhập cho Vườn; tuyên truyền bảo vệ môi trường rừng.',
    tour_8_schedule_day_0_time_1:
      '- Thời gian dự kiến: 2 ngày 1 đêm (ngủ lều trong rừng) ',
    tour_8_schedule_day_0_title_1:
      '- Lịch trình dự kiến: Khởi hành từ Trung tâm Vườn - Trạm kiểm lâm Tà Lài, Cánh đồng Tà Lài, Rừng hỗn giao tre nứa, rừng Bằng Lăng, khe Suối, dùng bữa chính nghỉ đêm trong rừng - Trung tâm Vườn.  ',
    tour_8_notice:
      '- Khách thích mạo hiểm, khám phá và trải nghiệm thiên nhiên (lưu ý là cần có sức khoẻ tốt, có khả năng đi bộ dài)\n- Đoàn phù hợp tham gia tour này là dưới 15 khách. Số lượng, thành phần của đoàn khách sẽ chịu sự điều phối của Trung tâm diễn giải môi trường và dịch vụ của Vườn. Việc này nhằm đảm bảo khai thác du lịch trong khuôn khổ sức tải môi trường, giảm thiểu tối đa các tác động lên hệ sinh thái của Vườn quốc gia Cát Tiên\n- Đoàn khách phải có hướng dẫn viên đi cùng.',

    // tour 9

    tour_9_title:
      'Tuyến quan sát Chim tại các trục đường chính, đường mòn và các Bàu nước',
    tour_9_address:
      'ấp 1, xã Nam Cát Tiên, huyện Tân Phú, Tỉnh Đồng Nai, Vietnam',
    tour_9_synopsys_1:
      'Bàu Sấu với khung cảnh thiên nhiên đẹp như tranh, không khí trong lành, yên tĩnh, cùng với các bàu nước lân cận khác là một đãi ngộ nồng nhiệt đối với các vị du khách, đặc biệt là đối tượng du khách nhiếp ảnh. Hệ đất ngập nước tạo nên sinh cảnh tuyệt vời không chỉ cho cá sấu nước ngọt đặc hữu, mà còn là nơi quy tụ nhiều loài chim, cá nước ngọt và là chốn vãng lai của các loài động vật khác.',
    tour_9_synopsys_2:
      '\n- Độ dài tuyến: 20 km\n- Phương thức di chuyển trên tuyến: Ô tô, ưu tiên xe đạp và đi bộ xuyên rừng (Đi sớm hoặc chiều tà)\n-  Quan sát các loài Chim trên các trục đường chính, đường mòn và các Bàu nước trong Vườn… chụp ảnh và trải nghiệm thiên nhiên rừng nhiệt đới.\nTạo ra cơ hội việc làm cho cộng đồng từ việc lưu trú, ăn uống, mua sản phẩm địa phương, hướng dẫn viên; góp phần bảo tồn văn hóa bản địa từ xem chương trình biểu diễn văn hóa truyền thống; giảm thiểu việc xâm hại môi trường rừng do sinh kế; đa dạng hóa sản phẩm du lịch, tăng thu nhập cho Vườn; tuyên truyền bảo vệ môi trường rừng.',
    tour_9_schedule_day_0_time_1: '- Thời gian dự kiến:  3 - 6 tiếng  ',
    tour_9_schedule_day_0_title_1:
      '- Lịch trình dự kiến: Khởi hành từ Trung tâm Vườn - Bàu Rau muống Núi Tượng, qua Bàu Ốc , E7, Bàu Chim , Bàu Bèo - Trung tâm Vườn ',
    tour_9_notice:
      '- Khách thích mạo hiểm, khám phá và đặc biệt yêu thích các loài chim (lưu ý là cần có sức khoẻ tốt, có khả năng đi bộ dài)\n- Đoàn phù hợp tham gia tour này là dưới 10 khách. Số lượng, thành phần của đoàn khách sẽ chịu sự điều phối của Trung tâm diễn giải môi trường và dịch vụ của Vườn. Việc này nhằm đảm bảo khai thác du lịch trong khuôn khổ sức tải môi trường, giảm thiểu tối đa các tác động lên hệ sinh thái của Vườn quốc gia Cát Tiên\n- Du khách tham gia tour phải có hướng dẫn viên đi cùng.\n- Khách quan sát chim phải giữ yên lặng, trang phục gần với môi trường tự nhiên (liên hệ Trung tâm du khách của Vườn quốc gia Cát Tiên để rõ hơn về trang phục cần chuẩn bị)',

    // tour 10

    tour_10_title: 'Tuyến tham quan xuyên rừng đến Thác Trời qua cây Gõ lớn',
    tour_10_address:
      'ấp 1, xã Nam Cát Tiên, huyện Tân Phú, Tỉnh Đồng Nai, Vietnam',
    tour_10_synopsys_1:
      'Tuyến đường rừng mưa nhiệt đới đặc trưng với quần thể bằng lăng cổ thụ, các cây gỗ quý hàng chục hàng trăm năm tuổi, phong phú các loài lan rừng và ghềnh sông ngoạn mục. Đây là tuyến tham quan vô cùng thích hợp cho du khách yêu thích chụp ảnh và trải nghiệm thiên nhiên rừng nhiệt đới: mùa mưa ngắm hoa bằng lăng tím, bằng lăng trắng phớt, muồng đào hồng thắm; mùa khô ngắm sắc vàng - cam - đỏ của rừng thay lá.',
    tour_10_synopsys_2:
      '\n- Độ dài tuyến: 10 km\n- Ô tô và đi bộ xuyên rừng\nTham quan sinh cảnh rừng, cây Bằng Lăng cổ thụ, Lan rừng, Thác nước… ngắm Nai, Linh trưởng… chụp ảnh và trải nghiệm thiên nhiên rừng nhiệt đới: mùa mưa ngắm hoa Bằng Lăng, hoa Đào; mùa khô ngắm rừng thay lá, cây kì dị Dây Bàm Bàm.\nTạo ra cơ hội việc làm cho cộng đồng từ việc lưu trú, ăn uống, mua sản phẩm địa phương, hướng dẫn viên; góp phần bảo tồn văn hóa bản địa từ xem chương trình biểu diễn văn hóa truyền thống; giảm thiểu việc xâm hại môi trường rừng do sinh kế; đa dạng hóa sản phẩm du lịch, tăng thu nhập cho Vườn; tuyên truyền bảo vệ môi trường rừng.    ',
    tour_10_schedule_day_0_time_1:
      '- Thời gian dự kiến: 6- 8 tiếng đi về trong ngày ',
    tour_10_schedule_day_0_title_1:
      '- Lịch trình dự kiến: Khởi hành từ Trung tâm Vườn - Điểm đầu tuyến Sinh thái, đi bộ xuyên rừng, suối, đầm lầy, Cây Gõ lớn, Thác Trời - Trung tâm Vườn.',
    tour_10_notice:
      '"- Khách thích mạo hiểm, khám phá và trải nghiệm thiên nhiên (lưu ý là cần có sức khoẻ tốt, có khả năng đi bộ dài)\n- Đoàn phù hợp tham gia tour này là dưới 10 khách. Số lượng, thành phần của đoàn khách sẽ chịu sự điều phối của Trung tâm diễn giải môi trường và dịch vụ của Vườn. Việc này nhằm đảm bảo khai thác du lịch trong khuôn khổ sức tải môi trường, giảm thiểu tối đa các tác động lên hệ sinh thái của Vườn quốc gia Cát Tiên\n- Đoàn khách phải có hướng dẫn viên đi cùng. "  ',

    // tour 11

    tour_11_title:
      'Tuyến tham quan trên sông, từ Đà Lắc đến di tích khảo cổ Cát Tiên',
    tour_11_address:
      'ấp 1, xã Nam Cát Tiên, huyện Tân Phú, Tỉnh Đồng Nai, Vietnam',
    tour_11_synopsys_1:
      'Thánh địa Cát Tiên là một điểm đến đặc biệt khi du khách có thể tìm đến bằng cả đường bộ lẫn đường thủy. Khó có du khách nào cưỡng lại được một chuyến du ngoạn bằng thuyền hay cano ngược nguồn sông Đồng Nai ngắm nhìn cảnh quan sông và các loài thủy cầm, trên đường tìm về di chỉ khảo cổ chứa đựng chứng tích cho một nền văn hóa bản địa sơ khởi chịu ảnh hưởng Ấn Độ giáo, với niên đại xa xưa chỉ ngay sau Công nguyên vài thế kỷ.',
    tour_11_synopsys_2:
      '\n- Độ dài tuyến: 6 km\n- Phương thức di chuyển trên tuyến: Ô tô hoặc xe đạp (15km)  và thuyền (6 km)\n- Quan sát sinh cảnh rừng, mô hình canh tác nông nghiệp, chụp hình các loài chim nước, các loài Linh trưởng…cảnh quan sông Đồng Nai.\n Tạo ra cơ hội việc làm cho cộng đồng từ việc lưu trú, ăn uống, mua sản phẩm địa phương, hướng dẫn viên; góp phần bảo tồn văn hóa bản địa từ xem chương trình biểu diễn văn hóa truyền thống; giảm thiểu việc xâm hại môi trường rừng do sinh kế; đa dạng hóa sản phẩm du lịch, tăng thu nhập cho Vườn; tuyên truyền bảo vệ môi trường rừng.',
    tour_11_schedule_day_0_time_1:
      '- Thời gian dự kiến: 8 tiếng đi về trong ngày ',
    tour_11_schedule_day_0_title_1:
      '- Lịch trình dự kiến: Khởi hành từ Trung tâm Vườn - Bến Cự - Thác Trời -  Trạm kiểm lâm Đà cộ - Trạm kiểm lâm Đà Mí - Trạm kiểm lâm Đà Lắc (dùng thuyền}, sinh cảnh Rừng già, Thác khỉ (Lâm đồng) - đồi A1 Yoni Linga - di tích khảo cổ Cát Tiên - Trung tâm Vườn. ',
    tour_11_notice:
      '\n- Khách thích mạo hiểm, khám phá và trải nghiệm thiên nhiên\nDu khách tuyệt đối không được xả rác xuống sông và gây tiếng ồn làm ảnh hưởng đến thú rừng.\n- Đoàn phù hợp tham gia tour này là dưới 20 khách. Số lượng, thành phần của đoàn khách sẽ chịu sự điều phối của Trung tâm diễn giải môi trường và dịch vụ của Vườn. Việc này nhằm đảm bảo khai thác du lịch trong khuôn khổ sức tải môi trường, giảm thiểu tối đa các tác động lên hệ sinh thái của Vườn quốc gia Cát Tiên\n- Đoàn khách phải có hướng dẫn viên đi cùng.',

    // tour 12

    tour_12_title: 'Tuyến Rừng Bằng Lăng đi Đà Cộ',
    tour_12_address:
      'ấp 1, xã Nam Cát Tiên, huyện Tân Phú, Tỉnh Đồng Nai, Vietnam',
    tour_12_synopsys_1:
      'Con đường xuyên rừng nối từ Trung tâm VQG Cát Tiên đến Đà Cộ như được thiên nhiên đặc biệt tác tạo dành cho những du khách yêu thích hình thức trekking hay đạp xe du ngoạn rừng. Đà Cộ không chỉ có rừng bằng lăng xinh tươi vào cả mùa hoa đầu mưa lẫn mùa thay lá, mà còn là vùng đất của các loài linh trưởng đặc hữu ở Cát Tiên như vượn đen má vàng, voọc chà vá chân đen... Ngắm những vạt nắng xuyên qua các tầng rừng thường xanh và tán lá biếc mang đến cảm giác an nhiên tĩnh tại, là một trong những trải nghiệm khó quên.',
    tour_12_synopsys_2:
      '\n- Độ dài tuyến: 10 km\n- Phương thức di chuyển trên tuyến: Ô tô, xe đạp và đi bộ\nCảnh quan sông Đồng Nai, thác nước, ngắm Vượn, chim … chụp ảnh và trải nghiệm thiên nhiên rừng nhiệt đới.\nTạo ra cơ hội việc làm cho cộng đồng từ việc lưu trú, ăn uống, mua sản phẩm địa phương, hướng dẫn viên; góp phần bảo tồn văn hóa bản địa từ xem chương trình biểu diễn văn hóa truyền thống; giảm thiểu việc xâm hại môi trường rừng do sinh kế; đa dạng hóa sản phẩm du lịch, tăng thu nhập cho Vườn; tuyên truyền bảo vệ môi trường rừng.     ',
    tour_12_schedule_day_0_time_1:
      ' - Thời gian dự kiến: 2 - 4 tiếng (kết hợp ô tô }, xe đạp 4 - 5 tiếng đi về trong ngày ',
    tour_12_schedule_day_0_title_1:
      '- Lịch trình dự kiến: Khởi hành từ Trung tâm Vườn, cây Tung cổ thụ, Thác Bến Cự, Vườn thực vật, Thác Trời - Rừng Bằng Lăng - Trạm kiểm lâm Đà Cộ - Trung tâm Vườn.',
    tour_12_notice:
      '\n- Khách thích mạo hiểm, khám phá và trải nghiệm thiên nhiên (lưu ý là cần có sức khoẻ tốt, có khả năng đi bộ dài)\n- Đoàn phù hợp tham gia tour này là dưới 20 khách. Số lượng, thành phần của đoàn khách sẽ chịu sự điều phối của Trung tâm diễn giải môi trường và dịch vụ của Vườn. Việc này nhằm đảm bảo khai thác du lịch trong khuôn khổ sức tải môi trường, giảm thiểu tối đa các tác động lên hệ sinh thái của Vườn quốc gia Cát Tiên\n- Đoàn khách phải có hướng dẫn viên đi cùng.',

    // tour 13

    tour_13_title: 'Tuyến đường mòn Đá Bàn - Bầu Sấu',
    tour_13_address:
      'ấp 1, xã Nam Cát Tiên, huyện Tân Phú, Tỉnh Đồng Nai, Vietnam',
    tour_13_synopsys_1:
      'Trekking chưa bao giờ thú vị đến thế với con đường mòn băng rừng qua suối Đá Bàn nối về Bàu Sấu. Đặc biệt vào mùa mưa, con suối reo vui róc rách sẽ làm dịu bước lãng du trong những giờ phút dừng chân ngơi nghỉ giữa hành trình. Những tán rừng thường xanh với vô số cây gỗ quý hàng trăm năm tuổi và các dấu vết của thú rừng để lại là minh chứng cho sự đa dạng sinh học và giá trị quý báu của rừng nguyên sinh Cát Tiên.',

    tour_13_synopsys_2:
      '\n- Độ dài tuyến: 10 km\n- Phương thức di chuyển trên tuyến: Ô tô (3 km) và đi bộ (7 km)\nKhám phá hệ sinh thái rừng, ngắm các loài động vật hoang dã: Linh trưởng, Thú ăn cỏ, Chim… chụp ảnh và trải nghiệm thiên nhiên rừng nhiệt đới.\nTạo ra cơ hội việc làm cho cộng đồng từ việc lưu trú, ăn uống, mua sản phẩm địa phương, hướng dẫn viên; góp phần bảo tồn văn hóa bản địa từ xem chương trình biểu diễn văn hóa truyền thống; giảm thiểu việc xâm hại môi trường rừng do sinh kế; đa dạng hóa sản phẩm du lịch, tăng thu nhập cho Vườn; tuyên truyền bảo vệ môi trường rừng.',
    tour_13_schedule_day_0_time_1: '- Thời gian dự kiến: 2 ngày 1 đêm ',
    tour_13_schedule_day_0_title_1:
      '- Lịch trình dự kiến: Khởi hành Trung tâm Vườn -  Suối Đá Bàn - đi bộ xuyên rừng 10 km: sinh cảnh rừng già, Gõ đỏ, Dây leo Bàm Bàm, Suối cạn - Bầu Sấu - Trung tâm Vườn.',
    tour_13_notice:
      '\n- Khách thích mạo hiểm, khám phá và trải nghiệm thiên nhiên (lưu ý là cần có sức khoẻ tốt, có khả năng đi bộ dài)\n- Đoàn phù hợp tham gia tour này là dưới 10 khách. Số lượng, thành phần của đoàn khách sẽ chịu sự điều phối của Trung tâm diễn giải môi trường và dịch vụ của Vườn. Việc này nhằm đảm bảo khai thác du lịch trong khuôn khổ sức tải môi trường, giảm thiểu tối đa các tác động lên hệ sinh thái của Vườn quốc gia Cát Tiên\n- Đoàn khách phải có hướng dẫn viên đi cùng.',

    // tour 14

    tour_14_title: 'Tuyến Tà Lài đi Hang Dơi, Đồi Xanh, Đồi Đá Trắng',
    tour_14_address:
      'ấp 1, xã Nam Cát Tiên, huyện Tân Phú, Tỉnh Đồng Nai, Vietnam',
    tour_14_synopsys_1:
      'Đồi núi và hang động luôn có nét cuốn hút rất riêng. Sau thời gian dừng chân ngơi nghỉ nhìn ngắm và lắng nghe về nền văn hóa đồng bào dân tộc thiểu số ở Tà Lài, hành trình khám phá hang dơi bí ẩn đang đón chờ bước chân du khách. Hành trình kết lại bằng chuyến thăm đồi Xanh, đồi Đá Trắng - thắng cảnh từng là điểm nóng của giới đào vàng trong quá khứ.',
    tour_14_synopsys_2:
      '\n- Độ dài tuyến: 25 km\n- Phương thức di chuyển trên tuyến: 10 km đi ô tô, 15 km đi bộ xuyên rừng\nTham quan cảnh quan làng dân tộc, đồng lúa, trải nghiệm các nét văn hoá các dân tộc Mạ và S’Tiêng. Ngắm các loài linh trưởng, Nai, Heo, Chim… chụp ảnh và trải nghiệm thiên nhiên rừng nhiệt đới.\nTạo ra cơ hội việc làm cho cộng đồng từ việc lưu trú, ăn uống, mua sản phẩm địa phương, hướng dẫn viên; góp phần bảo tồn văn hóa bản địa từ xem chương trình biểu diễn văn hóa truyền thống; giảm thiểu việc xâm hại môi trường rừng do sinh kế; đa dạng hóa sản phẩm du lịch, tăng thu nhập cho Vườn; tuyên truyền bảo vệ môi trường rừng.',
    tour_14_schedule_day_0_time_1:
      '- Thời gian dự kiến: 6- 8 tiếng đi về trong ngày hoặc ngủ lại qua đêm trong rừng ',
    tour_14_schedule_day_0_title_1:
      '- Lịch trình dự kiến: Khởi hành từ Trung tâm Vườn, Trung tâm cứu hộ Gấu, Nhà nghỉ Kơ nia, làng dân tộc Tà Lài, Nhà dài Tà Lài - Hang Dơi - Đồi Xanh - Trung tâm Vườn. ',
    tour_14_notice:
      '\n- Khách thích mạo hiểm, khám phá và trải nghiệm thiên nhiên (lưu ý là cần có sức khoẻ tốt, có khả năng đi bộ dài)\n- Đoàn phù hợp tham gia tour này là dưới 10 khách. Số lượng, thành phần của đoàn khách sẽ chịu sự điều phối của Trung tâm diễn giải môi trường và dịch vụ của Vườn. Việc này nhằm đảm bảo khai thác du lịch trong khuôn khổ sức tải môi trường, giảm thiểu tối đa các tác động lên hệ sinh thái của Vườn quốc gia Cát Tiên\n- Đoàn khách phải có hướng dẫn viên đi cùng. Đoàn có thể thuê thêm 1 người hỗ trợ hành lý vì tuyến dài',

    // tour 15

    tour_15_title:
      'Tuyến du lịch trải nghiệm bơi xuồng dọc sông Đồng Nai, từ Đà Cộ đến bến phà VQGCT',
    tour_15_address:
      'ấp 1, xã Nam Cát Tiên, huyện Tân Phú, Tỉnh Đồng Nai, Vietnam',
    tour_15_synopsys_1:
      'Hành trình bơi xuồng/ chèo thuyền từ Đà Cộ đến bến phà VQG Cát Tiên là một trong những hoạt động thể thao ngoài trời lý thú và đầy hấp dẫn cho những du khách ưa vận động, háo hức trước những thử thách. Chuyến hành trình đường sông này đòi hỏi du khách phải đưa thuyền vượt qua khúc sông khoảng 7km với 2 đoạn ghềnh đá khúc khuỷu, nhiều chướng ngại vật. Đây là tour khám phá - thử thách đặc biệt thiết kế cho các vị du khách yêu thích loại hình du lịch khám phá - thám hiểm. Chinh phục một đoạn sông Đồng Nai bao bọc ranh giới Vườn quốc gia có vẻ là một thử thách hấp dẫn và đầy lôi cuốn, là một điểm nhấn kỳ thú trong chuyến đi của bạn.',
    tour_15_synopsys_2:
      '\n- Độ dài tuyến: 10 km\n- Phương thức di chuyển trên tuyến: Ô tô hoặc xe đạp và xuồng Kayak hoặc xuồng cao su bơm hơi\n- Trải nghiệm bơi xuồng đi qua các ghềnh thác nước trên sông Đồng Nai, ngắm cảnh thiên và thú hoang dã bên bờ sông.\nTạo ra cơ hội việc làm cho cộng đồng từ việc lưu trú, ăn uống, mua sản phẩm địa phương, hướng dẫn viên; góp phần bảo tồn văn hóa bản địa từ xem chương trình biểu diễn văn hóa truyền thống; giảm thiểu việc xâm hại môi trường rừng do sinh kế; đa dạng hóa sản phẩm du lịch, tăng thu nhập cho Vườn; tuyên truyền bảo vệ môi trường rừng.',
    tour_15_schedule_day_0_time_1:
      '- Thời gian dự kiến: 6 - 8 tiếng đi về trong ngày ',
    tour_15_schedule_day_0_title_1:
      '- Lịch trình dự kiến: Khởi hành từ Trung tâm Vườn - Trạm kiểm lâm Đà Cộ - Thác Trời, Thác Dựng, Đảo Tiên -  đến Bến phà VQG.  ',
    tour_15_notice:
      '\n- Khách thích mạo hiểm, khám phá và trải nghiệm thiên nhiên (khách team building)\n- Du khách tuyệt đối không xả rác xuống sông\n- Đoàn phù hợp tham gia tour này là dưới 10 khách. Số lượng, thành phần của đoàn khách sẽ chịu sự điều phối của Trung tâm diễn giải môi trường và dịch vụ của Vườn. Việc này nhằm đảm bảo khai thác du lịch trong khuôn khổ sức tải môi trường, giảm thiểu tối đa các tác động lên hệ sinh thái của Vườn quốc gia Cát Tiên\n- Đoàn khách phải có hướng dẫn viên đi cùng. Nếu khách ngủ đêm trong rừng, đoàn có thể thuê thêm 1 người hỗ trợ hành lý',

    // tour 16

    tour_16_title: 'Tuyến đường Đà Cộ - Đà Lắc- Đắc Lua',
    tour_16_address:
      'ấp 1, xã Nam Cát Tiên, huyện Tân Phú, Tỉnh Đồng Nai, Vietnam',
    tour_16_synopsys_1:
      'Tuyến đường Đà Cộ - Đà Lắc - Đắc Lua chắc chắn vô cùng hấp dẫn đối với các du khách là nhiếp ảnh gia chuyên nghiệp và nghiệp dư, và bất kỳ ai yêu thích vẻ đẹp tươi tắc giàu màu sắc của các loài chim họ nhà gà, như chim trĩ, gà lôi hông tía, gà rừng, gà tiền mặt đỏ... Tuyến đường rừng này tương đối bằng phẳng, dễ đi, đáp ứng được mọi hình thức tham quan bằng ô tô, đi bộ và xe đạp. Sinh cảnh rừng cũng được làm phong phú thêm bởi dòng suối Đắc Lua giàu trữ lượng nước vào mùa mưa, hiền hòa vào mùa khô. Xa hơn về hướng Đắc Lua là những cánh đồng lúa, vườn tược mùa màng đặc trưng miền Đông Nam bộ đón chờ du khách.',
    tour_16_synopsys_2:
      '\n- Độ dài tuyến: 18 km\n- Phương thức di chuyển trên tuyến: Ô tô và đi bộ xuyên rừng\n- Khám phá hệ sinh thái rừng, ngắm các loài thú móng vuốt, Linh trưởng, thú ăn cỏ, chim… chụp ảnh và trải nghiệm thiên nhiên rừng nhiệt đới.\nTạo ra cơ hội việc làm cho cộng đồng từ việc lưu trú, ăn uống, mua sản phẩm địa phương, hướng dẫn viên; đa dạng hóa sản phẩm du lịch, tăng thu nhập cho Vườn; tuyên truyền bảo vệ môi trường rừng.',
    tour_16_schedule_day_0_time_1:
      '-- Thời gian dự kiến: 8 tiếng đi về trong ngày ô tô , 2 ngày 1 đêm đi bộ',
    tour_16_schedule_day_0_title_1:
      'Khởi hành từ Trung tâm Vườn - Trạm kiểm lâm Đà Cộ - Trạm kiểm lâm Đà Mí - Đà Lắc -\n- Lịch trình dự kiến: Trạm kiểm lâm Đắc Lua. Đi dọc sông Đồng Nai, qua đồng cỏ, tre nứa, rừng trồng, rừng già, chuối rừng, bàu nước xem chim nước - Trung tâm Vườn. ',
    tour_16_notice:
      '\n- Khách thích mạo hiểm, khám phá và trải nghiệm thiên nhiên (lưu ý là cần có sức khoẻ tốt, có khả năng đi bộ dài)\n- Đoàn phù hợp tham gia tour này là dưới 10 khách. Số lượng, thành phần của đoàn khách sẽ chịu sự điều phối của Trung tâm diễn giải môi trường và dịch vụ của Vườn. Việc này nhằm đảm bảo khai thác du lịch trong khuôn khổ sức tải môi trường, giảm thiểu tối đa các tác động lên hệ sinh thái của Vườn quốc gia Cát Tiên\n- Đoàn khách phải có hướng dẫn viên đi cùng.',

    // tour 17

    tour_17_title: 'Tuyến Tà Lài đi Đồi Đất đỏ, Sa Mách, Suối Ràng',
    tour_17_address:
      'ấp 1, xã Nam Cát Tiên, huyện Tân Phú, Tỉnh Đồng Nai, Vietnam',
    tour_17_synopsys_1:
      'Các cánh rừng hỗn giao đan xen rừng tre nứa thuần loại mở ra một cánh cổng xanh đón chào du khách đến với đồi Đất Đỏ. Tiếp tục hành trình, du khách hoàn thành 40km đường rừng để dừng chân tại Sa Mách và tiếp tục di chuyển đến điểm nhấn thú vị nhất của chuyến đi là con suối Ràng đẹp và trong xanh chảy xuyên lõi rừng Cát Tiên. Thác Ràng và đập tràn suối Ràng chắc chắn là điểm dừng chân không thể thiếu trong chuyến tham quan này. Trên đường đi, du khách có thể quan sát một số loài linh trưởng và chim chóc trong sinh cảnh rừng yêu thích của chúng.',
    tour_17_synopsys_2:
      '\n- Độ dài tuyến: 27 km\n- Phương thức di chuyển trên tuyến: Ô tô,  ưu tiên Xe đạp\nTham quan cảnh quan làng dân tộc, đồng lúa, trải nghiệm các nét văn hoá các dân tộc Mạ và S’Tiêng. Đặc biệt có thể ngắm các loài thú lớn Voi, Bò Tót, Linh trưởng, chim… chụp ảnh và trải nghiệm thiên nhiên rừng nhiệt đới. Cung đường này vượt qua nhiều đồi núi và khe suối…\nTạo ra cơ hội việc làm cho cộng đồng từ việc lưu trú, ăn uống, mua sản phẩm địa phương, hướng dẫn viên; góp phần bảo tồn văn hóa bản địa từ xem chương trình biểu diễn văn hóa truyền thống; giảm thiểu việc xâm hại môi trường rừng do sinh kế; đa dạng hóa sản phẩm du lịch, tăng thu nhập cho Vườn; tuyên truyền bảo vệ môi trường rừng.',
    tour_17_schedule_day_0_time_1:
      '- Thời gian dự kiến: Đi ô tô hết 6 - 8 tiếng (đi về trong ngày). Đi bộ - 2 ngày 1 đêm',
    tour_17_schedule_day_0_title_1:
      '- Lịch trình dự kiến: Ô tô khởi hành từ Trung tâm Vườn - Trạm kiểm lâm Tà Lài qua Đồi Đá Trắng - Trạm kiểm lâm Đất đỏ - Sa Mách - Xe đạp và đi bộ xuyên rừng từ Sa Mách…  Trạm kiểm lâm Suối Ràng - Trung tâm Vườn. ',
    tour_17_notice:
      '\n- Khách thích mạo hiểm, khám phá và trải nghiệm thiên nhiên (lưu ý là cần có sức khoẻ tốt, có khả năng đi bộ dài)\n- Đoàn phù hợp tham gia tour này là dưới 10 khách. Số lượng, thành phần của đoàn khách sẽ chịu sự điều phối của Trung tâm diễn giải môi trường và dịch vụ của Vườn. Việc này nhằm đảm bảo khai thác du lịch trong khuôn khổ sức tải môi trường, giảm thiểu tối đa các tác động lên hệ sinh thái của Vườn quốc gia Cát Tiên\nĐoàn phải có hướng dẫn viên và kiểm lâm đi cùng.',
  },
  en: {
    // tour 1
    tour_1_title: 'Explore Dong Nai Nature and Culture Reserve',
    tour_1_address:
      'Dong Nai Nature and Culture Reserve \n Ap 1, Xa Ma Da, Vinh Cuu District, Dong Nai',
    tour_1_synopsys_1:
      'Dong Nai, a land of "unique natural wonders" famous for many beautiful landscapes. One of the most unique tourist destinations is the Trị An Lake. With an area of ​​32,400 hectares, surrounded by the Dong Nai Biosphere Reserve. This place is favored by nature with clear blue water, decorated with 72 small and large islands. On a yacht, you can admire the endless stretches of forest, flocks of storks flying, and the fishing activities of local fishermen on the mountain lake.\n In addition, Trị An Lake is also an artificial lake that attracts many tourists to visit, check-in, and organize many outdoor activities such as camping, fishing, and boating. Coming to Trị An Lake is also an opportunity for tourists to approach the Mã Đà tropical forest - one of the last rainforests successfully protected for rare animals such as elephants, tapirs, and bears. The forest has a rich and diverse flora and fauna, so it has been recognized by UNESCO as a World Biosphere Reserve.\n This place was once famous for the epic "Dai Ngan Battle Zone D". With two national-level historical relics; The D Zone and the Southern Central Bureau are located deep in the Mã Đà forest.\n The unique features of the terrain and landscape of forests, rivers and lakes, culture and history have created a unique and unparalleled beauty of wilderness nature. Trị An Lake will surely be an interesting destination for visitors to learn, research, experience, and explore the ecosystem of the tropical rainforest.',
    tour_1_synopsys_2:
      'Dong Nai, a land of "unique natural wonders" famous for many beautiful landscapes. One of the most unique tourist destinations is the Trị An Lake. With an area of ​​32,400 hectares, surrounded by the Dong Nai Biosphere Reserve. This place is favored by nature with clear blue water, decorated with 72 small and large islands. On a yacht, you can admire the endless stretches of forest, flocks of storks flying, and the fishing activities of local fishermen on the mountain lake.\n In addition, Trị An Lake is also an artificial lake that attracts many tourists to visit, check-in, and organize many outdoor activities such as camping, fishing, and boating. Coming to Trị An Lake is also an opportunity for tourists to approach the Mã Đà tropical forest - one of the last rainforests successfully protected for rare animals such as elephants, tapirs, and bears. The forest has a rich and diverse flora and fauna, so it has been recognized by UNESCO as a World Biosphere Reserve.',
    tour_1_schedule_day_1_time_1: '08h00',
    tour_1_schedule_day_1_title_1:
      'Tour guide picks up the group at Ba Hao Thai Center Office, Hamlet 5, Ma Da Commune, Vinh Cuu District, Dong Nai Province.',
    tour_1_schedule_day_1_time_2: '08h15-08h45',
    tour_1_schedule_day_1_title_2:
      'The tour guide let the group move into the War Zone D historical relic to learn about the heroic land, the hard-working East but the heroic.',
    tour_1_schedule_day_1_time_3: '09h00-10h30',
    tour_1_schedule_day_1_title_3:
      'Guide for the delegation to offer incense in memory of heroes and martyrs and give instructions on the relic of the heroic and resilient War Zone D. ',
    tour_1_schedule_day_1_des_3: '',
    tour_1_schedule_day_1_time_4: '10h30-11h00',
    tour_1_schedule_day_1_title_4:
      'Get on the bus to move to Ba Hao Ecological Center, have lunch and rest to visit and take pictures at Ba Hao Ecological Center with an area of ​​15.7h with many beautiful scenes only available in Bao Bao area. Conservation of Nature- Culture Dong Nai',
    tour_1_schedule_day_1_des_4: '',
    tour_1_schedule_day_1_time_5: '13h00-13h30',
    tour_1_schedule_day_1_title_5:
      'The group gets on the bus to move to the area of ​​Glade B in the Ma Da forest.\n',
    tour_1_schedule_day_1_time_6: '13hh30 -15h00',
    tour_1_schedule_day_1_title_6:
      "The eco-guide will lead the group to walk, wade through streams, visit the forest and study with the rangers explaining biodiversity, the ecosystem in the forest, and walking skills. forest, survival skills, and some medical therapies such as forest bathing (Shinrin-yoku) feel nature and listen to the forest's breath at Dong Nai Culture and Nature Reserve.",

    tour_1_schedule_day_1_des_6: '',

    tour_1_schedule_day_2_time_1: '15h00-15h30',
    tour_1_schedule_day_2_title_1: 'Farewell to the delegation!See you again.',
    // tour_1_schedule_day_2_time_2: '06:30 – 08:30: ',
    // tour_1_schedule_day_2_title_2:
    //   ' Breakfast on the boat, visit Suoi Tuong raft village ',
    //
    // tour_1_schedule_day_2_des_2:
    //   'Suoi Tuong raft village has more than 100 households, is a raft village of Cambodian overseas Vietnamese who have lived as fishermen and have stayed with Tri An lake for over 30 years, forming a culture on the lake and fishing village of rapids. The net is very lively.) \n- Exchanges with the expatriates here (can give gifts to the poor, give school tools to the students.)\n- Visit the fishing wharf where all are gathered. and fresh seafood at Tri An Lake. Here you can buy seafood as gifts.',
    // tour_1_schedule_day_2_time_3: '10:00 – 11:30: ',
    // tour_1_schedule_day_2_title_3:
    //   ' Trekking to explore the Ma Da rainforest ecosystem.',
    // tour_1_schedule_day_2_des_3:
    //   'Check in the 500-year-old civet tree, wading in the middle of the forest.',
    //
    // tour_1_schedule_day_2_time_4: '12h00:',
    // tour_1_schedule_day_2_title_4:
    //   ' have lunch and rest at Dong Nai Nature and Culture Reserve.',
    //
    // tour_1_notice:
    //   'Hat, drinking water, cycling sports clothes, swimwear, shoes, flashlight, magnifying glass, binoculars, sunscreen, insect repellent, toiletries and at least 02 changing clothes. ',
    // tour 2

    tour_2_title: 'BICYING THE D-ZONE - TRANG BE',
    tour_2_address:
      'Dong Nai Nature - Culture Reserve \nup 1, Ma Da Commune, Vinh Cuu District, Dong Nai',
    tour_2_synopsys_1:
      'Rolling on an important historical site on the battlefield of War Zone D during the two resistance wars against the French Colonialists and the invading American Empire, learning the imprints of the land of "the hard but heroic East"',
    tour_2_synopsys_2:
      'Rolling on an important historical site on the battlefield of War Zone D during the two resistance wars against the French Colonialists and the invading American Empire, learning the imprints of the land of "the hard but heroic East"',
    tour_2_schedule_day_1_time_1: '07h30:',
    tour_2_schedule_day_1_title_1:
      'The guide will pick you up at the Center for Ecology - Culture - History of War Zone D, check in at the hotel.',
    tour_2_schedule_day_1_time_2: '08h00',
    tour_2_schedule_day_1_title_2:
      'Forest rangers welcome the delegation from the Office of Dong Nai Nature and Culture Conservation Area, Hamlet 1, Ma Da Commune, Vinh Cuu District, Dong Nai Province.\n ',
    tour_2_schedule_day_1_des_2: '',
    tour_2_schedule_day_1_time_3: '08h00-08h30',
    tour_2_schedule_day_1_title_3:
      ' The tour guide takes the group to move to Hieu Liem dam bank, where to receive bicycles. The tour guide introduces the cycling program and things to note.',

    tour_2_schedule_day_1_time_4: '08h30-11h00',
    tour_2_schedule_day_1_title_4:
      "The delegation received bicycles and started cycling about 18km. On the way, the delegation was able to see the beautiful scenery of the Reserve's forest and was introduced to the flora and fauna in the Reserve 's forest.",

    tour_2_schedule_day_1_des_4: '',

    tour_2_schedule_day_1_time_5: '11h00-12h30',
    tour_2_schedule_day_1_title_5: 'The group had lunch at the tree of Kônia',

    tour_2_schedule_day_1_time_6: '12h30-13h00',
    tour_2_schedule_day_1_title_6: 'The group rests on the spot.',

    tour_2_schedule_day_1_time_7: '13h00-13h30',
    tour_2_schedule_day_1_title_7:
      'The group moved by bicycle to return to provincial road 761 (distance 1km).\n',
    tour_2_schedule_day_1_time_8: '13h30',
    tour_2_schedule_day_1_title_8:
      'End the program. Farewell HDV group on the bus back to the original departure point.\n',

    // tour_2_schedule_day_2_time_1: '07:00 ',
    // tour_2_schedule_day_2_title_1: 'Breakfast.',
    //
    // tour_2_schedule_day_2_time_2: '08:30',
    // tour_2_schedule_day_2_title_2:
    //   ' Walking tour of the natural forest (walking 06 km).',
    //
    // tour_2_schedule_day_2_time_3: '11:30',
    // tour_2_schedule_day_2_title_3: 'Lunch.',
    // tour_2_schedule_day_2_time_4: '14:30',
    // tour_2_schedule_day_2_title_4:
    //   'Farewell to the forest of War Zone D end the tour to the source.',
    //
    // tour_2_notice: 'Distance: 20km (hard terrain)',

    // tour 3

    tour_3_title:
      'Route from the Center to Cay Tung, Bang Lang 5 tops, Uncle Dong Tree, Banyan Tree Giao',
    tour_3_address: 'Cat Tien National Park',
    tour_3_synopsys_1:
      'On the same most convenient forest road, the great Tung tree has the majestic appearance of the old forest, the rare red oak tree is hundreds of years old, the banyan tree is like a giant spider and intertwined with the branches. The forest dotted by the beautiful bright shell of the mausoleum complex is waiting for visitors to explore the tropical rainforest of Cat Tien. ',
    tour_3_synopsys_2:
      '\n- Route length: 3 km\n- Mode of travel on the route: Car or bicycle and walking through the forest\n- Main activities on the route:\n + Observing the forest habitat, watching the wild animals (Early morning or late afternoon): primates, pigs, deer, birds… take pictures.\n+ Experience the nature of the rainforest: in the rainy season, you can see Bang Lang flowers, Peach flowers; In the dry season, watching the forest change leaves, the strange tree Wire Bam Bam…\n- Meaning: The tourist route creates job opportunities for the community from the use of means of transport (bicycles, cars) eating, drinking, buy local products, tour guides; contribute to the preservation of indigenous culture from watching traditional cultural shows; diversify tourism products, increase income for the Garden; propaganda for the protection of the forest environment. ',

    tour_3_schedule_day_0_time_1:
      'Estimated time: 2-4 hours and return in the same day',
    tour_3_schedule_day_0_title_1:
      "Estimated schedule: Departure from Garden Center - Tung Tree - Bang Lang 5 tops - Uncle Dong's Wood Tree - Banyan Tree Giao - Garden Center. ",

    tour_3_notice:
      '- The route is for visitors who have little time and like to explore and experience wild nature \n- Visitors are not allowed to litter, write or carve on trees, make noise to affect wildlife',

    // tour 4

    tour_4_title:
      'The hundred-stemmed Cay Si route, the population of Bang Lang Loc Binh, siDai Phong Tu forest scene',
    tour_4_address: 'Cat Tien National Park',
    tour_4_synopsys_1:
      '17km through the forest is a relatively long distance for visitors to feel the fresh air of the largest primeval forest in the South. Throughout the route are evergreen and mixed forest habitats with typical tropical rainforest plant species. Visitors will be able to see the cluster of Bang Lang Loc Binh with a unique and strange shape created by the hands of nature and many magnificent and large perennial forest trees. Besides, Dai Phong Tu forest with seasonal wetland habitat and small green stream all year round is an ideal place for picnic activities, overnight camping.',
    tour_4_synopsys_2:
      '\n- Route length: 16 km\n- Mode of traveling on the route: Car or bicycle and walking through the forest\n- Activities on the route: Observing wildlife habitats: primates, pigs, deer, birds…; bathing in Lake Cay Si; take pictures and experience the nature of tropical forests: in the rainy season, you can see Bang Lang flowers and peach flowers; dry season watching the forest change leaves ...\n- Meaning: Creating job opportunities for the community from accommodation, eating, buying local products, guides; contribute to the preservation of indigenous culture from watching traditional cultural shows; minimize the encroachment on forest environment by livelihood; diversify tourism products, increase income for the Garden; propaganda to protect the forest environment.',
    tour_4_schedule_day_0_time_1:
      '- Estimated time: 3-4 hours or 6-8 hours going back and forth during the day (lunch at Dac Lua Station',
    tour_4_schedule_day_0_title_1:
      "- Estimated schedule: Departure from the Garden Center - Ancient Tung tree - Uncle Dong's persimmon tree - Da Loc Giao tree - E7 intersection - Bang Lang water hyacinth complex - Dai Phong Tu glade , watching fish…) - Si tree with hundreds of stems - Garden Center. ",

    tour_4_notice:
      '- The route is for visitors who love to adventure, explore and experience nature (note that good health is required, capable of long walks)\n- Visitors are not allowed to litter, write or carve on trees .\n- Visitors are not allowed to make noise to affect wild animals.\n- The suitable group to join this tour is less than 20 guests. The number and composition of the delegation will be coordinated by the Center for Environmental Interpretation and Services of the Park. This is to ensure tourism exploitation within the framework of environmental capacity, minimizing impacts on the ecosystem of Cat Tien National Park.',

    // tour 5

    tour_5_title: 'Nightmazing route from the Center to Nui Tuong',
    tour_5_address: 'Cat Tien National Park',
    tour_5_synopsys_1:
      'Cat Tien is the only place in Vietnam where you can see wildlife right in their natural habitat. Visitors will go through the vast grasslands - the ideal habitat for ungulates and small carnivores, and see with their own eyes the deer, porcupines, rabbits, weasels... are looking for food. Depending on the season and weather of the year, plus a little luck, the group of tourists can meet a herd of wild boar or a herd of gaurs, a healthy, wild beauty.',
    tour_5_synopsys_2:
      '\n- Route length: 6 km\n- Mode of travel on the route: By car\nActivities on the route: Observing forest habitats, watching wild animals, herbivores, birds... taking photos and experience the nature of the rainforest at night.\nCreate job opportunities for the community from staying, eating, buying local products, guides; contribute to the preservation of indigenous culture from watching traditional cultural shows; minimize the encroachment on forest environment by livelihood; diversify tourism products, increase income for the Garden; propaganda to protect the forest environment.',
    tour_5_schedule_day_0_time_1: '- Estimated time: 1 hour',
    tour_5_schedule_day_0_title_1:
      '- Estimated schedule: Departure from Garden Center - Bear Rescue Center - Night Animal Viewing Area - Nui Tuong Ranger Station (Ko Nia Guest House) - Garden Center.',
    tour_5_notice:
      "- Guests stay at the Garden, or resorts and homestays in the buffer zone. \n- Suitable for both international and domestic visitors.\n- The frequency of the tour in a day, the number and composition of the delegation will be coordinated by the Garden's Environmental Interpretation Center and Services. This is to ensure tourism exploitation within the framework of environmental capacity, minimizing impacts on the ecosystem of Cat Tien National Park.",

    // tour 6

    tour_6_title:
      'Dong Nai river habitat experience route, from Garden Center to Ta Lai',
    tour_6_address: 'Cat Tien National Park',
    tour_6_synopsys_1:
      '12km along the longest inland river in Vietnam wraps the boundary of Cat Tien forest to come to the long-standing indigenous culture in Dong Nai that always has its own attraction. Visitors have the opportunity to see the section of the Dong Nai River that creates the natural boundary for Cat Tien National Park, and right across the bank is a buffer zone followed by layers of fields and lush orchards promising a bountiful harvest. The community of Chau Ma people, the native Stieng people of this country, will welcome visitors at a traditional long house, with the sound of a rustic stringed instrument and a hearty stove.Warm hospitality and fresh like colorful brocade patterns.',
    tour_6_synopsys_2:
      '\n- Route length: 12 km\n- Mode of travel on the route: Boat, car (First time by boat, return by car)\n- Experience river habitats, wild animals wild, birds…, Ta Lai field, Ta Lai Ethnic Village… take pictures and experience the nature of the rainforest.\n-Create job opportunities for the community from staying, eating, buying products local, tour guide; contribute to the preservation of indigenous culture from watching traditional cultural shows; minimize the encroachment on forest environment by livelihood; diversify tourism products, increase income for the Garden; propaganda to protect the forest environment.',
    tour_6_schedule_day_0_time_1:
      '- Estimated time: 4-6 hours to return in a day (Dry Season)',
    tour_6_schedule_day_0_title_1:
      "- Estimated schedule: Departure from the Garden Center, experience 11 km on Dong Nai River: see alluvial plains growing vegetables and fruits and visit the riverside households to buy bananas, jackfruit, pomelo...; model of bird's nest house, homestay model - Ta Lai indigenous ethnic village - Garden Center.",

    tour_6_notice:
      '- Tourists absolutely do not litter into the river, causing noise to affect wild animals. \n- The suitable group to join this tour is less than 20 guests. The number and composition of the delegation will be coordinated by the Center for Environmental Interpretation and Services of the Park. This is to ensure tourism exploitation within the framework of the environmental capacity, minimizing the impact on the ecosystem of Cat Tien National Park\n- Visitors absolutely comply with the safety principles when participating in the tour. water transport',

    // tour 7

    tour_7_title:
      'Primate observation route from Thac Troi - Da Co - Botanical garden',
    tour_7_address: 'Cat Tien National Park',
    tour_7_synopsys_1:
      'Tian waterfall, Da Co and the botanical gardens of Cat Tien forest are indispensable names if tourists want to see primates, especially endemic species such as yellow-cheeked gibbon, douc langur. Black-legged langur, Truong Son silver langur, coolie... A quiet, peaceful forest, only echoing the chirping of birds and gibbons, the sound of douc flocks passing branches will be a great destination - a wonderful destination for tourists. nature lovers.',
    tour_7_synopsys_2:
      '\n- Route length: 15 km\n- Method of traveling on the route: Car, bicycle and walking through the forest\n- Observing the forest habitat, primates species in Thac Troi - Da Co section… take pictures and experience the nature of the rainforest.\nCreate job opportunities for the community from guides; contribute to the preservation of indigenous culture from watching traditional cultural shows; minimize the encroachment on forest environment by livelihood; diversify tourism products, increase income for the Garden; propaganda for the protection of the forest environment. ',
    tour_7_schedule_day_0_time_1:
      ': 3 hours (car and walking}, 4 hours (bicycle and walking). ',
    tour_7_schedule_day_0_title_1:
      '- Suitable departure time: early morning or late afternoon.\n- Estimated schedule: Departure from Garden Center, Ben Cu, Botanical Garden, Troi Waterfall, Ancient Bang Lang Forest - Garden Center. ',
    tour_7_notice:
      '- Guests who love to explore nature, especially love primates (note that good health is required, able to walk long)\n- Suitable for both international and domestic visitors.\n- Visitors do not wild animals are allowed to eat.\n- The suitable group to join this tour is less than 10 guests. The number and composition of the delegation will be coordinated by the Center for Environmental Interpretation and Services of the Park. This is to ensure tourism exploitation within the framework of environmental capacity, minimizing impacts on the ecosystem of Cat Tien National Park.',

    tour_8_title: 'Ta Lai to Bau Sau forest trekking route',
    tour_8_address: 'Cat Tien National Park',
    tour_8_synopsys_1:
      'The trekking route through the forest from Ta Lai to Bau Sau is an opportunity for visitors to directly observe and feel the indigenous culture of the Chau Ma and Stieng ethnic groups through the architecture of long houses, handmade brocade weaving, and Enjoy the traditional string music. In the second leg of the journey, visitors can witness many big trees, woody vines, observe many species of birds and animals, and learn about the rare freshwater crocodile species that only exist in the wild. nature in Cat Tien National Park. ',
    tour_8_synopsys_2:
      "\n- Route length: 14 km\n- Mode of travel on the route: Walking through the forest\n-Visiting the landscape of ethnic villages, rice fields, experiencing the cultural features of the Ma and S ethnic groups 'Tieng. See primates, birds, herbivores: Gaur, Deer, Pig... take photos and experience the nature of the rainforest.\nCreate job opportunities for the community from accommodation, eating, buying products local products, guides; contribute to the preservation of indigenous culture from watching traditional cultural shows; minimize the encroachment on forest environment by livelihood; diversify tourism products, increase income for the Garden; propaganda to protect the forest environment.",
    tour_8_schedule_day_0_time_1:
      '-Estimated time: 2 days 1 night (sleeping in a tent in the forest)',
    tour_8_schedule_day_0_title_1:
      '- Estimated schedule: Departure from Garden Center - Ta Lai Ranger Station, Ta Lai Field, Mixed bamboo and bamboo forest, Bang Lang forest, Suoi stream, main meal and overnight in the forest - Garden Center. ',
    tour_8_notice:
      '- Guests who like to adventure, explore and experience nature (note that good health is required, able to go for long walks)\n- The suitable group to join this tour is less than 15 guests. The number and composition of the delegation will be coordinated by the Center for Environmental Interpretation and Services of the Park. This is to ensure tourism exploitation within the environmental capacity, minimizing impacts on the ecosystem of Cat Tien National Park\n- The tour group must be accompanied by a guide.',

    // tour 9

    tour_9_title:
      'Bird observation route at main roads, trails and water fountains',
    tour_9_address: 'Cat Tien National Park',
    tour_9_synopsys_1:
      'Bau Sau with picturesque natural scenery, fresh and quiet air, along with other nearby water bodies is a warm treat for tourists, especially photography tourists. . The wetland system creates an excellent habitat not only for endemic freshwater crocodiles, but also a home to many species of freshwater birds and fish, and a refuge for other animals.',
    tour_9_synopsys_2:
      '\n- Route length: 20 km\n- Modes of travel on the route: Cars, bicycles are preferred and walking through the forest (Early or late afternoon)\n- Observing Birds on the roads main roads, trails and Bau Nuoc in the Garden… take photos and experience the nature of the rainforest.\nCreate job opportunities for the community from accommodation, eating, buying local products, guides ; contribute to the preservation of indigenous culture from watching traditional cultural shows; minimize the encroachment on forest environment by livelihood; diversify tourism products, increase income for the Garden; propaganda to protect the forest environment.',
    tour_9_schedule_day_0_time_1: '- Estimated time: 3 - 6 hours',
    tour_9_schedule_day_0_title_1:
      '- Estimated schedule: Departure from Garden Center - Bau Rau morning glory Nui Tuong, via Bau Oc, E7, Bau Chim, Bau Beo - Garden Center',
    tour_9_notice:
      '- Guests who like adventure, discovery and especially love birds (note that they need to be in good health, able to walk long distances)\n- The suitable group to join this tour is less than 10 guests. The number and composition of the delegation will be coordinated by the Center for Environmental Interpretation and Services of the Park. This is to ensure tourism exploitation within the environmental capacity, minimizing the impact on the ecosystem of Cat Tien National Park\n- Tourists participating in the tour must be accompanied by a guide. \n- Bird watchers must keep quiet, dress close to the natural environment (contact the Visitor Center of Cat Tien National Park for more information on what to prepare)',

    // tour 10

    tour_10_title:
      'Visiting route through the forest to Thac Troi through the big goose tree',
    tour_10_address: 'Cat Tien National Park',
    tour_10_synopsys_1:
      'The tropical rainforest route features a population of ancient mausoleums, precious trees tens of hundreds of years old, rich in forest orchids and spectacular rapids. This is an extremely suitable sightseeing route for tourists who love to take pictures and experience the nature of the rainforest: in the rainy season, see flowers with purple mausoleums, white mausoleums, pink peach blossoms; In the dry season, see the yellow - orange - red color of the forest changing leaves.',
    tour_10_synopsys_2:
      '\n- Route length: 10 km\n- Car and walking through the forest\nVisit the forest habitats, ancient Bang Lang trees, forest orchids, Waterfalls… see deer, primates… take photos and experience tropical forest nature: in the rainy season, you can see Bang Lang and Peach flowers; In the dry season, watching the forest change leaves and strange trees, Wire Bam.\nCreate job opportunities for the community from staying, eating, buying local products, guides; contribute to the preservation of indigenous culture from watching traditional cultural shows; minimize the encroachment on forest environment by livelihood; diversify tourism products, increase income for the Garden; propaganda for the protection of the forest environment. ',
    tour_10_schedule_day_0_time_1:
      '- Estimated time: 6-8 hours going back in a day',
    tour_10_schedule_day_0_title_1:
      '- Estimated schedule: Departure from Garden Center - Ecological route starting point, walk through forests, streams, swamps, Big Go Tree, Troi Waterfall - Garden Center.',
    tour_10_notice:
      '"- Guests who like to adventure, explore and experience nature (note that they need to be in good health, able to walk long distances)\n- The suitable group to join this tour is less than 10 guests. The composition of the delegation will be coordinated by the Center for Environmental Interpretation and Services of the Park, this is to ensure tourism exploitation within the environmental capacity, minimizing impacts on the ecosystem. of Cat Tien National Park\n- The group must have a guide to accompany itng. " ',

    // tour 11

    tour_11_title:
      'Sightseeing route on the river, from Da Lac to the archaeological site of Cat Tien',
    tour_11_address: 'Cat Tien National Park',
    tour_11_synopsys_1:
      'Cat Tien Sanctuary is a special destination when visitors can reach it by both land and water. Hardly any tourist can resist a trip by boat or canoe upstream of the Dong Nai River to see the river landscape and waterfowl species, on the way to find the archaeological site containing the evidence for a local culture. The region was originally Hindu-influenced, dating back to as early as a few centuries AD.',
    tour_11_synopsys_2:
      '\n- Route length: 6 km\n- Mode of travel on the route: Car or bicycle (15km) and boat (6 km)\n- Observation of forest habitat, agricultural farming model, take pictures of water birds, primates… Dong Nai river landscape.\n Create job opportunities for the community from accommodation, eating, buying local products, guides; contribute to the preservation of indigenous culture from watching traditional cultural shows; minimize the encroachment on forest environment by livelihood; diversify tourism products, increase income for the Garden; propaganda to protect the forest environment.',
    tour_11_schedule_day_0_time_1:
      '- Estimated time: 8 hours going back in a day',
    tour_11_schedule_day_0_title_1:
      '- Estimated schedule: Departure from Garden Center - Ben Cu - Troi Waterfall - Da Co ranger station - Da Mi ranger station - Da Lak ranger station (using boat}, habitat of Old Forest, Monkey Falls ( Lam Dong) - A1 Yoni Linga hill - Cat Tien archaeological site - Garden Center.',
    tour_11_notice:
      '\n- Guests who love to adventure, explore and experience nature\nYou absolutely must not litter into the river and make noise that affects wildlife.\n- The right group to join this tour is below 20 guests. The number and composition of the delegation will be coordinated by the Center for Environmental Interpretation and Services of the Park. This is to ensure tourism exploitation within the environmental capacity, minimizing impacts on the ecosystem of Cat Tien National Park\n- The tour group must be accompanied by a guide.',

    // tour 12

    tour_12_title: 'Bang Lang Forest Route to Da Co',
    tour_12_address: 'Cat Tien National Park',
    tour_12_synopsys_1:
      'The road through the forest connecting the center of Cat Tien National Park to Da Co seems to be specially created by nature for visitors who love trekking or cycling through the forest. Da Co not only has beautiful mausoleum forest in both the first rainy season and the leaf change season, but also the land of endemic primates in Cat Tien such as yellow-cheeked gibbon, black-shanked douc langur... Watching the sunbeams through the evergreen forest floors and blue foliage brings a sense of peace and tranquility, is one of the unforgettable experiences.',
    tour_12_synopsys_2:
      '\n- Route length: 10 km\n- Modes of travel on the route: Car, bicycle and walking\nDong Nai river landscape, waterfalls, gibbons, birds ... take photos and experience nature tropical forests.\nCreate job opportunities for the community from accommodation, meals, buying local products, guides; contribute to the preservation of indigenous culture from watching traditional cultural shows; minimize the encroachment on forest environment by livelihood; diversify tourism products, increase income for the Garden; propaganda for the protection of the forest environment. ',
    tour_12_schedule_day_0_time_1:
      ' - Estimated time: 2-4 hours (combined with car }, bicycle 4-5 hours to go back in the day ',
    tour_12_schedule_day_0_title_1:
      '- Estimated schedule: Departure from Garden Center, Ancient Tung tree, Ben Cu Waterfall, Botanical Garden, Troi Waterfall - Bang Lang Forest - Da Co Ranger Station - Garden Center.',
    tour_12_notice:
      '\n- Guests who love to adventure, explore and experience nature (note that good health is required, able to go for long walks)\n- The suitable group to join this tour is less than 20 guests. The number and composition of the delegation will be coordinated by the Center for Environmental Interpretation and Services of the Park. This is to ensure tourism exploitation within the environmental capacity, minimizing impacts on the ecosystem of Cat Tien National Park\n- The tour group must be accompanied by a guide.',

    // tour 13

    tour_13_title: 'Da Ban - Bau Sau Trail',
    tour_13_address: 'Cat Tien National Park',
    tour_13_synopsys_1:
      'Trekking has never been so interesting with the forest trail through Da Ban stream connecting to Bau Sau. Especially in the rainy season, the gurgling stream will soothe the wandering step during the moments of stopping to rest in the middle of the journey. The evergreen forest canopy with countless precious trees hundreds of years old and the traces left by wild animals is a testament to the biodiversity and precious value of Cat Tien primeval forest.',

    tour_13_synopsys_2:
      '\n- Route length: 10 km\n- Mode of travel on the route: Car (3 km) and walking (7 km)\nExplore the forest ecosystem, watch wild animals: Primates, Herbivores, Birds… take pictures and experience the nature of the rainforest.\nCreate job opportunities for the community from staying, eating, buying local products, guides; contribute to the preservation of indigenous culture from watching traditional cultural shows; minimize the encroachment on forest environment by livelihood; diversify tourism products, increase income for the Garden; propaganda to protect the forest environment.',
    tour_13_schedule_day_0_time_1: '- Estimated time: 2 days 1 night',
    tour_13_schedule_day_0_title_1:
      '- Estimated schedule: Departure Garden Center - Suoi Da Ban - walk through the forest 10 km: old forest habitat, Red gooseberry, Bam Bam vines, Dry stream - Bau Sau - Garden center.',
    tour_13_notice:
      '\n- Guests who love to adventure, explore and experience nature (note that good health is required, able to go for long walks)\n- The suitable group to join this tour is less than 10 guests. The number and composition of the delegation will be coordinated by the Center for Environmental Interpretation and Services of the Park. This is to ensure tourism exploitation within the environmental capacity, minimizing impacts on the ecosystem of Cat Tien National Park\n- The tour group must be accompanied by a guide.',

    // tour 14

    tour_14_title: 'Tà Lài Route to Bat Cave, Green Hill, and White Rock Hill',
    tour_14_address: 'Cat Tien National Park',
    tour_14_synopsys_1:
      'Mountain hills and caves always have a special attraction. After a break to rest, admire, and learn about the culture of the ethnic minority people in Tà Lài, the journey to explore the mysterious bat cave awaits tourists. The journey ends with a visit to Green Hill and White Rock Hill - a former hot spot for gold prospectors in the past.',
    tour_14_synopsys_2:
      "\n- Route length: 25 km\n- Mode of transportation on the route: 10 km by car, 15 km hiking through the jungle.\nVisiting the landscape of ethnic villages, rice fields, experiencing the cultures of the Mạ and S’Tiêng ethnic groups. Admiring the forest's fauna such as deer, pigs, and birds, taking photos, and experiencing the tropical forest nature.\nCreating job opportunities for the community through accommodation, food, purchasing local products, tour guides; contributing to the conservation of indigenous cultures by watching traditional cultural performances; reducing forest environmental damage from livelihoods; diversifying tourism products, increasing income for the park; propagating forest environmental protection.",
    tour_14_schedule_day_0_time_1:
      '- Estimated time: 6-8 hours for round trip or overnight stay in the forest',
    tour_14_schedule_day_0_title_1:
      '- Planned itinerary: Departure from the Park Center, Bear Rescue Center, Kơ nia Guesthouse, Tà Lài ethnic village, Tà Lài Long House - Bat Cave - Green Hill - Park Center.',
    tour_14_notice:
      "\n- Suitable for adventurous tourists who want to explore and experience nature (note that good health and the ability to walk long distances are required)\n- The group size for this tour is under 10 guests. The number and composition of the group will be coordinated by the Park's environmental interpretation and service center. This is to ensure tourism development within the environmental carrying capacity, minimizing the impacts on the ecosystem of Cat Tien National Park\n- The group must have a tour guide. The group can also hire an additional person to assist with luggage due to the long route.",
    // tour 15
    tour_15_title:
      'Experience Kayaking on Dong Nai River Tour, from Da Co to VQGCT Ferry Terminal',
    tour_15_address: 'Cat Tien National Park',
    tour_15_synopsys_1:
      'The kayaking journey from Da Co to VQG Cat Tien Ferry Terminal is an exciting outdoor sports activity for active and adventurous travelers. This river trip requires paddling through a 7km stretch of the river with two rocky rapids and many obstacles. This is a specially designed discovery and adventure tour for tourists who love exploring and adventuring. Conquering a section of the Dong Nai River that borders the National Park seems like an attractive and fascinating challenge and a highlight of your trip.',
    tour_15_synopsys_2:
      '\n- Route length: 10 km\n- Mode of transportation on the route: Car or bike and Kayak or inflatable rubber boat\n- Experience kayaking through waterfalls on the Dong Nai River, admiring the natural scenery and wildlife on the river banks.\nCreate job opportunities for the community through accommodation, food and beverage, local product purchases, and tour guides. Contribute to the preservation of indigenous culture by watching traditional cultural performances, reducing forest livelihood exploitation, diversifying tourism products, increasing income for the Park, and promoting environmental protection.',
    tour_15_schedule_day_0_time_1:
      '- Expected time: 6 - 8 hours round trip in a day ',
    tour_15_schedule_day_0_title_1:
      '- Expected itinerary: Depart from the Park Center - Da Co Forest Station - Troi Waterfall, Dung Waterfall, Tien Island - to VQG Ferry Terminal.  ',
    tour_15_notice:
      '\n- Suitable for adventurous and nature-exploring travelers (team building groups)\n- Absolutely do not litter into the river\n- The group size for this tour is below 10 guests. The number and composition of the group will be coordinated by the Environmental Interpretation Center and Park services to ensure tourism exploitation within the carrying capacity of the environment, minimizing impacts on the Cat Tien National Park ecosystem.\n- The group must be accompanied by a tour guide. If guests overnight in the forest, the group can hire an additional person to support luggage.',

    // tour 16
    tour_16_title: 'Da Co - Da Lac - Dac Lua Route',
    tour_16_address: 'Cat Tien National Park',
    tour_16_synopsys_1:
      'The Da Co - Da Lac - Dac Lua route is definitely attractive to tourists who are professional or amateur photographers, and anyone who loves the fresh and colorful beauty of galliform birds such as pheasants, red junglefowls, junglefowls, and red junglefowl. This forest route is relatively flat, easy to walk, and suitable for all forms of travel by car, on foot, and by bike. The forest scene is also enriched by the Dac Lua stream with its abundant water supply in the rainy season and gentle flow in the dry season. Farther in the direction of Dac Lua are the characteristic rice fields and orchards of the Southeast region, awaiting visitors.',
    tour_16_synopsys_2:
      '\n- Route length: 18 km\n- Mode of transportation: Car and hiking through the forest\n- Explore the forest ecosystem, observe ungulates, primates, herbivores, birds... take photos and experience the tropical forest nature. \nCreate employment opportunities for the community from accommodation, dining, purchasing local products, guides; diversify tourism products, increase income for the Park; promote forest environmental protection.',
    tour_16_schedule_day_0_time_1:
      '- Estimated time: 8 hours for a round trip by car, 2 days 1 night for hiking',
    tour_16_schedule_day_0_title_1:
      'Depart from the Park Center - Da Co Forest Ranger Station - Da Mi Forest Ranger Station - Da Lac -\n- Proposed itinerary: Dac Lua Forest Ranger Station. Go along the Dong Nai River, through grasslands, bamboo forests, plantations, old-growth forests, and banana groves, to observe water birds and return to the Park Center.',
    tour_16_notice:
      "- Suitable for adventurous tourists who want to explore and experience nature (note that good health and long-distance hiking ability are required)\n- The group size for this tour is less than 10 people. The number and composition of the group will be coordinated by the Park's environmental interpretation and service center. This is to ensure that tourism is developed within the environmental carrying capacity and minimizes the impact on the ecosystem of Cat Tien National Park.\n- The tour group must be accompanied by a guide.",
    // tour 17
    tour_17_title: 'Talai - Red Soil Hill - Sa Mach - Rang Stream Tour',
    tour_17_address: 'Cat Tien National Park',
    tour_17_synopsys_1:
      'A mix of deciduous forest and bamboo forest creates a green gateway to welcome visitors to the Red Soil Hill. Continuing on the journey, travelers will complete a 40km forest trail to reach Sa Mach, and then move on to the most exciting highlight of the trip: the beautiful and clear Rang Stream that flows through the heart of Cat Tien forest. Rang Waterfall and Rang Dam are definitely a must-see stop on this tour. Along the way, visitors can observe some primates and birds in their favorite forest habitat.',
    tour_17_synopsys_2:
      '- Tour length: 27 km\n- Means of transportation: Car, priority for bicycles\nVisit the ethnic village, rice fields, and experience the culture of the Mạ and S’Tiêng ethnic groups. Particularly, it is possible to observe and take pictures of large animals such as elephants, buffalo, primates, birds, and experience the tropical forest. This route passes through many hills, mountains, and streams...\nCreating job opportunities for the community from accommodation, food, local product purchase, tour guide; contributing to the conservation of indigenous culture by watching traditional cultural performances; reducing forest environmental violations due to livelihoods; diversifying tourism products, increasing income for the Park; and promoting forest environmental protection.',
    tour_17_schedule_day_0_time_1:
      '- Expected time: by car 6-8 hours (round trip). Trekking - 2 days 1 night',
    tour_17_schedule_day_0_title_1:
      '- Planned itinerary: Depart by car from the Park Center - Tà Lài Forest Station through White Stone Hill - Đất đỏ Forest Station - Sa Mach - Cycle and trek through the forest from Sa Mach... Suối Ràng Forest Station - Park Center.',
    tour_17_notice:
      "- Suitable for adventurous and nature-loving travelers (note that good health and ability to walk long distances are required).\n- The group size for this tour is under 10 people. The number and composition of the group will be coordinated by the Park's Environmental Interpretation Center and services to ensure that tourism is conducted within the environmental carrying capacity and minimizes impacts on the ecosystem of Cat Tien National Park.\nThe group must be accompanied by a guide and forest rangers.",
    type_tour_1: 'Nature discovery tour',
    type_tour_2: 'Nature discovery tour',
    latest_news: 'Latest News',
    see_more: 'See More',
    event: 'Event',
    other_news: 'Other News',
    car: 'Car',
    camp: 'Camp',
    visit: ' Visit',
    cycling: 'Cycling',
    ecological: 'Ecological',
    history: 'History',
    community: 'Community',
    education: 'Education',
    hiking: 'Hiking',
    swim: 'Swim',
    rowing: 'Rowing',
    wading_stream: 'Wading Stream',
    tour_hot: 'Tour hot',
    discover_dong_nai: 'Discover Dong Nai',

    detail: 'Detail',
    call_now: 'Call Now',

    animal_photos: 'Animal photos',
    plant_photo: 'Plant photo',
    culture_history: 'Culture - history',

    overview: 'Overview',
    schedule: 'Schedule',
    map: 'Map',
    note: 'Note',
    photo_library: 'Photo Library',
    weather: 'Weather',
    skin: 'Skin',
    regulations: 'Regulations',
    sale_off: 'Sale off',
    book_now: 'Book now',
    call: 'Call',
    day: 'Day',
  },
};
export const getListTour = (): Tour[] => {
  const LANG = localStorage.getItem('lang') || 'en';
  const dataTourVi = tours[LANG];
  const tour: Tour = {
    id: '101',
    // tel: '+84 2513961140',
    facebook: 'https://www.facebook.com/groups/khubaotondongnai/',
    contact1: 'Mr. Nam: 0966888433',
    contact2: 'Mr. Toàn: 0974745020',
    contact3: 'Ms. Hương: 0989289220',
    email:
      'Email của Trung tâm sinh thái văn hóa lịch sử Chiến khu Đ : trungtamckd@gmail.com',
    address: `${dataTourVi.tour_1_address}`,
    imageThumb: `/images/tour/tour_1/image_1.jpg`,
    name: `${dataTourVi.tour_1_title}`,
    description1: `${dataTourVi.tour_1_synopsys_1}`,
    description2: `${dataTourVi.tour_1_synopsys_2}`,
    time: dataTourVi.a2_day_1_night,
    typeTour: dataTourVi.type_tour_1,
    longitude: '107.43071183440613',
    latitude: '11.42337889637757',
    price: {
      adults: 300000,
      children: 150000,
      peopleWithDisabilities: 100000,
      elderly: 150000,
      student: 150000,
      pupil: 100000,
    },
    note: {
      weather: [],
      skin: [dataTourVi.tour_1_notice],
      regulations: [],
    },
    listImage: [
      '/images/tour/tour_1/image_2.jpg',
      '/images/tour/tour_1/image_3.jpg',
      '/images/tour/tour_1/image_4.jpg',
    ],

    //  đạp xe, cắm trại, chèo thuyền, bơi, lửa trại, lội suối, hiking
    listAction: [
      {
        action: dataTourVi.ecological,
        image: ic_action_ecological,
      },
      {
        action: dataTourVi.history,
        image: ic_action_history,
      },
      {
        action: dataTourVi.community,
        image: ic_action_community,
      },
      {
        action: dataTourVi.visit,
        image: ic_action_visit,
      },
      {
        action: dataTourVi.education,
        image: ic_action_education,
      },
      {
        action: dataTourVi.cycling,
        image: ic_action_cycling,
      },
      {
        action: dataTourVi.camp,
        image: ic_action_camp,
      },
      {
        action: dataTourVi.rowing,
        image: ic_action_rowing,
      },
      {
        action: dataTourVi.swim,
        image: ic_action_swim,
      },
      {
        action: dataTourVi.wading_stream,
        image: ic_action_wading_stream,
      },
      {
        action: dataTourVi.hiking,
        image: ic_action_hiking,
      },
    ],
    listSchedule: [
      {
        day: '1',
        schedule: [
          {
            time: dataTourVi.tour_1_schedule_day_1_time_1,
            title: dataTourVi.tour_1_schedule_day_1_title_1,
            description: '',
            image: [],
          },
          {
            time: dataTourVi.tour_1_schedule_day_1_time_2,
            title: dataTourVi.tour_1_schedule_day_1_title_2,
            description: '',
            image: [],
          },
          {
            time: dataTourVi.tour_1_schedule_day_1_time_3,
            title: dataTourVi.tour_1_schedule_day_1_title_3,
            description: dataTourVi.tour_1_schedule_day_1_des_3,
            image: [],
          },
          {
            time: dataTourVi.tour_1_schedule_day_1_time_4,
            title: dataTourVi.tour_1_schedule_day_1_title_4,
            description: dataTourVi.tour_1_schedule_day_1_des_4,
            image: [],
          },
          {
            time: dataTourVi.tour_1_schedule_day_1_time_5,
            title: dataTourVi.tour_1_schedule_day_1_title_5,
            description: '',
            image: [],
          },

          {
            time: dataTourVi.tour_1_schedule_day_1_time_6,
            title: dataTourVi.tour_1_schedule_day_1_title_6,
            description: dataTourVi.tour_1_schedule_day_1_des_6,
            image: [],
          },
          {
            time: dataTourVi.tour_1_schedule_day_1_time_7,
            title: dataTourVi.tour_1_schedule_day_1_title_7,
            description: dataTourVi.tour_1_schedule_day_1_des_7,
            image: [],
          },
        ],
      },
    ],
  };

  const tour2 = {
    id: '102',
    address: dataTourVi.tour_2_address,
    // tel: '+84 2513961140',
    facebook: 'https://www.facebook.com/groups/khubaotondongnai/',
    contact1: 'Mr. Nam: 0966888433',
    contact2: 'Mr. Toàn: 0974745020',
    contact3: 'Ms. Hương: 0989289220',
    email:
      'Email của Trung tâm sinh thái văn hóa lịch sử Chiến khu Đ: trungtamckd@gmail.com',
    imageThumb: '/images/tour/tour_2/image_1.jpg',
    // "https://doc-0c-8o-docs.googleusercontent.com/docs/securesc/dsrp2ij34p9u7k2r6vpu9brmvgf8jdh8/bplm1ddg4fv8ns7l3rbq01cutre1pi8f/1665591825000/12515604651516018806/12515604651516018806/1q_9eawOMrKv0wBVc8fh31aWHZDg-V-3E?ax=ALW9-sDUmoeS1Q6jCppLz-kECa8SwCKffz6sIvl-1VhPh1xUj854C0zLYTiknv2xCiKPKlrjOLbt5vzRGHgy3_2TX4GtYBfHHvB-XsQq1SDNa6O9P9T7jhJhdhZMW7AViEx0suUT-iamqYtqD_Yb7bCfenyHdnTGosdSbK9SqgxUuWD0aCubuf7lITeNfSoYjI3pTHwWnsrke75pHIyeysUxS_9m2yYdxpn04V6RYCmpePyOocEoN9IbSUzKZpict0jLk9Mr1Ys41GKD9jkZoU5j3R5SMP8Au1afqSqM12CzeT3BAJlQD9gj0jzwPeKZovBhDqaE-4L3ADHv-tsF1K2l-gCwpCa6xmkyNaj3veTELZoToJAGJP8praQxh1Kemcse1aM2sNWLE-Qb0nYiLRNCEcWIvceSMZKqj140r0IudZ0LjZ_yAWk8in8JQzXmdQBvONtP7OpahI_Sq-VVqd3bmMAuN54aZ7Abfq7tdHFQ_CcFEu5jE9vfLxk9Gg_lcfv5vVnf5yyuO2--t17vf6adi8nKVNQ2MG9GyPHksWQv6Tn1-eBbYu-nsog4QvnKZgjv5K-eSNPp31RHSKh1tVMXzNX6t_7DJsHCYo3FCy4Wa_jc52t28P9nlwLqtPH97kOgpJnc5pf0hqI9hjHIw6959HbSeRsF0cyW3nGpM4eWfnHiS3EEZCqLvswGEU9LTGvtro2G3siFMNsE0zassHsf9y0yFStQa9UBQGMMOxLXLZ-nt1wY4O5LI_cSqUwmxglgdaArNjszsFTWjZZEBASFo9NBkb8iAdU5hIiy1rs7KUGf9V2koKLyn-RtTJrcvDbMRys_AXe1EqSRyOyt22dN6UfcZpTAnJ9bIY4dtN26skR9o-V8WGz6tpJ3p2vMkB4s-_COGg4FPQoZlLnbaFk4gqKkLPpGLw07vQ&uuid=88a7f57f-31fd-4b09-8402-ada820573a17&authuser=0&nonce=2dl6r9eqhjp6q&user=12515604651516018806&hash=rd221j58v41qe6ccu6p7utkfd0dsdg5g",
    name: dataTourVi.tour_2_title,
    description1: dataTourVi.tour_2_synopsys_1,
    description2: dataTourVi.tour_2_synopsys_2,
    time: dataTourVi.a2_day_1_night,
    typeTour: dataTourVi.type_tour_1,
    longitude: '107.43071183440613',
    latitude: '11.42337889637757',
    price: {
      adults: 300000,
      children: 150000,
      peopleWithDisabilities: 100000,
      elderly: 150000,
      student: 150000,
      pupil: 100000,
    },
    note: {
      weather: [],
      skin: [],
      regulations: [dataTourVi.tour_2_notice],
    },
    listImage: [
      '/images/tour/tour_2/image_2.jpg',
      '/images/tour/tour_3/image_3.jpg',
      '/images/tour/tour_4/image_4.jpg',
      '/images/tour/tour_5/image_5.jpg',
    ],

    //  đạp xe, du lịch lịch sử, du lịch sinh thái, cắm trại.
    listAction: [
      {
        action: dataTourVi.cycling,
        image: ic_action_cycling,
      },
      {
        action: dataTourVi.history,
        image: ic_action_history,
      },
      {
        action: dataTourVi.ecological,
        image: ic_action_ecological,
      },
      {
        action: dataTourVi.camp,
        image: ic_action_camp,
      },
    ],
    listSchedule: [
      {
        day: '0',
        schedule: [
          {
            time: dataTourVi.tour_2_schedule_day_1_time_1,
            title: dataTourVi.tour_2_schedule_day_1_title_1,
            description: '',
            image: [],
          },
          {
            time: dataTourVi.tour_2_schedule_day_1_time_2,
            title: dataTourVi.tour_2_schedule_day_1_title_2,
            description: dataTourVi.tour_2_schedule_day_1_des_2,
            image: [],
          },
          {
            time: dataTourVi.tour_2_schedule_day_1_time_3,
            title: dataTourVi.tour_2_schedule_day_1_title_3,
            description: '',
            image: [],
          },

          {
            time: dataTourVi.tour_2_schedule_day_1_time_4,
            title: dataTourVi.tour_2_schedule_day_1_title_4,
            description: dataTourVi.tour_2_schedule_day_1_des_4,
            image: [],
          },

          // "15giờ 30: Hướng dẫn kỹ năng dựng lều trại, và thực hiện dựng lều, tham quan tìm hiểu các loài cây gỗ lớn và loài dơi tại khu vực Di tích.
          // 17 giờ 30: Ăn chiều.
          // 19 giờ 30: Chương trình giao lưu, đốt lửa trại.
          // 21 giờ 00: Nghỉ ngơi tại Di tích."

          {
            time: dataTourVi.tour_2_schedule_day_1_time_5,
            title: dataTourVi.tour_2_schedule_day_1_title_5,
            description: '',
            image: [],
          },
          {
            time: dataTourVi.tour_2_schedule_day_1_time_6,
            title: dataTourVi.tour_2_schedule_day_1_title_6,
            description: '',
            image: [],
          },
          {
            time: dataTourVi.tour_2_schedule_day_1_time_7,
            title: dataTourVi.tour_2_schedule_day_1_title_7,
            description: '',
            image: [],
          },
        ],
      },
    ],
  };

  const tour3 = {
    id: '103',
    facebook: 'https://www.facebook.com/cattienvietnam',
    contact1: 'Email: cattienvietnam@gmail.com',
    contact2: 'Zalo: 0856 669 228 - Vườn quốc gia Cát Tiên',
    address: dataTourVi.tour_3_address,
    imageThumb: '/images/tour/tour_3/image_1.jpg',
    // "https://doc-0c-8o-docs.googleusercontent.com/docs/securesc/dsrp2ij34p9u7k2r6vpu9brmvgf8jdh8/bplm1ddg4fv8ns7l3rbq01cutre1pi8f/1665591825000/12515604651516018806/12515604651516018806/1q_9eawOMrKv0wBVc8fh31aWHZDg-V-3E?ax=ALW9-sDUmoeS1Q6jCppLz-kECa8SwCKffz6sIvl-1VhPh1xUj854C0zLYTiknv2xCiKPKlrjOLbt5vzRGHgy3_2TX4GtYBfHHvB-XsQq1SDNa6O9P9T7jhJhdhZMW7AViEx0suUT-iamqYtqD_Yb7bCfenyHdnTGosdSbK9SqgxUuWD0aCubuf7lITeNfSoYjI3pTHwWnsrke75pHIyeysUxS_9m2yYdxpn04V6RYCmpePyOocEoN9IbSUzKZpict0jLk9Mr1Ys41GKD9jkZoU5j3R5SMP8Au1afqSqM12CzeT3BAJlQD9gj0jzwPeKZovBhDqaE-4L3ADHv-tsF1K2l-gCwpCa6xmkyNaj3veTELZoToJAGJP8praQxh1Kemcse1aM2sNWLE-Qb0nYiLRNCEcWIvceSMZKqj140r0IudZ0LjZ_yAWk8in8JQzXmdQBvONtP7OpahI_Sq-VVqd3bmMAuN54aZ7Abfq7tdHFQ_CcFEu5jE9vfLxk9Gg_lcfv5vVnf5yyuO2--t17vf6adi8nKVNQ2MG9GyPHksWQv6Tn1-eBbYu-nsog4QvnKZgjv5K-eSNPp31RHSKh1tVMXzNX6t_7DJsHCYo3FCy4Wa_jc52t28P9nlwLqtPH97kOgpJnc5pf0hqI9hjHIw6959HbSeRsF0cyW3nGpM4eWfnHiS3EEZCqLvswGEU9LTGvtro2G3siFMNsE0zassHsf9y0yFStQa9UBQGMMOxLXLZ-nt1wY4O5LI_cSqUwmxglgdaArNjszsFTWjZZEBASFo9NBkb8iAdU5hIiy1rs7KUGf9V2koKLyn-RtTJrcvDbMRys_AXe1EqSRyOyt22dN6UfcZpTAnJ9bIY4dtN26skR9o-V8WGz6tpJ3p2vMkB4s-_COGg4FPQoZlLnbaFk4gqKkLPpGLw07vQ&uuid=88a7f57f-31fd-4b09-8402-ada820573a17&authuser=0&nonce=2dl6r9eqhjp6q&user=12515604651516018806&hash=rd221j58v41qe6ccu6p7utkfd0dsdg5g",
    name: dataTourVi.tour_3_title,
    description1: dataTourVi.tour_3_synopsys_1,
    description2: dataTourVi.tour_3_synopsys_2,
    time: dataTourVi.a1_day,
    typeTour: dataTourVi.type_tour_1,
    longitude: ' 107.43095555687225',
    latitude: '11.423441301841912',
    price: {
      adults: 300000,
      children: 150000,
      peopleWithDisabilities: 100000,
      elderly: 150000,
      student: 150000,
      pupil: 100000,
    },
    note: {
      weather: [],
      skin: [],
      regulations: [dataTourVi.tour_3_notice],
    },
    listImage: [
      '/images/tour/tour_3/image_2.jpg',
      '/images/tour/tour_3/image_3.jpg',
      '/images/tour/tour_3/image_4.jpg',
      '/images/tour/tour_3/image_5.jpg',
      '/images/tour/tour_3/image_6.jpg',
      '/images/tour/tour_3/image_7.jpg',
      '/images/tour/tour_3/image_8.jpg',
      '/images/tour/tour_3/image_9.jpg',
      '/images/tour/tour_3/image_10.jpg',
      '/images/tour/tour_3/image_11.jpg',
      '/images/tour/tour_3/image_12.jpg',
    ],
    listAction: [
      // du lịch sinh thái, giáo dục, tham quan, ô tô, xe đạp, hiking

      {
        action: dataTourVi.ecological,
        image: ic_action_ecological,
      },

      {
        action: dataTourVi.education,
        image: ic_action_education,
      },

      {
        action: dataTourVi.cycling,
        image: ic_action_cycling,
      },

      {
        action: dataTourVi.visit,
        image: ic_action_visit,
      },

      {
        action: dataTourVi.hiking,
        image: ic_action_hiking,
      },

      {
        action: dataTourVi.car,
        image: ic_action_car,
      },
    ],
    listSchedule: [
      {
        day: '0',
        schedule: [
          {
            time: '',
            title: dataTourVi.tour_3_schedule_day_0_time_1,
            description: dataTourVi.tour_3_schedule_day_0_title_1,
            image: [
              '/images/tour/tour_3/intinerary_1.jpg',
              '/images/tour/tour_3/intinerary_2.jpg',
              '/images/tour/tour_3/intinerary_3.jpg',
            ],
          },
        ],
      },
    ],
    // tel: '0613.961683',
    // fax: '0613.961683',
  };

  const tour4 = {
    // tel: '0613.961683',
    // fax: '0613.961683',
    facebook: 'https://www.facebook.com/cattienvietnam',
    contact1: 'Email: cattienvietnam@gmail.com',
    contact2: 'Zalo: 0856 669 228 - Vườn quốc gia Cát Tiên',
    id: '104',
    address: dataTourVi.tour_4_address,
    imageThumb: '/images/tour/tour_4/avatar.jpg',
    // "https://doc-0c-8o-docs.googleusercontent.com/docs/securesc/dsrp2ij34p9u7k2r6vpu9brmvgf8jdh8/bplm1ddg4fv8ns7l3rbq01cutre1pi8f/1665591825000/12515604651516018806/12515604651516018806/1q_9eawOMrKv0wBVc8fh31aWHZDg-V-3E?ax=ALW9-sDUmoeS1Q6jCppLz-kECa8SwCKffz6sIvl-1VhPh1xUj854C0zLYTiknv2xCiKPKlrjOLbt5vzRGHgy3_2TX4GtYBfHHvB-XsQq1SDNa6O9P9T7jhJhdhZMW7AViEx0suUT-iamqYtqD_Yb7bCfenyHdnTGosdSbK9SqgxUuWD0aCubuf7lITeNfSoYjI3pTHwWnsrke75pHIyeysUxS_9m2yYdxpn04V6RYCmpePyOocEoN9IbSUzKZpict0jLk9Mr1Ys41GKD9jkZoU5j3R5SMP8Au1afqSqM12CzeT3BAJlQD9gj0jzwPeKZovBhDqaE-4L3ADHv-tsF1K2l-gCwpCa6xmkyNaj3veTELZoToJAGJP8praQxh1Kemcse1aM2sNWLE-Qb0nYiLRNCEcWIvceSMZKqj140r0IudZ0LjZ_yAWk8in8JQzXmdQBvONtP7OpahI_Sq-VVqd3bmMAuN54aZ7Abfq7tdHFQ_CcFEu5jE9vfLxk9Gg_lcfv5vVnf5yyuO2--t17vf6adi8nKVNQ2MG9GyPHksWQv6Tn1-eBbYu-nsog4QvnKZgjv5K-eSNPp31RHSKh1tVMXzNX6t_7DJsHCYo3FCy4Wa_jc52t28P9nlwLqtPH97kOgpJnc5pf0hqI9hjHIw6959HbSeRsF0cyW3nGpM4eWfnHiS3EEZCqLvswGEU9LTGvtro2G3siFMNsE0zassHsf9y0yFStQa9UBQGMMOxLXLZ-nt1wY4O5LI_cSqUwmxglgdaArNjszsFTWjZZEBASFo9NBkb8iAdU5hIiy1rs7KUGf9V2koKLyn-RtTJrcvDbMRys_AXe1EqSRyOyt22dN6UfcZpTAnJ9bIY4dtN26skR9o-V8WGz6tpJ3p2vMkB4s-_COGg4FPQoZlLnbaFk4gqKkLPpGLw07vQ&uuid=88a7f57f-31fd-4b09-8402-ada820573a17&authuser=0&nonce=2dl6r9eqhjp6q&user=12515604651516018806&hash=rd221j58v41qe6ccu6p7utkfd0dsdg5g",
    name: dataTourVi.tour_4_title,
    description1: dataTourVi.tour_4_synopsys_1,
    description2: dataTourVi.tour_4_title,
    time: dataTourVi.a1_day,
    typeTour: dataTourVi.type_tour_1,
    longitude: ' 107.43095555687225',
    latitude: '11.423441301841912',
    price: {
      adults: 300000,
      children: 150000,
      peopleWithDisabilities: 100000,
      elderly: 150000,
      student: 150000,
      pupil: 100000,
    },
    note: {
      weather: [],
      skin: [],
      regulations: [dataTourVi.tour_4_notice],
    },
    listImage: [
      '/images/tour/tour_4/image_1.jpg',
      '/images/tour/tour_4/image_2.jpg',
      '/images/tour/tour_4/image_3.jpg',
      '/images/tour/tour_4/image_4.jpg',
      '/images/tour/tour_4/image_5.jpg',
      '/images/tour/tour_4/image_6.jpg',
      '/images/tour/tour_4/image_7.jpg',
      '/images/tour/tour_4/image_8.jpg',
      '/images/tour/tour_4/image_9.jpg',
      '/images/tour/tour_4/image_10.jpg',
      '/images/tour/tour_4/image_11.jpg',
      '/images/tour/tour_4/image_12.jpg',
    ],
    listAction: [
      // du lịch sinh thái, giáo dục, tham quan, ô tô, xe đạp, hiking

      {
        action: dataTourVi.ecological,
        image: ic_action_ecological,
      },

      {
        action: dataTourVi.education,
        image: ic_action_education,
      },

      {
        action: dataTourVi.cycling,
        image: ic_action_cycling,
      },

      {
        action: dataTourVi.visit,
        image: ic_action_visit,
      },
      {
        action: dataTourVi.car,
        image: ic_action_car,
      },
      {
        action: dataTourVi.hiking,
        image: ic_action_hiking,
      },
    ],
    listSchedule: [
      {
        day: '1',
        schedule: [
          {
            time: '',
            title: dataTourVi.tour_4_schedule_day_0_time_1,
            description: dataTourVi.tour_4_schedule_day_0_title_1,
            image: [
              '/images/tour/tour_4/intinerary_1.jpg',
              '/images/tour/tour_4/intinerary_2.jpg',
              '/images/tour/tour_4/intinerary_3.jpg',
              '/images/tour/tour_4/intinerary_4.jpg',
            ],
          },
        ],
      },
    ],
  };

  const tour5 = {
    // tel: '0613.961683',
    // fax: '0613.961683',
    facebook: 'https://www.facebook.com/cattienvietnam',
    contact1: 'Email: cattienvietnam@gmail.com',
    contact2: 'Zalo: 0856 669 228 - Vườn quốc gia Cát Tiên',
    id: '105',
    address: dataTourVi.tour_5_address,
    imageThumb: '/images/tour/tour_5/avatar.jpg',
    // "https://doc-0c-8o-docs.googleusercontent.com/docs/securesc/dsrp2ij34p9u7k2r6vpu9brmvgf8jdh8/bplm1ddg4fv8ns7l3rbq01cutre1pi8f/1665591825000/12515604651516018806/12515604651516018806/1q_9eawOMrKv0wBVc8fh31aWHZDg-V-3E?ax=ALW9-sDUmoeS1Q6jCppLz-kECa8SwCKffz6sIvl-1VhPh1xUj854C0zLYTiknv2xCiKPKlrjOLbt5vzRGHgy3_2TX4GtYBfHHvB-XsQq1SDNa6O9P9T7jhJhdhZMW7AViEx0suUT-iamqYtqD_Yb7bCfenyHdnTGosdSbK9SqgxUuWD0aCubuf7lITeNfSoYjI3pTHwWnsrke75pHIyeysUxS_9m2yYdxpn04V6RYCmpePyOocEoN9IbSUzKZpict0jLk9Mr1Ys41GKD9jkZoU5j3R5SMP8Au1afqSqM12CzeT3BAJlQD9gj0jzwPeKZovBhDqaE-4L3ADHv-tsF1K2l-gCwpCa6xmkyNaj3veTELZoToJAGJP8praQxh1Kemcse1aM2sNWLE-Qb0nYiLRNCEcWIvceSMZKqj140r0IudZ0LjZ_yAWk8in8JQzXmdQBvONtP7OpahI_Sq-VVqd3bmMAuN54aZ7Abfq7tdHFQ_CcFEu5jE9vfLxk9Gg_lcfv5vVnf5yyuO2--t17vf6adi8nKVNQ2MG9GyPHksWQv6Tn1-eBbYu-nsog4QvnKZgjv5K-eSNPp31RHSKh1tVMXzNX6t_7DJsHCYo3FCy4Wa_jc52t28P9nlwLqtPH97kOgpJnc5pf0hqI9hjHIw6959HbSeRsF0cyW3nGpM4eWfnHiS3EEZCqLvswGEU9LTGvtro2G3siFMNsE0zassHsf9y0yFStQa9UBQGMMOxLXLZ-nt1wY4O5LI_cSqUwmxglgdaArNjszsFTWjZZEBASFo9NBkb8iAdU5hIiy1rs7KUGf9V2koKLyn-RtTJrcvDbMRys_AXe1EqSRyOyt22dN6UfcZpTAnJ9bIY4dtN26skR9o-V8WGz6tpJ3p2vMkB4s-_COGg4FPQoZlLnbaFk4gqKkLPpGLw07vQ&uuid=88a7f57f-31fd-4b09-8402-ada820573a17&authuser=0&nonce=2dl6r9eqhjp6q&user=12515604651516018806&hash=rd221j58v41qe6ccu6p7utkfd0dsdg5g",
    name: dataTourVi.tour_5_title,
    description1: dataTourVi.tour_5_synopsys_1,
    description2: dataTourVi.tour_5_synopsys_2,
    time: dataTourVi.a1_day,
    typeTour: dataTourVi.type_tour_1,
    longitude: ' 107.43095555687225',
    latitude: '11.423441301841912',
    price: {
      adults: 300000,
      children: 150000,
      peopleWithDisabilities: 100000,
      elderly: 150000,
      student: 150000,
      pupil: 100000,
    },
    note: {
      weather: [],
      skin: [],
      regulations: [dataTourVi.tour_5_notice],
    },
    listImage: [
      '/images/tour/tour_5/image_1.jpg',
      '/images/tour/tour_5/image_2.jpg',
      '/images/tour/tour_5/image_3.jpg',
      '/images/tour/tour_5/image_4.jpg',
      '/images/tour/tour_5/image_5.jpg',
      '/images/tour/tour_5/image_6.jpg',
      '/images/tour/tour_5/image_7.jpg',
      '/images/tour/tour_5/image_8.jpg',
      '/images/tour/tour_5/image_9.jpg',
      '/images/tour/tour_5/image_10.jpg',
      '/images/tour/tour_5/image_11.jpg',
      '/images/tour/tour_5/image_12.jpg',
    ],
    listAction: [
      // du lịch sinh thái, giáo dục, tham quan, ô tô, xe đạp, hiking

      {
        action: dataTourVi.ecological,
        image: ic_action_ecological,
      },

      {
        action: dataTourVi.education,
        image: ic_action_education,
      },

      {
        action: dataTourVi.visit,
        image: ic_action_visit,
      },
      {
        action: dataTourVi.car,
        image: ic_action_car,
      },
    ],
    listSchedule: [
      {
        day: '0',
        schedule: [
          {
            time: '',
            title: dataTourVi.tour_5_schedule_day_0_time_1,
            description: dataTourVi.tour_5_schedule_day_0_title_1,
            image: [
              '/images/tour/tour_5/intinerary_1.jpg',
              '/images/tour/tour_5/intinerary_2.jpg',
              '/images/tour/tour_5/intinerary_3.jpg',
              '/images/tour/tour_5/intinerary_4.jpg',
              '/images/tour/tour_5/intinerary_5.jpg',
            ],
          },
        ],
      },
    ],
  };

  const tour6 = {
    // tel: '0613.961683',
    // fax: '0613.961683',
    facebook: 'https://www.facebook.com/cattienvietnam',
    contact1: 'Email: cattienvietnam@gmail.com',
    contact2: 'Zalo: 0856 669 228 - Vườn quốc gia Cát Tiên',
    id: '106',
    address: dataTourVi.tour_6_address,
    imageThumb: '/images/tour/tour_6/avatar.jpg',
    // "https://doc-0c-8o-docs.googleusercontent.com/docs/securesc/dsrp2ij34p9u7k2r6vpu9brmvgf8jdh8/bplm1ddg4fv8ns7l3rbq01cutre1pi8f/1665591825000/12515604651516018806/12515604651516018806/1q_9eawOMrKv0wBVc8fh31aWHZDg-V-3E?ax=ALW9-sDUmoeS1Q6jCppLz-kECa8SwCKffz6sIvl-1VhPh1xUj854C0zLYTiknv2xCiKPKlrjOLbt5vzRGHgy3_2TX4GtYBfHHvB-XsQq1SDNa6O9P9T7jhJhdhZMW7AViEx0suUT-iamqYtqD_Yb7bCfenyHdnTGosdSbK9SqgxUuWD0aCubuf7lITeNfSoYjI3pTHwWnsrke75pHIyeysUxS_9m2yYdxpn04V6RYCmpePyOocEoN9IbSUzKZpict0jLk9Mr1Ys41GKD9jkZoU5j3R5SMP8Au1afqSqM12CzeT3BAJlQD9gj0jzwPeKZovBhDqaE-4L3ADHv-tsF1K2l-gCwpCa6xmkyNaj3veTELZoToJAGJP8praQxh1Kemcse1aM2sNWLE-Qb0nYiLRNCEcWIvceSMZKqj140r0IudZ0LjZ_yAWk8in8JQzXmdQBvONtP7OpahI_Sq-VVqd3bmMAuN54aZ7Abfq7tdHFQ_CcFEu5jE9vfLxk9Gg_lcfv5vVnf5yyuO2--t17vf6adi8nKVNQ2MG9GyPHksWQv6Tn1-eBbYu-nsog4QvnKZgjv5K-eSNPp31RHSKh1tVMXzNX6t_7DJsHCYo3FCy4Wa_jc52t28P9nlwLqtPH97kOgpJnc5pf0hqI9hjHIw6959HbSeRsF0cyW3nGpM4eWfnHiS3EEZCqLvswGEU9LTGvtro2G3siFMNsE0zassHsf9y0yFStQa9UBQGMMOxLXLZ-nt1wY4O5LI_cSqUwmxglgdaArNjszsFTWjZZEBASFo9NBkb8iAdU5hIiy1rs7KUGf9V2koKLyn-RtTJrcvDbMRys_AXe1EqSRyOyt22dN6UfcZpTAnJ9bIY4dtN26skR9o-V8WGz6tpJ3p2vMkB4s-_COGg4FPQoZlLnbaFk4gqKkLPpGLw07vQ&uuid=88a7f57f-31fd-4b09-8402-ada820573a17&authuser=0&nonce=2dl6r9eqhjp6q&user=12515604651516018806&hash=rd221j58v41qe6ccu6p7utkfd0dsdg5g",
    name: dataTourVi.tour_6_title,
    description1: dataTourVi.tour_6_synopsys_1,
    description2: dataTourVi.tour_6_synopsys_2,
    time: dataTourVi.a1_day,
    typeTour: dataTourVi.type_tour_1,
    longitude: ' 107.43095555687225',
    latitude: '11.423441301841912',
    price: {
      adults: 300000,
      children: 150000,
      peopleWithDisabilities: 100000,
      elderly: 150000,
      student: 150000,
      pupil: 100000,
    },
    note: {
      weather: [],
      skin: [],
      regulations: [dataTourVi.tour_6_notice],
    },
    listImage: [
      '/images/tour/tour_6/image_1.jpg',
      '/images/tour/tour_6/image_2.jpg',
      '/images/tour/tour_6/image_3.jpg',
      '/images/tour/tour_6/image_4.jpg',
      '/images/tour/tour_6/image_5.jpg',
      '/images/tour/tour_6/image_6.jpg',
      '/images/tour/tour_6/image_7.jpg',
      '/images/tour/tour_6/image_9.jpg',
      '/images/tour/tour_6/image_10.jpg',
      '/images/tour/tour_6/image_11.jpg',
      '/images/tour/tour_6/image_12.jpg',
    ],
    listAction: [
      // du lịch sinh thái, giáo dục, tham quan, ô tô, xe đạp, hiking

      {
        action: dataTourVi.ecological,
        image: ic_action_ecological,
      },

      {
        action: dataTourVi.education,
        image: ic_action_education,
      },

      {
        action: dataTourVi.visit,
        image: ic_action_visit,
      },
      {
        action: dataTourVi.rowing,
        image: ic_action_rowing,
      },
      {
        action: dataTourVi.car,
        image: ic_action_car,
      },
    ],
    listSchedule: [
      {
        day: '0',
        schedule: [
          {
            time: '',
            title: dataTourVi.tour_6_schedule_day_0_time_1,
            description: dataTourVi.tour_6_schedule_day_0_title_1,
            image: [
              '/images/tour/tour_6/intinerary_1.jpg',
              '/images/tour/tour_6/intinerary_2.jpg',
            ],
          },
        ],
      },
    ],
  };

  const tour7 = {
    // tel: '0613.961683',
    // fax: '0613.961683',
    facebook: 'https://www.facebook.com/cattienvietnam',
    contact1: 'Email: cattienvietnam@gmail.com',
    contact2: 'Zalo: 0856 669 228 - Vườn quốc gia Cát Tiên',
    id: '107',
    address: dataTourVi.tour_7_address,
    imageThumb: '/images/tour/tour_7/avatar.jpg',
    // "https://doc-0c-8o-docs.googleusercontent.com/docs/securesc/dsrp2ij34p9u7k2r6vpu9brmvgf8jdh8/bplm1ddg4fv8ns7l3rbq01cutre1pi8f/1665591825000/12515604651516018806/12515604651516018806/1q_9eawOMrKv0wBVc8fh31aWHZDg-V-3E?ax=ALW9-sDUmoeS1Q6jCppLz-kECa8SwCKffz6sIvl-1VhPh1xUj854C0zLYTiknv2xCiKPKlrjOLbt5vzRGHgy3_2TX4GtYBfHHvB-XsQq1SDNa6O9P9T7jhJhdhZMW7AViEx0suUT-iamqYtqD_Yb7bCfenyHdnTGosdSbK9SqgxUuWD0aCubuf7lITeNfSoYjI3pTHwWnsrke75pHIyeysUxS_9m2yYdxpn04V6RYCmpePyOocEoN9IbSUzKZpict0jLk9Mr1Ys41GKD9jkZoU5j3R5SMP8Au1afqSqM12CzeT3BAJlQD9gj0jzwPeKZovBhDqaE-4L3ADHv-tsF1K2l-gCwpCa6xmkyNaj3veTELZoToJAGJP8praQxh1Kemcse1aM2sNWLE-Qb0nYiLRNCEcWIvceSMZKqj140r0IudZ0LjZ_yAWk8in8JQzXmdQBvONtP7OpahI_Sq-VVqd3bmMAuN54aZ7Abfq7tdHFQ_CcFEu5jE9vfLxk9Gg_lcfv5vVnf5yyuO2--t17vf6adi8nKVNQ2MG9GyPHksWQv6Tn1-eBbYu-nsog4QvnKZgjv5K-eSNPp31RHSKh1tVMXzNX6t_7DJsHCYo3FCy4Wa_jc52t28P9nlwLqtPH97kOgpJnc5pf0hqI9hjHIw6959HbSeRsF0cyW3nGpM4eWfnHiS3EEZCqLvswGEU9LTGvtro2G3siFMNsE0zassHsf9y0yFStQa9UBQGMMOxLXLZ-nt1wY4O5LI_cSqUwmxglgdaArNjszsFTWjZZEBASFo9NBkb8iAdU5hIiy1rs7KUGf9V2koKLyn-RtTJrcvDbMRys_AXe1EqSRyOyt22dN6UfcZpTAnJ9bIY4dtN26skR9o-V8WGz6tpJ3p2vMkB4s-_COGg4FPQoZlLnbaFk4gqKkLPpGLw07vQ&uuid=88a7f57f-31fd-4b09-8402-ada820573a17&authuser=0&nonce=2dl6r9eqhjp6q&user=12515604651516018806&hash=rd221j58v41qe6ccu6p7utkfd0dsdg5g",
    name: dataTourVi.tour_7_title,
    description1: dataTourVi.tour_7_synopsys_1,
    description2: dataTourVi.tour_7_synopsys_2,
    time: dataTourVi.a1_day,
    typeTour: dataTourVi.type_tour_1,
    longitude: ' 107.43095555687225',
    latitude: '11.423441301841912',
    price: {
      adults: 300000,
      children: 150000,
      peopleWithDisabilities: 100000,
      elderly: 150000,
      student: 150000,
      pupil: 100000,
    },
    note: {
      weather: [],
      skin: [],
      regulations: [dataTourVi.tour_7_notice],
    },
    listImage: [
      '/images/tour/tour_7/image_1.jpg',
      '/images/tour/tour_7/image_2.jpg',
      '/images/tour/tour_7/image_3.jpg',
      '/images/tour/tour_7/image_4.jpg',
      '/images/tour/tour_7/image_5.jpg',
      '/images/tour/tour_7/image_6.jpg',
      '/images/tour/tour_7/image_7.jpg',
      '/images/tour/tour_7/image_8.jpg',
      '/images/tour/tour_7/image_9.jpg',
      '/images/tour/tour_7/image_10.jpg',
      '/images/tour/tour_7/image_11.jpg',
      '/images/tour/tour_7/image_12.jpg',
    ],
    listAction: [
      // du lịch sinh thái, giáo dục, tham quan, ô tô, xe đạp, hiking

      {
        action: dataTourVi.ecological,
        image: ic_action_ecological,
      },

      {
        action: dataTourVi.education,
        image: ic_action_education,
      },

      {
        action: dataTourVi.visit,
        image: ic_action_visit,
      },
      {
        action: dataTourVi.hiking,
        image: ic_action_hiking,
      },
      {
        action: dataTourVi.car,
        image: ic_action_car,
      },
    ],
    listSchedule: [
      {
        day: '0',
        schedule: [
          {
            time: '',
            title: dataTourVi.tour_7_schedule_day_0_time1,
            description: dataTourVi.tour_7_schedule_day_0_title_1,
            image: [
              '/images/tour/tour_7/intinerary_1.jpg',
              '/images/tour/tour_7/intinerary_2.jpg',
              '/images/tour/tour_7/intinerary_3.jpg',
              '/images/tour/tour_7/intinerary_4.jpg',
            ],
          },
        ],
      },
    ],
  };

  const tour8 = {
    // tel: '0613.961683',
    // fax: '0613.961683',
    facebook: 'https://www.facebook.com/cattienvietnam',
    contact1: 'Email: cattienvietnam@gmail.com',
    contact2: 'Zalo: 0856 669 228 - Vườn quốc gia Cát Tiên',
    id: '108',
    address: dataTourVi.tour_8_address,
    imageThumb: '/images/tour/tour_8/avatar.jpg',
    // "https://doc-0c-8o-docs.googleusercontent.com/docs/securesc/dsrp2ij34p9u7k2r6vpu9brmvgf8jdh8/bplm1ddg4fv8ns7l3rbq01cutre1pi8f/1665591825000/12515604651516018806/12515604651516018806/1q_9eawOMrKv0wBVc8fh31aWHZDg-V-3E?ax=ALW9-sDUmoeS1Q6jCppLz-kECa8SwCKffz6sIvl-1VhPh1xUj854C0zLYTiknv2xCiKPKlrjOLbt5vzRGHgy3_2TX4GtYBfHHvB-XsQq1SDNa6O9P9T7jhJhdhZMW7AViEx0suUT-iamqYtqD_Yb7bCfenyHdnTGosdSbK9SqgxUuWD0aCubuf7lITeNfSoYjI3pTHwWnsrke75pHIyeysUxS_9m2yYdxpn04V6RYCmpePyOocEoN9IbSUzKZpict0jLk9Mr1Ys41GKD9jkZoU5j3R5SMP8Au1afqSqM12CzeT3BAJlQD9gj0jzwPeKZovBhDqaE-4L3ADHv-tsF1K2l-gCwpCa6xmkyNaj3veTELZoToJAGJP8praQxh1Kemcse1aM2sNWLE-Qb0nYiLRNCEcWIvceSMZKqj140r0IudZ0LjZ_yAWk8in8JQzXmdQBvONtP7OpahI_Sq-VVqd3bmMAuN54aZ7Abfq7tdHFQ_CcFEu5jE9vfLxk9Gg_lcfv5vVnf5yyuO2--t17vf6adi8nKVNQ2MG9GyPHksWQv6Tn1-eBbYu-nsog4QvnKZgjv5K-eSNPp31RHSKh1tVMXzNX6t_7DJsHCYo3FCy4Wa_jc52t28P9nlwLqtPH97kOgpJnc5pf0hqI9hjHIw6959HbSeRsF0cyW3nGpM4eWfnHiS3EEZCqLvswGEU9LTGvtro2G3siFMNsE0zassHsf9y0yFStQa9UBQGMMOxLXLZ-nt1wY4O5LI_cSqUwmxglgdaArNjszsFTWjZZEBASFo9NBkb8iAdU5hIiy1rs7KUGf9V2koKLyn-RtTJrcvDbMRys_AXe1EqSRyOyt22dN6UfcZpTAnJ9bIY4dtN26skR9o-V8WGz6tpJ3p2vMkB4s-_COGg4FPQoZlLnbaFk4gqKkLPpGLw07vQ&uuid=88a7f57f-31fd-4b09-8402-ada820573a17&authuser=0&nonce=2dl6r9eqhjp6q&user=12515604651516018806&hash=rd221j58v41qe6ccu6p7utkfd0dsdg5g",
    name: dataTourVi.tour_8_title,
    description1: dataTourVi.tour_8_synopsys_1,
    description2: dataTourVi.tour_8_synopsys_1,
    time: dataTourVi.a2_day_1_night,
    typeTour: dataTourVi.type_tour_1,
    longitude: ' 107.43095555687225',
    latitude: '11.423441301841912',
    price: {
      adults: 300000,
      children: 150000,
      peopleWithDisabilities: 100000,
      elderly: 150000,
      student: 150000,
      pupil: 100000,
    },
    note: {
      weather: [],
      skin: [],
      regulations: [dataTourVi.tour_8_notice],
    },
    listImage: [
      '/images/tour/tour_8/image_1.jpg',
      '/images/tour/tour_8/image_2.jpg',
      '/images/tour/tour_8/image_3.jpg',
      '/images/tour/tour_8/image_4.jpg',
      '/images/tour/tour_8/image_5.jpg',
      '/images/tour/tour_8/image_6.jpg',
      '/images/tour/tour_8/image_7.jpg',
      '/images/tour/tour_8/image_8.jpg',
      '/images/tour/tour_8/image_9.jpg',
      '/images/tour/tour_8/image_10.jpg',
      '/images/tour/tour_8/image_11.jpg',
      '/images/tour/tour_8/image_12.jpg',
    ],
    listAction: [
      // du lịch sinh thái, du lịch cộng đồng, hiking, giáo dục, tham quan
      {
        action: dataTourVi.ecological,
        image: ic_action_ecological,
      },

      {
        action: dataTourVi.education,
        image: ic_action_education,
      },

      {
        action: dataTourVi.visit,
        image: ic_action_visit,
      },
      {
        action: dataTourVi.hiking,
        image: ic_action_hiking,
      },
      {
        action: dataTourVi.community,
        image: ic_action_community,
      },
    ],
    listSchedule: [
      {
        day: '0',
        schedule: [
          {
            time: '',
            title: dataTourVi.tour_8_schedule_day_0_time1,
            description: dataTourVi.tour_8_schedule_day_0_title_1,
            image: [
              '/images/tour/tour_8/intinerary_1.jpg',
              '/images/tour/tour_8/intinerary_2.jpg',
              '/images/tour/tour_8/intinerary_3.jpg',
              '/images/tour/tour_8/intinerary_4.jpg',
              '/images/tour/tour_8/intinerary_5.jpg',
              '/images/tour/tour_8/intinerary_6.jpg',
              '/images/tour/tour_8/intinerary_7.jpg',
              '/images/tour/tour_8/intinerary_8.jpg',
              '/images/tour/tour_8/intinerary_9.jpg',
              '/images/tour/tour_8/intinerary_10.jpg',
              '/images/tour/tour_8/intinerary_11.jpg',
              '/images/tour/tour_8/intinerary_12.jpg',
              '/images/tour/tour_8/intinerary_13.jpg',
              '/images/tour/tour_8/intinerary_14.jpg',
            ],
          },
        ],
      },
    ],
  };

  const tour9 = {
    // tel: '0613.961683',
    // fax: '0613.961683',
    facebook: 'https://www.facebook.com/cattienvietnam',
    contact1: 'Email: cattienvietnam@gmail.com',
    contact2: 'Zalo: 0856 669 228 - Vườn quốc gia Cát Tiên',
    id: '109',
    address: dataTourVi.tour_9_address,
    imageThumb: '/images/tour/tour_9/avatar.jpg',
    // "https://doc-0c-8o-docs.googleusercontent.com/docs/securesc/dsrp2ij34p9u7k2r6vpu9brmvgf8jdh8/bplm1ddg4fv8ns7l3rbq01cutre1pi8f/1665591825000/12515604651516018806/12515604651516018806/1q_9eawOMrKv0wBVc8fh31aWHZDg-V-3E?ax=ALW9-sDUmoeS1Q6jCppLz-kECa8SwCKffz6sIvl-1VhPh1xUj854C0zLYTiknv2xCiKPKlrjOLbt5vzRGHgy3_2TX4GtYBfHHvB-XsQq1SDNa6O9P9T7jhJhdhZMW7AViEx0suUT-iamqYtqD_Yb7bCfenyHdnTGosdSbK9SqgxUuWD0aCubuf7lITeNfSoYjI3pTHwWnsrke75pHIyeysUxS_9m2yYdxpn04V6RYCmpePyOocEoN9IbSUzKZpict0jLk9Mr1Ys41GKD9jkZoU5j3R5SMP8Au1afqSqM12CzeT3BAJlQD9gj0jzwPeKZovBhDqaE-4L3ADHv-tsF1K2l-gCwpCa6xmkyNaj3veTELZoToJAGJP8praQxh1Kemcse1aM2sNWLE-Qb0nYiLRNCEcWIvceSMZKqj140r0IudZ0LjZ_yAWk8in8JQzXmdQBvONtP7OpahI_Sq-VVqd3bmMAuN54aZ7Abfq7tdHFQ_CcFEu5jE9vfLxk9Gg_lcfv5vVnf5yyuO2--t17vf6adi8nKVNQ2MG9GyPHksWQv6Tn1-eBbYu-nsog4QvnKZgjv5K-eSNPp31RHSKh1tVMXzNX6t_7DJsHCYo3FCy4Wa_jc52t28P9nlwLqtPH97kOgpJnc5pf0hqI9hjHIw6959HbSeRsF0cyW3nGpM4eWfnHiS3EEZCqLvswGEU9LTGvtro2G3siFMNsE0zassHsf9y0yFStQa9UBQGMMOxLXLZ-nt1wY4O5LI_cSqUwmxglgdaArNjszsFTWjZZEBASFo9NBkb8iAdU5hIiy1rs7KUGf9V2koKLyn-RtTJrcvDbMRys_AXe1EqSRyOyt22dN6UfcZpTAnJ9bIY4dtN26skR9o-V8WGz6tpJ3p2vMkB4s-_COGg4FPQoZlLnbaFk4gqKkLPpGLw07vQ&uuid=88a7f57f-31fd-4b09-8402-ada820573a17&authuser=0&nonce=2dl6r9eqhjp6q&user=12515604651516018806&hash=rd221j58v41qe6ccu6p7utkfd0dsdg5g",
    name: dataTourVi.tour_9_title,
    description1: dataTourVi.tour_9_synopsys_1,
    description2: dataTourVi.tour_9_synopsys_2,
    time: dataTourVi.a1_day,
    typeTour: dataTourVi.type_tour_1,
    longitude: ' 107.43095555687225',
    latitude: '11.423441301841912',
    price: {
      adults: 300000,
      children: 150000,
      peopleWithDisabilities: 100000,
      elderly: 150000,
      student: 150000,
      pupil: 100000,
    },
    note: {
      weather: [],
      skin: [],
      regulations: [dataTourVi.tour_9_notice],
    },
    listImage: [
      '/images/tour/tour_9/image_1.jpg',
      '/images/tour/tour_9/image_2.jpg',
      '/images/tour/tour_9/image_3.jpg',
      '/images/tour/tour_9/image_4.jpg',
      '/images/tour/tour_9/image_5.jpg',
      '/images/tour/tour_9/image_6.jpg',
      '/images/tour/tour_9/image_7.jpg',
      '/images/tour/tour_9/image_8.jpg',
      '/images/tour/tour_9/image_9.jpg',
      '/images/tour/tour_9/image_10.jpg',
      '/images/tour/tour_9/image_11.jpg',
      '/images/tour/tour_9/image_12.jpg',
    ],
    listAction: [
      // du lịch sinh thái, du lịch cộng đồng, hiking, giáo dục, tham quan
      {
        action: dataTourVi.ecological,
        image: ic_action_ecological,
      },

      {
        action: dataTourVi.education,
        image: ic_action_education,
      },

      {
        action: dataTourVi.visit,
        image: ic_action_visit,
      },
      {
        action: dataTourVi.hiking,
        image: ic_action_hiking,
      },
    ],
    listSchedule: [
      {
        day: '0',
        schedule: [
          {
            time: '',
            title: dataTourVi.tour_9_schedule_day_0_time1,
            description: dataTourVi.tour_9_schedule_day_0_title_1,
            image: [
              '/images/tour/tour_9/intinerary_1.jpg',
              '/images/tour/tour_9/intinerary_2.jpg',
              '/images/tour/tour_9/intinerary_3.jpg',
            ],
          },
        ],
      },
    ],
  };

  const tour10 = {
    // tel: '0613.961683',
    // fax: '0613.961683',
    facebook: 'https://www.facebook.com/cattienvietnam',
    contact1: 'Email: cattienvietnam@gmail.com',
    contact2: 'Zalo: 0856 669 228 - Vườn quốc gia Cát Tiên',
    id: '110',
    address: dataTourVi.tour_10_address,
    imageThumb: '/images/tour/tour_10/avatar.jpg',
    // "https://doc-0c-8o-docs.googleusercontent.com/docs/securesc/dsrp2ij34p9u7k2r6vpu9brmvgf8jdh8/bplm1ddg4fv8ns7l3rbq01cutre1pi8f/1665591825000/12515604651516018806/12515604651516018806/1q_9eawOMrKv0wBVc8fh31aWHZDg-V-3E?ax=ALW9-sDUmoeS1Q6jCppLz-kECa8SwCKffz6sIvl-1VhPh1xUj854C0zLYTiknv2xCiKPKlrjOLbt5vzRGHgy3_2TX4GtYBfHHvB-XsQq1SDNa6O9P9T7jhJhdhZMW7AViEx0suUT-iamqYtqD_Yb7bCfenyHdnTGosdSbK9SqgxUuWD0aCubuf7lITeNfSoYjI3pTHwWnsrke75pHIyeysUxS_9m2yYdxpn04V6RYCmpePyOocEoN9IbSUzKZpict0jLk9Mr1Ys41GKD9jkZoU5j3R5SMP8Au1afqSqM12CzeT3BAJlQD9gj0jzwPeKZovBhDqaE-4L3ADHv-tsF1K2l-gCwpCa6xmkyNaj3veTELZoToJAGJP8praQxh1Kemcse1aM2sNWLE-Qb0nYiLRNCEcWIvceSMZKqj140r0IudZ0LjZ_yAWk8in8JQzXmdQBvONtP7OpahI_Sq-VVqd3bmMAuN54aZ7Abfq7tdHFQ_CcFEu5jE9vfLxk9Gg_lcfv5vVnf5yyuO2--t17vf6adi8nKVNQ2MG9GyPHksWQv6Tn1-eBbYu-nsog4QvnKZgjv5K-eSNPp31RHSKh1tVMXzNX6t_7DJsHCYo3FCy4Wa_jc52t28P9nlwLqtPH97kOgpJnc5pf0hqI9hjHIw6959HbSeRsF0cyW3nGpM4eWfnHiS3EEZCqLvswGEU9LTGvtro2G3siFMNsE0zassHsf9y0yFStQa9UBQGMMOxLXLZ-nt1wY4O5LI_cSqUwmxglgdaArNjszsFTWjZZEBASFo9NBkb8iAdU5hIiy1rs7KUGf9V2koKLyn-RtTJrcvDbMRys_AXe1EqSRyOyt22dN6UfcZpTAnJ9bIY4dtN26skR9o-V8WGz6tpJ3p2vMkB4s-_COGg4FPQoZlLnbaFk4gqKkLPpGLw07vQ&uuid=88a7f57f-31fd-4b09-8402-ada820573a17&authuser=0&nonce=2dl6r9eqhjp6q&user=12515604651516018806&hash=rd221j58v41qe6ccu6p7utkfd0dsdg5g",
    name: dataTourVi.tour_10_title,
    description1: dataTourVi.tour_10_synopsys_1,
    description2: dataTourVi.tour_10_synopsys_2,
    time: dataTourVi.a1_day,
    typeTour: dataTourVi.type_tour_1,
    longitude: ' 107.43095555687225',
    latitude: '11.423441301841912',
    price: {
      adults: 300000,
      children: 150000,
      peopleWithDisabilities: 100000,
      elderly: 150000,
      student: 150000,
      pupil: 100000,
    },
    note: {
      weather: [],
      skin: [],
      regulations: [dataTourVi.tour_10_notice],
    },
    listImage: [
      '/images/tour/tour_10/image_1.jpg',
      '/images/tour/tour_10/image_2.jpg',
      '/images/tour/tour_10/image_3.jpg',
      '/images/tour/tour_10/image_4.jpg',
      '/images/tour/tour_10/image_5.jpg',
      '/images/tour/tour_10/image_6.jpg',
      '/images/tour/tour_10/image_7.jpg',
      '/images/tour/tour_10/image_8.jpg',
      '/images/tour/tour_10/image_9.jpg',
      '/images/tour/tour_10/image_10.jpg',
      '/images/tour/tour_10/image_11.jpg',
      '/images/tour/tour_10/image_12.jpg',
    ],
    listAction: [
      // du lịch sinh thái, du lịch cộng đồng, hiking, giáo dục, tham quan
      {
        action: dataTourVi.ecological,
        image: ic_action_ecological,
      },

      {
        action: dataTourVi.education,
        image: ic_action_education,
      },

      {
        action: dataTourVi.visit,
        image: ic_action_visit,
      },
      {
        action: dataTourVi.hiking,
        image: ic_action_hiking,
      },
      {
        action: dataTourVi.car,
        image: ic_action_car,
      },
    ],
    listSchedule: [
      {
        day: '0',
        schedule: [
          {
            time: '',
            title: dataTourVi.tour_10_schedule_day_0_time1,
            description: dataTourVi.tour_10_schedule_day_0_title_1,

            image: [
              '/images/tour/tour_10/intinerary_1.jpg',
              '/images/tour/tour_10/intinerary_2.jpg',
              '/images/tour/tour_10/intinerary_3.jpg',
            ],
          },
        ],
      },
    ],
  };

  const tour11 = {
    // tel: '0613.961683',
    // fax: '0613.961683',
    facebook: 'https://www.facebook.com/cattienvietnam',
    contact1: 'Email: cattienvietnam@gmail.com',
    contact2: 'Zalo: 0856 669 228 - Vườn quốc gia Cát Tiên',
    id: '111',
    address: dataTourVi.tour_10_address,
    imageThumb: '/images/tour/tour_11/avatar.jpg',
    // "https://doc-0c-8o-docs.googleusercontent.com/docs/securesc/dsrp2ij34p9u7k2r6vpu9brmvgf8jdh8/bplm1ddg4fv8ns7l3rbq01cutre1pi8f/1665591825000/12515604651516018806/12515604651516018806/1q_9eawOMrKv0wBVc8fh31aWHZDg-V-3E?ax=ALW9-sDUmoeS1Q6jCppLz-kECa8SwCKffz6sIvl-1VhPh1xUj854C0zLYTiknv2xCiKPKlrjOLbt5vzRGHgy3_2TX4GtYBfHHvB-XsQq1SDNa6O9P9T7jhJhdhZMW7AViEx0suUT-iamqYtqD_Yb7bCfenyHdnTGosdSbK9SqgxUuWD0aCubuf7lITeNfSoYjI3pTHwWnsrke75pHIyeysUxS_9m2yYdxpn04V6RYCmpePyOocEoN9IbSUzKZpict0jLk9Mr1Ys41GKD9jkZoU5j3R5SMP8Au1afqSqM12CzeT3BAJlQD9gj0jzwPeKZovBhDqaE-4L3ADHv-tsF1K2l-gCwpCa6xmkyNaj3veTELZoToJAGJP8praQxh1Kemcse1aM2sNWLE-Qb0nYiLRNCEcWIvceSMZKqj140r0IudZ0LjZ_yAWk8in8JQzXmdQBvONtP7OpahI_Sq-VVqd3bmMAuN54aZ7Abfq7tdHFQ_CcFEu5jE9vfLxk9Gg_lcfv5vVnf5yyuO2--t17vf6adi8nKVNQ2MG9GyPHksWQv6Tn1-eBbYu-nsog4QvnKZgjv5K-eSNPp31RHSKh1tVMXzNX6t_7DJsHCYo3FCy4Wa_jc52t28P9nlwLqtPH97kOgpJnc5pf0hqI9hjHIw6959HbSeRsF0cyW3nGpM4eWfnHiS3EEZCqLvswGEU9LTGvtro2G3siFMNsE0zassHsf9y0yFStQa9UBQGMMOxLXLZ-nt1wY4O5LI_cSqUwmxglgdaArNjszsFTWjZZEBASFo9NBkb8iAdU5hIiy1rs7KUGf9V2koKLyn-RtTJrcvDbMRys_AXe1EqSRyOyt22dN6UfcZpTAnJ9bIY4dtN26skR9o-V8WGz6tpJ3p2vMkB4s-_COGg4FPQoZlLnbaFk4gqKkLPpGLw07vQ&uuid=88a7f57f-31fd-4b09-8402-ada820573a17&authuser=0&nonce=2dl6r9eqhjp6q&user=12515604651516018806&hash=rd221j58v41qe6ccu6p7utkfd0dsdg5g",
    name: dataTourVi.tour_10_title,
    description1: dataTourVi.tour_10_synopsys_1,
    description2: dataTourVi.tour_10_synopsys_2,
    time: dataTourVi.a1_day,
    typeTour: dataTourVi.type_tour_1,
    longitude: ' 107.43095555687225',
    latitude: '11.423441301841912',
    price: {
      adults: 300000,
      children: 150000,
      peopleWithDisabilities: 100000,
      elderly: 150000,
      student: 150000,
      pupil: 100000,
    },
    note: {
      weather: [],
      skin: [],
      regulations: [dataTourVi.tour_11_notice],
    },
    listImage: [
      '/images/tour/tour_11/image_1.jpg',
      '/images/tour/tour_11/image_2.jpg',
      '/images/tour/tour_11/image_3.jpg',
      '/images/tour/tour_11/image_4.jpg',
      '/images/tour/tour_11/image_5.jpg',
      '/images/tour/tour_11/image_6.jpg',
      '/images/tour/tour_11/image_7.jpg',
      '/images/tour/tour_11/image_8.jpg',
      '/images/tour/tour_11/image_9.jpg',
      '/images/tour/tour_11/image_10.jpg',
      '/images/tour/tour_11/image_11.jpg',
      '/images/tour/tour_11/image_12.jpg',
    ],
    listAction: [
      // du lịch sinh thái, du lịch cộng đồng, hiking, giáo dục, tham quan
      {
        action: dataTourVi.ecological,
        image: ic_action_ecological,
      },

      {
        action: dataTourVi.education,
        image: ic_action_education,
      },

      {
        action: dataTourVi.visit,
        image: ic_action_visit,
      },
      {
        action: dataTourVi.cycling,
        image: ic_action_cycling,
      },
      {
        action: dataTourVi.rowing,
        image: ic_action_rowing,
      },
      {
        action: dataTourVi.car,
        image: ic_action_car,
      },
    ],
    listSchedule: [
      {
        day: '1',
        schedule: [
          {
            time: '',
            title: dataTourVi.tour_11_schedule_day_0_time1,
            description: dataTourVi.tour_11_schedule_day_0_title_1,
            image: [
              '/images/tour/tour_11/intinerary_1.jpg',
              '/images/tour/tour_11/intinerary_2.jpg',
              '/images/tour/tour_11/intinerary_3.jpg',
            ],
          },
        ],
      },
    ],
  };

  const tour12 = {
    // tel: '0613.961683',
    // fax: '0613.961683',
    facebook: 'https://www.facebook.com/cattienvietnam',
    contact1: 'Email: cattienvietnam@gmail.com',
    contact2: 'Zalo: 0856 669 228 - Vườn quốc gia Cát Tiên',
    id: '112',
    address: dataTourVi.tour_12_address,
    imageThumb: '/images/tour/tour_12/avatar.jpg',
    // "https://doc-0c-8o-docs.googleusercontent.com/docs/securesc/dsrp2ij34p9u7k2r6vpu9brmvgf8jdh8/bplm1ddg4fv8ns7l3rbq01cutre1pi8f/1665591825000/12515604651516018806/12515604651516018806/1q_9eawOMrKv0wBVc8fh31aWHZDg-V-3E?ax=ALW9-sDUmoeS1Q6jCppLz-kECa8SwCKffz6sIvl-1VhPh1xUj854C0zLYTiknv2xCiKPKlrjOLbt5vzRGHgy3_2TX4GtYBfHHvB-XsQq1SDNa6O9P9T7jhJhdhZMW7AViEx0suUT-iamqYtqD_Yb7bCfenyHdnTGosdSbK9SqgxUuWD0aCubuf7lITeNfSoYjI3pTHwWnsrke75pHIyeysUxS_9m2yYdxpn04V6RYCmpePyOocEoN9IbSUzKZpict0jLk9Mr1Ys41GKD9jkZoU5j3R5SMP8Au1afqSqM12CzeT3BAJlQD9gj0jzwPeKZovBhDqaE-4L3ADHv-tsF1K2l-gCwpCa6xmkyNaj3veTELZoToJAGJP8praQxh1Kemcse1aM2sNWLE-Qb0nYiLRNCEcWIvceSMZKqj140r0IudZ0LjZ_yAWk8in8JQzXmdQBvONtP7OpahI_Sq-VVqd3bmMAuN54aZ7Abfq7tdHFQ_CcFEu5jE9vfLxk9Gg_lcfv5vVnf5yyuO2--t17vf6adi8nKVNQ2MG9GyPHksWQv6Tn1-eBbYu-nsog4QvnKZgjv5K-eSNPp31RHSKh1tVMXzNX6t_7DJsHCYo3FCy4Wa_jc52t28P9nlwLqtPH97kOgpJnc5pf0hqI9hjHIw6959HbSeRsF0cyW3nGpM4eWfnHiS3EEZCqLvswGEU9LTGvtro2G3siFMNsE0zassHsf9y0yFStQa9UBQGMMOxLXLZ-nt1wY4O5LI_cSqUwmxglgdaArNjszsFTWjZZEBASFo9NBkb8iAdU5hIiy1rs7KUGf9V2koKLyn-RtTJrcvDbMRys_AXe1EqSRyOyt22dN6UfcZpTAnJ9bIY4dtN26skR9o-V8WGz6tpJ3p2vMkB4s-_COGg4FPQoZlLnbaFk4gqKkLPpGLw07vQ&uuid=88a7f57f-31fd-4b09-8402-ada820573a17&authuser=0&nonce=2dl6r9eqhjp6q&user=12515604651516018806&hash=rd221j58v41qe6ccu6p7utkfd0dsdg5g",
    name: dataTourVi.tour_12_title,
    description1: dataTourVi.tour_12_synopsys_1,
    description2: dataTourVi.tour_12_synopsys_2,
    time: dataTourVi.a1_day,
    typeTour: dataTourVi.type_tour_1,
    longitude: ' 107.43095555687225',
    latitude: '11.423441301841912',
    price: {
      adults: 300000,
      children: 150000,
      peopleWithDisabilities: 100000,
      elderly: 150000,
      student: 150000,
      pupil: 100000,
    },
    note: {
      weather: [],
      skin: [],
      regulations: [dataTourVi.tour_12_notice],
    },
    listImage: [
      '/images/tour/tour_12/image_1.jpg',
      '/images/tour/tour_12/image_2.jpg',
      '/images/tour/tour_12/image_3.jpg',
      '/images/tour/tour_12/image_4.jpg',
      '/images/tour/tour_12/image_5.jpg',
      '/images/tour/tour_12/image_6.jpg',
      '/images/tour/tour_12/image_7.jpg',
      '/images/tour/tour_12/image_8.jpg',
      '/images/tour/tour_12/image_9.jpg',
      '/images/tour/tour_12/image_10.jpg',
      '/images/tour/tour_12/image_11.jpg',
      '/images/tour/tour_12/image_12.jpg',
    ],
    listAction: [
      // du lịch sinh thái, du lịch cộng đồng, hiking, giáo dục, tham quan
      {
        action: dataTourVi.ecological,
        image: ic_action_ecological,
      },

      {
        action: dataTourVi.education,
        image: ic_action_education,
      },

      {
        action: dataTourVi.visit,
        image: ic_action_visit,
      },
      {
        action: dataTourVi.cycling,
        image: ic_action_cycling,
      },
      {
        action: dataTourVi.hiking,
        image: ic_action_hiking,
      },
      {
        action: dataTourVi.car,
        image: ic_action_car,
      },
    ],
    listSchedule: [
      {
        day: '1',
        schedule: [
          {
            time: '',
            title: dataTourVi.tour_12_schedule_day_0_time1,
            description: dataTourVi.tour_12_schedule_day_0_title_1,
            image: [
              '/images/tour/tour_12/intinerary_1.jpg',
              '/images/tour/tour_12/intinerary_2.jpg',
              '/images/tour/tour_12/intinerary_3.jpg',
              '/images/tour/tour_12/intinerary_4.jpg',
            ],
          },
        ],
      },
    ],
  };

  const tour13 = {
    // tel: '0613.961683',
    // fax: '0613.961683',
    facebook: 'https://www.facebook.com/cattienvietnam',
    contact1: 'Email: cattienvietnam@gmail.com',
    contact2: 'Zalo: 0856 669 228 - Vườn quốc gia Cát Tiên',
    id: '113',
    address: dataTourVi.tour_13_address,
    imageThumb: '/images/tour/tour_13/avatar.jpg',
    // "https://doc-0c-8o-docs.googleusercontent.com/docs/securesc/dsrp2ij34p9u7k2r6vpu9brmvgf8jdh8/bplm1ddg4fv8ns7l3rbq01cutre1pi8f/1665591825000/12515604651516018806/12515604651516018806/1q_9eawOMrKv0wBVc8fh31aWHZDg-V-3E?ax=ALW9-sDUmoeS1Q6jCppLz-kECa8SwCKffz6sIvl-1VhPh1xUj854C0zLYTiknv2xCiKPKlrjOLbt5vzRGHgy3_2TX4GtYBfHHvB-XsQq1SDNa6O9P9T7jhJhdhZMW7AViEx0suUT-iamqYtqD_Yb7bCfenyHdnTGosdSbK9SqgxUuWD0aCubuf7lITeNfSoYjI3pTHwWnsrke75pHIyeysUxS_9m2yYdxpn04V6RYCmpePyOocEoN9IbSUzKZpict0jLk9Mr1Ys41GKD9jkZoU5j3R5SMP8Au1afqSqM12CzeT3BAJlQD9gj0jzwPeKZovBhDqaE-4L3ADHv-tsF1K2l-gCwpCa6xmkyNaj3veTELZoToJAGJP8praQxh1Kemcse1aM2sNWLE-Qb0nYiLRNCEcWIvceSMZKqj140r0IudZ0LjZ_yAWk8in8JQzXmdQBvONtP7OpahI_Sq-VVqd3bmMAuN54aZ7Abfq7tdHFQ_CcFEu5jE9vfLxk9Gg_lcfv5vVnf5yyuO2--t17vf6adi8nKVNQ2MG9GyPHksWQv6Tn1-eBbYu-nsog4QvnKZgjv5K-eSNPp31RHSKh1tVMXzNX6t_7DJsHCYo3FCy4Wa_jc52t28P9nlwLqtPH97kOgpJnc5pf0hqI9hjHIw6959HbSeRsF0cyW3nGpM4eWfnHiS3EEZCqLvswGEU9LTGvtro2G3siFMNsE0zassHsf9y0yFStQa9UBQGMMOxLXLZ-nt1wY4O5LI_cSqUwmxglgdaArNjszsFTWjZZEBASFo9NBkb8iAdU5hIiy1rs7KUGf9V2koKLyn-RtTJrcvDbMRys_AXe1EqSRyOyt22dN6UfcZpTAnJ9bIY4dtN26skR9o-V8WGz6tpJ3p2vMkB4s-_COGg4FPQoZlLnbaFk4gqKkLPpGLw07vQ&uuid=88a7f57f-31fd-4b09-8402-ada820573a17&authuser=0&nonce=2dl6r9eqhjp6q&user=12515604651516018806&hash=rd221j58v41qe6ccu6p7utkfd0dsdg5g",
    name: dataTourVi.tour_13_title,
    description1: dataTourVi.tour_13_synopsys_1,
    description2: dataTourVi.tour_13_synopsys_2,
    time: dataTourVi.a2_day_1_night,
    typeTour: dataTourVi.type_tour_1,
    longitude: ' 107.43095555687225',
    latitude: '11.423441301841912',
    price: {
      adults: 300000,
      children: 150000,
      peopleWithDisabilities: 100000,
      elderly: 150000,
      student: 150000,
      pupil: 100000,
    },
    note: {
      weather: [],
      skin: [],
      regulations: [dataTourVi.tour_13_notice],
    },
    listImage: [
      '/images/tour/tour_13/image_1.jpg',
      '/images/tour/tour_13/image_2.jpg',
      '/images/tour/tour_13/image_3.jpg',
      '/images/tour/tour_13/image_4.jpg',
      '/images/tour/tour_13/image_5.jpg',
      '/images/tour/tour_13/image_6.jpg',
      '/images/tour/tour_13/image_7.jpg',
      '/images/tour/tour_13/image_8.jpg',
      '/images/tour/tour_13/image_9.jpg',
      '/images/tour/tour_13/image_10.jpg',
      '/images/tour/tour_13/image_11.jpg',
      '/images/tour/tour_13/image_12.jpg',
    ],
    listAction: [
      // du lịch sinh thái, du lịch cộng đồng, hiking, giáo dục, tham quan
      {
        action: dataTourVi.ecological,
        image: ic_action_ecological,
      },

      {
        action: dataTourVi.education,
        image: ic_action_education,
      },

      {
        action: dataTourVi.visit,
        image: ic_action_visit,
      },

      {
        action: dataTourVi.hiking,
        image: ic_action_hiking,
      },
      {
        action: dataTourVi.car,
        image: ic_action_car,
      },
    ],
    listSchedule: [
      {
        day: '1',
        schedule: [
          {
            time: '',
            title: dataTourVi.tour_13_schedule_day_0_time1,
            description: dataTourVi.tour_13_schedule_day_0_title_1,
            image: [
              '/images/tour/tour_13/intinerary_1.jpg',
              '/images/tour/tour_13/intinerary_2.jpg',
              '/images/tour/tour_13/intinerary_3.jpg',
            ],
          },
        ],
      },
    ],
  };

  const tour14 = {
    // tel: '0613.961683',
    // fax: '0613.961683',
    facebook: 'https://www.facebook.com/cattienvietnam',
    contact1: 'Email: cattienvietnam@gmail.com',
    contact2: 'Zalo: 0856 669 228 - Vườn quốc gia Cát Tiên',
    id: '114',
    address: dataTourVi.tour_14_address,
    imageThumb: '/images/tour/tour_14/avatar.jpg',
    // "https://doc-0c-8o-docs.googleusercontent.com/docs/securesc/dsrp2ij34p9u7k2r6vpu9brmvgf8jdh8/bplm1ddg4fv8ns7l3rbq01cutre1pi8f/1665591825000/12515604651516018806/12515604651516018806/1q_9eawOMrKv0wBVc8fh31aWHZDg-V-3E?ax=ALW9-sDUmoeS1Q6jCppLz-kECa8SwCKffz6sIvl-1VhPh1xUj854C0zLYTiknv2xCiKPKlrjOLbt5vzRGHgy3_2TX4GtYBfHHvB-XsQq1SDNa6O9P9T7jhJhdhZMW7AViEx0suUT-iamqYtqD_Yb7bCfenyHdnTGosdSbK9SqgxUuWD0aCubuf7lITeNfSoYjI3pTHwWnsrke75pHIyeysUxS_9m2yYdxpn04V6RYCmpePyOocEoN9IbSUzKZpict0jLk9Mr1Ys41GKD9jkZoU5j3R5SMP8Au1afqSqM12CzeT3BAJlQD9gj0jzwPeKZovBhDqaE-4L3ADHv-tsF1K2l-gCwpCa6xmkyNaj3veTELZoToJAGJP8praQxh1Kemcse1aM2sNWLE-Qb0nYiLRNCEcWIvceSMZKqj140r0IudZ0LjZ_yAWk8in8JQzXmdQBvONtP7OpahI_Sq-VVqd3bmMAuN54aZ7Abfq7tdHFQ_CcFEu5jE9vfLxk9Gg_lcfv5vVnf5yyuO2--t17vf6adi8nKVNQ2MG9GyPHksWQv6Tn1-eBbYu-nsog4QvnKZgjv5K-eSNPp31RHSKh1tVMXzNX6t_7DJsHCYo3FCy4Wa_jc52t28P9nlwLqtPH97kOgpJnc5pf0hqI9hjHIw6959HbSeRsF0cyW3nGpM4eWfnHiS3EEZCqLvswGEU9LTGvtro2G3siFMNsE0zassHsf9y0yFStQa9UBQGMMOxLXLZ-nt1wY4O5LI_cSqUwmxglgdaArNjszsFTWjZZEBASFo9NBkb8iAdU5hIiy1rs7KUGf9V2koKLyn-RtTJrcvDbMRys_AXe1EqSRyOyt22dN6UfcZpTAnJ9bIY4dtN26skR9o-V8WGz6tpJ3p2vMkB4s-_COGg4FPQoZlLnbaFk4gqKkLPpGLw07vQ&uuid=88a7f57f-31fd-4b09-8402-ada820573a17&authuser=0&nonce=2dl6r9eqhjp6q&user=12515604651516018806&hash=rd221j58v41qe6ccu6p7utkfd0dsdg5g",
    name: dataTourVi.tour_14_title,
    description1: dataTourVi.tour_14_synopsys_1,
    description2: dataTourVi.tour_14_synopsys_2,
    time: dataTourVi.a2_day_1_night,
    typeTour: dataTourVi.type_tour_1,
    longitude: ' 107.43095555687225',
    latitude: '11.423441301841912',
    price: {
      adults: 300000,
      children: 150000,
      peopleWithDisabilities: 100000,
      elderly: 150000,
      student: 150000,
      pupil: 100000,
    },
    note: {
      weather: [],
      skin: [],
      regulations: [dataTourVi.tour_14_notice],
    },
    listImage: [
      '/images/tour/tour_14/image_1.jpg',
      '/images/tour/tour_14/image_2.jpg',
      '/images/tour/tour_14/image_3.jpg',
      '/images/tour/tour_14/image_4.jpg',
      '/images/tour/tour_14/image_5.jpg',
      '/images/tour/tour_14/image_6.jpg',
      '/images/tour/tour_14/image_7.jpg',
      '/images/tour/tour_14/image_8.jpg',
      '/images/tour/tour_14/image_9.jpg',
      '/images/tour/tour_14/image_10.jpg',
      '/images/tour/tour_14/image_11.jpg',
      '/images/tour/tour_14/image_12.jpg',
    ],
    listAction: [
      {
        action: dataTourVi.ecological,
        image: ic_action_ecological,
      },
      {
        action: dataTourVi.education,
        image: ic_action_education,
      },
      {
        action: dataTourVi.visit,
        image: ic_action_visit,
      },
      {
        action: dataTourVi.hiking,
        image: ic_action_hiking,
      },
      {
        action: dataTourVi.car,
        image: ic_action_car,
      },
    ],
    listSchedule: [
      {
        day: '1',
        schedule: [
          {
            time: '',
            title: dataTourVi.tour_14_schedule_day_0_time1,
            description: dataTourVi.tour_14_schedule_day_0_title_1,
            image: [
              '/images/tour/tour_14/intinerary_1.jpg',
              '/images/tour/tour_14/intinerary_2.jpg',
              '/images/tour/tour_14/intinerary_3.jpg',
            ],
          },
        ],
      },
    ],
  };

  const tour15 = {
    // tel: '0613.961683',
    // fax: '0613.961683',
    facebook: 'https://www.facebook.com/cattienvietnam',
    contact1: 'Email: cattienvietnam@gmail.com',
    contact2: 'Zalo: 0856 669 228 - Vườn quốc gia Cát Tiên',
    id: '115',
    address: dataTourVi.tour_15_address,
    imageThumb: '/images/tour/tour_15/avatar.jpg',
    // "https://doc-0c-8o-docs.googleusercontent.com/docs/securesc/dsrp2ij34p9u7k2r6vpu9brmvgf8jdh8/bplm1ddg4fv8ns7l3rbq01cutre1pi8f/1665591825000/12515604651516018806/12515604651516018806/1q_9eawOMrKv0wBVc8fh31aWHZDg-V-3E?ax=ALW9-sDUmoeS1Q6jCppLz-kECa8SwCKffz6sIvl-1VhPh1xUj854C0zLYTiknv2xCiKPKlrjOLbt5vzRGHgy3_2TX4GtYBfHHvB-XsQq1SDNa6O9P9T7jhJhdhZMW7AViEx0suUT-iamqYtqD_Yb7bCfenyHdnTGosdSbK9SqgxUuWD0aCubuf7lITeNfSoYjI3pTHwWnsrke75pHIyeysUxS_9m2yYdxpn04V6RYCmpePyOocEoN9IbSUzKZpict0jLk9Mr1Ys41GKD9jkZoU5j3R5SMP8Au1afqSqM12CzeT3BAJlQD9gj0jzwPeKZovBhDqaE-4L3ADHv-tsF1K2l-gCwpCa6xmkyNaj3veTELZoToJAGJP8praQxh1Kemcse1aM2sNWLE-Qb0nYiLRNCEcWIvceSMZKqj140r0IudZ0LjZ_yAWk8in8JQzXmdQBvONtP7OpahI_Sq-VVqd3bmMAuN54aZ7Abfq7tdHFQ_CcFEu5jE9vfLxk9Gg_lcfv5vVnf5yyuO2--t17vf6adi8nKVNQ2MG9GyPHksWQv6Tn1-eBbYu-nsog4QvnKZgjv5K-eSNPp31RHSKh1tVMXzNX6t_7DJsHCYo3FCy4Wa_jc52t28P9nlwLqtPH97kOgpJnc5pf0hqI9hjHIw6959HbSeRsF0cyW3nGpM4eWfnHiS3EEZCqLvswGEU9LTGvtro2G3siFMNsE0zassHsf9y0yFStQa9UBQGMMOxLXLZ-nt1wY4O5LI_cSqUwmxglgdaArNjszsFTWjZZEBASFo9NBkb8iAdU5hIiy1rs7KUGf9V2koKLyn-RtTJrcvDbMRys_AXe1EqSRyOyt22dN6UfcZpTAnJ9bIY4dtN26skR9o-V8WGz6tpJ3p2vMkB4s-_COGg4FPQoZlLnbaFk4gqKkLPpGLw07vQ&uuid=88a7f57f-31fd-4b09-8402-ada820573a17&authuser=0&nonce=2dl6r9eqhjp6q&user=12515604651516018806&hash=rd221j58v41qe6ccu6p7utkfd0dsdg5g",
    name: dataTourVi.tour_15_title,
    description1: dataTourVi.tour_15_synopsys_1,
    description2: dataTourVi.tour_15_synopsys_2,
    time: dataTourVi.a1_day,
    typeTour: dataTourVi.type_tour_1,
    longitude: ' 107.43095555687225',
    latitude: '11.423441301841912',
    price: {
      adults: 300000,
      children: 150000,
      peopleWithDisabilities: 100000,
      elderly: 150000,
      student: 150000,
      pupil: 100000,
    },
    note: {
      weather: [],
      skin: [],
      regulations: [dataTourVi.tour_15_notice],
    },
    listImage: [
      '/images/tour/tour_15/image_1.jpg',
      '/images/tour/tour_15/image_2.jpg',
      '/images/tour/tour_15/image_3.jpg',
      '/images/tour/tour_15/image_4.jpg',
      '/images/tour/tour_15/image_5.jpg',
      '/images/tour/tour_15/image_6.jpg',
      '/images/tour/tour_15/image_7.jpg',
      '/images/tour/tour_15/image_8.jpg',
      '/images/tour/tour_15/image_9.jpg',
      '/images/tour/tour_15/image_10.jpg',
      '/images/tour/tour_15/image_11.jpg',
      '/images/tour/tour_15/image_12.jpg',
    ],
    listAction: [
      {
        action: dataTourVi.ecological,
        image: ic_action_ecological,
      },
      {
        action: dataTourVi.education,
        image: ic_action_education,
      },
      {
        action: dataTourVi.visit,
        image: ic_action_visit,
      },
      {
        action: dataTourVi.rowing,
        image: ic_action_rowing,
      },
      {
        action: dataTourVi.car,
        image: ic_action_car,
      },
    ],
    listSchedule: [
      {
        day: '1',
        schedule: [
          {
            time: '',
            title: dataTourVi.tour_15_schedule_day_0_time1,
            description: dataTourVi.tour_15_schedule_day_0_title_1,
            image: [
              '/images/tour/tour_15/intinerary_1.jpg',
              '/images/tour/tour_15/intinerary_2.jpg',
            ],
          },
        ],
      },
    ],
  };

  const tour16 = {
    // tel: '0613.961683',
    // fax: '0613.961683',
    facebook: 'https://www.facebook.com/cattienvietnam',
    contact1: 'Email: cattienvietnam@gmail.com',
    contact2: 'Zalo: 0856 669 228 - Vườn quốc gia Cát Tiên',
    id: '116',
    address: dataTourVi.tour_16_address,
    imageThumb: '/images/tour/tour_16/avatar.jpg',
    // "https://doc-0c-8o-docs.googleusercontent.com/docs/securesc/dsrp2ij34p9u7k2r6vpu9brmvgf8jdh8/bplm1ddg4fv8ns7l3rbq01cutre1pi8f/1665591825000/12515604651516018806/12515604651516018806/1q_9eawOMrKv0wBVc8fh31aWHZDg-V-3E?ax=ALW9-sDUmoeS1Q6jCppLz-kECa8SwCKffz6sIvl-1VhPh1xUj854C0zLYTiknv2xCiKPKlrjOLbt5vzRGHgy3_2TX4GtYBfHHvB-XsQq1SDNa6O9P9T7jhJhdhZMW7AViEx0suUT-iamqYtqD_Yb7bCfenyHdnTGosdSbK9SqgxUuWD0aCubuf7lITeNfSoYjI3pTHwWnsrke75pHIyeysUxS_9m2yYdxpn04V6RYCmpePyOocEoN9IbSUzKZpict0jLk9Mr1Ys41GKD9jkZoU5j3R5SMP8Au1afqSqM12CzeT3BAJlQD9gj0jzwPeKZovBhDqaE-4L3ADHv-tsF1K2l-gCwpCa6xmkyNaj3veTELZoToJAGJP8praQxh1Kemcse1aM2sNWLE-Qb0nYiLRNCEcWIvceSMZKqj140r0IudZ0LjZ_yAWk8in8JQzXmdQBvONtP7OpahI_Sq-VVqd3bmMAuN54aZ7Abfq7tdHFQ_CcFEu5jE9vfLxk9Gg_lcfv5vVnf5yyuO2--t17vf6adi8nKVNQ2MG9GyPHksWQv6Tn1-eBbYu-nsog4QvnKZgjv5K-eSNPp31RHSKh1tVMXzNX6t_7DJsHCYo3FCy4Wa_jc52t28P9nlwLqtPH97kOgpJnc5pf0hqI9hjHIw6959HbSeRsF0cyW3nGpM4eWfnHiS3EEZCqLvswGEU9LTGvtro2G3siFMNsE0zassHsf9y0yFStQa9UBQGMMOxLXLZ-nt1wY4O5LI_cSqUwmxglgdaArNjszsFTWjZZEBASFo9NBkb8iAdU5hIiy1rs7KUGf9V2koKLyn-RtTJrcvDbMRys_AXe1EqSRyOyt22dN6UfcZpTAnJ9bIY4dtN26skR9o-V8WGz6tpJ3p2vMkB4s-_COGg4FPQoZlLnbaFk4gqKkLPpGLw07vQ&uuid=88a7f57f-31fd-4b09-8402-ada820573a17&authuser=0&nonce=2dl6r9eqhjp6q&user=12515604651516018806&hash=rd221j58v41qe6ccu6p7utkfd0dsdg5g",
    name: dataTourVi.tour_16_title,
    description1: dataTourVi.tour_16_synopsys_1,
    description2: dataTourVi.tour_16_synopsys_2,
    time: dataTourVi.a1_day,
    typeTour: dataTourVi.type_tour_1,
    longitude: ' 107.43095555687225',
    latitude: '11.423441301841912',
    price: {
      adults: 300000,
      children: 150000,
      peopleWithDisabilities: 100000,
      elderly: 150000,
      student: 150000,
      pupil: 100000,
    },
    note: {
      weather: [],
      skin: [],
      regulations: [dataTourVi.tour_16_notice],
    },
    listImage: [
      '/images/tour/tour_16/image_1.jpg',
      '/images/tour/tour_16/image_2.jpg',
      '/images/tour/tour_16/image_3.jpg',
      '/images/tour/tour_16/image_4.jpg',
      '/images/tour/tour_16/image_5.jpg',
      '/images/tour/tour_16/image_6.jpg',
      '/images/tour/tour_16/image_7.jpg',
      '/images/tour/tour_16/image_8.jpg',
      '/images/tour/tour_16/image_9.jpg',
      '/images/tour/tour_16/image_10.jpg',
      '/images/tour/tour_16/image_11.jpg',
      '/images/tour/tour_16/image_12.jpg',
    ],
    listAction: [
      {
        action: dataTourVi.ecological,
        image: ic_action_ecological,
      },
      {
        action: dataTourVi.education,
        image: ic_action_education,
      },
      {
        action: dataTourVi.visit,
        image: ic_action_visit,
      },
      {
        action: dataTourVi.hiking,
        image: ic_action_hiking,
      },
      {
        action: dataTourVi.car,
        image: ic_action_car,
      },
    ],
    listSchedule: [
      {
        day: '1',
        schedule: [
          {
            time: '',
            title: dataTourVi.tour_16_schedule_day_0_time1,
            description: dataTourVi.tour_16_schedule_day_0_title_1,
            image: [
              '/images/tour/tour_16/intinerary_1.jpg',
              '/images/tour/tour_16/intinerary_2.jpg',
              '/images/tour/tour_16/intinerary_3.jpg',
              '/images/tour/tour_16/intinerary_4.jpg',
              '/images/tour/tour_16/intinerary_5.jpg',
            ],
          },
        ],
      },
    ],
  };

  const tour17 = {
    // tel: '0613.961683',
    // fax: '0613.961683',
    contact1: 'Email: cattienvietnam@gmail.com',
    contact2: 'Zalo: 0856 669 228 - Vườn quốc gia Cát Tiên',
    id: '117',
    facebook: 'https://www.facebook.com/cattienvietnam',
    address: dataTourVi.tour_17_address,
    imageThumb: '/images/tour/tour_17/avatar.jpg',
    // "https://doc-0c-8o-docs.googleusercontent.com/docs/securesc/dsrp2ij34p9u7k2r6vpu9brmvgf8jdh8/bplm1ddg4fv8ns7l3rbq01cutre1pi8f/1665591825000/12515604651516018806/12515604651516018806/1q_9eawOMrKv0wBVc8fh31aWHZDg-V-3E?ax=ALW9-sDUmoeS1Q6jCppLz-kECa8SwCKffz6sIvl-1VhPh1xUj854C0zLYTiknv2xCiKPKlrjOLbt5vzRGHgy3_2TX4GtYBfHHvB-XsQq1SDNa6O9P9T7jhJhdhZMW7AViEx0suUT-iamqYtqD_Yb7bCfenyHdnTGosdSbK9SqgxUuWD0aCubuf7lITeNfSoYjI3pTHwWnsrke75pHIyeysUxS_9m2yYdxpn04V6RYCmpePyOocEoN9IbSUzKZpict0jLk9Mr1Ys41GKD9jkZoU5j3R5SMP8Au1afqSqM12CzeT3BAJlQD9gj0jzwPeKZovBhDqaE-4L3ADHv-tsF1K2l-gCwpCa6xmkyNaj3veTELZoToJAGJP8praQxh1Kemcse1aM2sNWLE-Qb0nYiLRNCEcWIvceSMZKqj140r0IudZ0LjZ_yAWk8in8JQzXmdQBvONtP7OpahI_Sq-VVqd3bmMAuN54aZ7Abfq7tdHFQ_CcFEu5jE9vfLxk9Gg_lcfv5vVnf5yyuO2--t17vf6adi8nKVNQ2MG9GyPHksWQv6Tn1-eBbYu-nsog4QvnKZgjv5K-eSNPp31RHSKh1tVMXzNX6t_7DJsHCYo3FCy4Wa_jc52t28P9nlwLqtPH97kOgpJnc5pf0hqI9hjHIw6959HbSeRsF0cyW3nGpM4eWfnHiS3EEZCqLvswGEU9LTGvtro2G3siFMNsE0zassHsf9y0yFStQa9UBQGMMOxLXLZ-nt1wY4O5LI_cSqUwmxglgdaArNjszsFTWjZZEBASFo9NBkb8iAdU5hIiy1rs7KUGf9V2koKLyn-RtTJrcvDbMRys_AXe1EqSRyOyt22dN6UfcZpTAnJ9bIY4dtN26skR9o-V8WGz6tpJ3p2vMkB4s-_COGg4FPQoZlLnbaFk4gqKkLPpGLw07vQ&uuid=88a7f57f-31fd-4b09-8402-ada820573a17&authuser=0&nonce=2dl6r9eqhjp6q&user=12515604651516018806&hash=rd221j58v41qe6ccu6p7utkfd0dsdg5g",
    name: dataTourVi.tour_17_title,
    description1: dataTourVi.tour_17_synopsys_1,
    description2: dataTourVi.tour_17_synopsys_2,
    time: dataTourVi.a1_day,
    typeTour: dataTourVi.type_tour_1,
    longitude: ' 107.43095555687225',
    latitude: '11.423441301841912',

    price: {
      adults: 300000,
      children: 150000,
      peopleWithDisabilities: 100000,
      elderly: 150000,
      student: 150000,
      pupil: 100000,
    },
    note: {
      weather: [],
      skin: [],
      regulations: [dataTourVi.tour_17_notice],
    },
    listImage: [
      '/images/tour/tour_17/image_1.jpg',
      '/images/tour/tour_17/image_2.jpg',
      '/images/tour/tour_17/image_3.jpg',
      '/images/tour/tour_17/image_4.jpg',
      '/images/tour/tour_17/image_5.jpg',
      '/images/tour/tour_17/image_6.jpg',
      '/images/tour/tour_17/image_7.jpg',
      '/images/tour/tour_17/image_8.jpg',
      '/images/tour/tour_17/image_9.jpg',
      '/images/tour/tour_17/image_10.jpg',
      '/images/tour/tour_17/image_11.jpg',
      '/images/tour/tour_17/image_12.jpg',
    ],
    listAction: [
      {
        action: dataTourVi.ecological,
        image: ic_action_ecological,
      },
      {
        action: dataTourVi.education,
        image: ic_action_education,
      },
      {
        action: dataTourVi.visit,
        image: ic_action_visit,
      },
      {
        action: dataTourVi.hiking,
        image: ic_action_hiking,
      },
      {
        action: dataTourVi.car,
        image: ic_action_car,
      },
    ],
    listSchedule: [
      {
        day: '1',
        schedule: [
          {
            time: '',
            title: dataTourVi.tour_17_schedule_day_0_time1,
            description: dataTourVi.tour_17_schedule_day_0_title_1,
            image: [
              '/images/tour/tour_17/intinerary_1.jpg',
              '/images/tour/tour_17/intinerary_2.jpg',
              '/images/tour/tour_17/intinerary_3.jpg',
              '/images/tour/tour_17/intinerary_4.jpg',
              '/images/tour/tour_17/intinerary_5.jpg',
            ],
          },
        ],
      },
    ],
  };

  return [
    tour,
    tour2,
    tour5,
    tour8,
    tour12,
    tour3,
    tour4,
    tour6,
    tour7,
    tour9,
    tour10,
    tour11,
    tour13,
    tour14,
    tour15,
    tour16,
    tour17,
  ];
};
