import React, { useCallback, useMemo, useState } from 'react';
import Select from 'react-select';
import Item from '../../components/Service/Item';
import styles from './style.module.scss';
import { useService } from './service';
import { Tour } from '../../models/tour';
import Pagination from '../../components/Pagination';
import { removeVietnameseTones } from '../../utils/func';
import { useTypeSafeTranslation } from '../../i18n/i18n';

const PAGE_LIMIT = 6;
const OFF_SET_DF = 0;

const Service = () => {
  const { t } = useTypeSafeTranslation();
  const { listTour, handleNavigate, listTime, listAction, listTourType } =
    useService();
  const [limit] = useState(PAGE_LIMIT);
  const [offsetDf] = useState(OFF_SET_DF);
  const [data, setData] = useState<Tour[]>([]);
  const [lstTimeSelected, setLstTimeSelected] = useState<
    { value: string; label: string }[]
  >([]);
  const [lstActionSelected, setLstActionSelected] = useState<
    { value: string; label: string }[]
  >([]);
  const [lstTourTypeSelected, setLstTourTypeSelected] = useState<
    { value: string; label: string }[]
  >([]);

  const cutDataPerPage = useCallback(
    (dataNews: Tour[], offset?: number) => {
      const valOffset = offset || offsetDf;
      setData(dataNews.slice(valOffset, valOffset + limit));
    },
    [limit, offsetDf],
  );

  useMemo(() => {
    if (listTour.length > 0) {
      cutDataPerPage(listTour);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listTour]);

  function handleChange(event: any) {
    const { value } = event.target;
    const tourFound = listTour.filter((tour) =>
      removeVietnameseTones(tour.name.toLowerCase()).includes(
        removeVietnameseTones(value.toLowerCase()),
      ),
    );
    setData(tourFound);
  }

  const handleSearch = (
    time: string[],
    typeTour: string[],
    typeAction: string[],
  ) => {
    const result: Tour[] = [];
    if (!time.length && !typeTour.length && !typeAction.length)
      return setData(listTour);
    if (typeAction.length) {
      listTour.forEach((item) => {
        const listActionFilter: string[] = item.listAction.map(
          (itemAction) => itemAction.action,
        );
        listActionFilter.forEach((itemEachAction) => {
          if (typeAction.includes(itemEachAction)) {
            if (!result.includes(item)) result.push(item);
          }
        });
      });
    }
    const resultTour = listTour.filter(
      (item) => time.includes(item.time) || typeTour.includes(item.typeTour),
    );
    // eslint-disable-next-line no-debugger
    setData([...result, ...resultTour]);
  };
  return (
    <div className={styles.service}>
      <div className={styles.background}>
        <img src="/images/P22.png" alt="p22" />
      </div>
      <div className={styles.service__wrap}>
        <div className={styles.search}>
          <div className={styles.search__field}>
            <input
              type="text"
              placeholder={t('pages/Service.keyword')}
              onChange={handleChange}
              className={styles.inputSearch}
            />
          </div>
          <div className={styles.search__field}>
            <p>{t('pages/Service.time')}</p>
            <Select
              name="filters"
              placeholder={t('pages/Service.keyword')}
              options={listTime}
              value={lstTimeSelected}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onChange={(newValue) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setLstTimeSelected(newValue);
              }}
              isMulti
              isClearable
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderRadius: '20px',
                }),
              }}
            />
          </div>
          <div className={styles.search__field}>
            <p>{t('pages/Service.duration_of_stay')}</p>
            <Select
              name="filters"
              placeholder={t('pages/Service.keyword')}
              options={listTourType}
              value={lstTourTypeSelected}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onChange={(newValue) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setLstTourTypeSelected(newValue);
              }}
              isMulti
              isClearable
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderRadius: '20px',
                }),
              }}
            />
          </div>
          <div className={styles.search__field}>
            <p>{t('pages/Service.activity')}</p>
            <Select
              name="filters"
              placeholder={t('pages/Service.keyword')}
              options={listAction}
              value={lstActionSelected}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onChange={(newValue) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setLstActionSelected(newValue);
              }}
              isMulti
              isClearable
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderRadius: '20px',
                }),
              }}
            />
          </div>
          <div className={styles.search__field}>
            <button
              type="submit"
              className={styles.searchBtn}
              onClick={() => {
                const lstTime = lstTimeSelected.map((item) => item.value);
                const lstTour = lstTourTypeSelected.map((item) => item.value);
                const lstAction = lstActionSelected.map((item) => item.value);
                handleSearch(lstTime, lstTour, lstAction);
              }}
            >
              {t('pages/Service.search')}
            </button>
          </div>
        </div>
        <div className={styles.content}>
          {data.map((item) => {
            return (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
              <div
                className={styles.item}
                key={item.id}
                onClick={() => handleNavigate(Number(item.id))}
              >
                <Item
                  imageUrl={item.imageThumb}
                  title={item.name}
                  handleNavigate={handleNavigate}
                  id={Number(item.id)}
                />
              </div>
            );
          })}
        </div>
        <Pagination
          pageCount={Math.ceil(listTour.length / limit)}
          onPageChange={(selected: number) => {
            cutDataPerPage(listTour, selected * limit);
            window.scrollTo(0, 0);
          }}
        />
      </div>

      <div className={styles.backgroundBottom}>
        <img src="/images/maskGroup.png" alt="backgroundNews" />
      </div>
    </div>
  );
};

export default Service;
