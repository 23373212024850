import React from 'react';
import { useTranslation } from 'react-i18next';
import Hexagon from './Hexagon';
import Item from './Item';
import styles from './style.module.scss';

const Tours = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.tours}>
        <div className={styles.wrap}>
          <h2>{t('tours.world_biosphere_reserve')}</h2>
          <div className={styles.content}>
            <p>
              <span>{t('tours.world_biosphere_reserve')} </span>{' '}
              {t('tours.content1')}
              <span>{t('tours.world_biosphere_reserve')} </span>{' '}
              {t('tours.content2')}
            </p>
          </div>
          <div className={styles.function}>
            <p>{t('tours.functions_world_biosphere_reserve')}</p>
            <div className={styles.listFunc}>
              <Item
                text={t('tours.conserve')}
                des={t('tours.conserve_explanation')}
                img="bodoi.png"
              />
              <Item
                text={t('tours.develop')}
                des={t('tours.develop_explanation')}
                img="nhasan1.png"
              />
              <Item
                text={t('tours.support')}
                des={t('tours.support_explanation')}
                img="nhasan.png"
              />
            </div>
            <div className={styles.criteria}>
              <p>{t('tours.07_criteria')}</p>
              <ul>
                <li>
                  <span>1</span>
                  <p>{t('tours.1nd_criterion')}</p>
                </li>
                <li>
                  <span>2</span>
                  <p>{t('tours.2nd_criterion')}</p>
                </li>
                <li>
                  <span>3</span>
                  <p>{t('tours.3nd_criterion')}</p>
                </li>
                <li>
                  <span>4</span>
                  <p>{t('tours.4nd_criterion')}</p>
                </li>
                <li>
                  <span>5</span>
                  <p>{t('tours.5nd_criterion')}</p>
                  <ul>
                    <li>+ {t('tours.5nd_criterion_1')}</li>
                    <li>+ {t('tours.5nd_criterion_2')}</li>
                    <li>+ {t('tours.5nd_criterion_3')}</li>
                  </ul>
                </li>
                <li>
                  <span>6</span>
                  <p>{t('tours.6nd_criterion')}</p>
                </li>
                <li>
                  <span>7</span>
                  <p>{t('tours.7nd_criterion')}</p>
                </li>
              </ul>
            </div>
            <div className={styles.hexagon}>
              <p>{t('tours.structure_world_biosphere_reserve')}</p>
              <div className={styles.top}>
                <Hexagon
                  text={t('tours.core_area')}
                  des={t('tours.core_area_explanation')}
                  img="vungloi.png"
                  id="1"
                />
              </div>
              <div className={styles.bottom}>
                <Hexagon
                  text={t('tours.buffer_zone')}
                  des={t('tours.buffer_zone_explanation')}
                  img="vungdem.png"
                  id="2"
                />
                <Hexagon
                  text={t('tours.transition_zone')}
                  des={t('tours.transition_zone_explanation')}
                  img="chuyentiep.png"
                  id="3"
                />
              </div>
            </div>
            <div className={styles.table}>
              <div className={styles.top}>
                <p className={styles.title}>
                  {t('tours.network_of_World_heritage')}
                </p>
                <p>- {t('tours.network_of_World_heritage_content1')}</p>
                <ul>
                  <li>● {t('tours.network_of_World_heritage_content1_1')}</li>
                  <li>● {t('tours.network_of_World_heritage_content1_2')}</li>
                  <li>● {t('tours.network_of_World_heritage_content1_3')}</li>
                  <li>● {t('tours.network_of_World_heritage_content1_4')}</li>
                  <li>● {t('tours.network_of_World_heritage_content1_5')}</li>
                </ul>
              </div>
              <div className={styles.bottom}>
                <p className={styles.title}>
                  {t('tours.world_heritage_sites_vietnam')}
                </p>
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: '10%' }}>{t('tours.col_STT')}</th>
                      <th style={{ width: '30%' }}>{t('tours.col_name')}</th>
                      <th style={{ width: '25%' }}>
                        {t('tours.col_recognition_date')}
                      </th>
                      <th>{t('tours.col_area')}</th>
                      <th>{t('tours.col_administrative_boundaries')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>{t('tours.row_1_name')}</td>
                      <td>21/01/2000</td>
                      <td>71.370</td>
                      <td>{t('tours.row_1_city')}</td>
                    </tr>
                    <tr>
                      <td>
                        <p className={styles.textBold}>2</p>
                      </td>
                      <td>
                        <p className={styles.textBold}>
                          {t('tours.row_2_name')}
                        </p>
                      </td>
                      <td>
                        <p className={styles.textBold}>
                          {t('tours.row_2_date_1')}: 10/11/2001
                        </p>
                        <p className={styles.textBold}>
                          {t('tours.row_2_date_2')}: 29/06/2011
                        </p>
                      </td>
                      <td>
                        <p className={styles.textBold}>756.000</p>
                      </td>
                      <td>
                        <p className={styles.textBold}>
                          {t('tours.row_2_city')}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>{t('tours.row_3_name')}</td>
                      <td>02/12/2004</td>
                      <td>26.241</td>
                      <td>{t('tours.row_3_city')}</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>{t('tours.row_4_name')}</td>
                      <td>20/12/2004</td>
                      <td>105.558</td>
                      <td>{t('tours.row_4_city')}</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>{t('tours.row_5_name')}</td>
                      <td>27/10/2006</td>
                      <td>1.188,104</td>
                      <td>{t('tours.row_5_city')}</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>{t('tours.row_6_name')}</td>
                      <td>18/9/2007</td>
                      <td>1.303,205</td>
                      <td>{t('tours.row_6_city')}</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>{t('tours.row_7_name')}</td>
                      <td>26/5/2009</td>
                      <td>371.306</td>
                      <td>{t('tours.row_7_city')}</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>{t('tours.row_8_name')}</td>
                      <td>26/05/2009</td>
                      <td>33.146</td>
                      <td>{t('tours.row_8_city')}</td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>{t('tours.row_9_name')}</td>
                      <td>09/06/2015</td>
                      <td>275.439</td>
                      <td>{t('tours.row_9_city')}</td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>{t('tours.row_10_name')}</td>
                      <td>15/09/2021</td>
                      <td>106.646,45</td>
                      <td>{t('tours.row_10_city')}</td>
                    </tr>
                    <tr>
                      <td>11</td>
                      <td>{t('tours.row_11_name')}</td>
                      <td>15/09/2021</td>
                      <td>413.512</td>
                      <td>{t('tours.row_11_city')}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tours;
