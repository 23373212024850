/* eslint-disable react/no-danger */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useService } from './service';
import styles from './style.module.scss';

const DetailAnimal = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { animal } = useService({ id: location.state?.id });
  return (
    <div className={styles.news}>
      <div className={styles.news__wrap}>
        <div className={styles.background}>
          <p>
            <span>
              {t('pages/DetailAnimal.library')} {'>'}
              {location.state?.name || t('pages/DetailAnimal.library')}
            </span>
            {location.state?.title || ' HỌ: NA - ANNONACEAE'}
          </p>
          <img src="/images/tree-bg.png" alt="backgroundNews" />
        </div>
        <div className={styles.content}>
          <img src={animal?.Image} alt="" />
          <h2>{animal?.Name}</h2>
          <ul>
            <li>
              <img src="./images/like-png.png" alt="" />
            </li>
            <li>
              <img src="./images/ShareNetwork.png" alt="" />
            </li>
            <li>
              <img src="./images/Printer.png" alt="" />
            </li>
          </ul>
          <div
            dangerouslySetInnerHTML={{ __html: animal?.Description ?? '' }}
          />
        </div>
      </div>
      <div className={styles.backgroundBottom}>
        <img src="/images/maskGroup.png" alt="backgroundNews" />
      </div>
    </div>
  );
};

export default DetailAnimal;
