import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Tabs from '../../components/DetailService/Tabs';
import styles from './style.module.scss';
import { useService } from './service';

const DetailService = () => {
  const { currentTour } = useService();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const clickPayment = () => {
    navigate('/thanh-toan', { state: currentTour });
  };

  return (
    <div className={styles.service}>
      <div className={styles.background}>
        <img src="/images/backgroundService.png" alt="p21" />
      </div>
      <div className={styles.service__wrap}>
        <div className={styles.content}>
          <div className={styles.title}>
            <h2>
              {currentTour?.name}
              {/* {`${t('pages/DetailService.address')} ${currentTour?.address}`} */}
            </h2>
            <button type="button" onClick={clickPayment}>
              {t('pages/DetailService.book_now')}
            </button>
          </div>
          <ul className={styles.detail}>
            <li>
              <i>
                <img src="/images/MapPinLine.svg" alt="" />
              </i>
              {`${t('pages/DetailService.address')}: ${currentTour?.address}`}
            </li>
            <li>
              <i>
                <img src="/images/hiking.svg" alt="" />
              </i>
              {t('pages/DetailService.hiking')}
            </li>
            <li>
              <i>
                <img src="/images/MapTrifold.svg" alt="" />
              </i>
              {t('pages/DetailService.time')}
            </li>
            <li>
              <i>
                <img src="/images/Leaf.svg" alt="" />
              </i>
              {t('pages/DetailService.discovery')}
            </li>
          </ul>
          <ul className={styles.listService}>
            <li>
              <i>
                <img src="/images/Campfire.svg" alt="" />
              </i>
              <p>{t('pages/DetailService.camping')}</p>
            </li>
            <li>
              <i>
                <img src="/images/Binoculars.svg" alt="" />
              </i>
              <p>{t('pages/DetailService.sightseeing')}</p>
            </li>
            <li>
              <i>
                <img src="/images/Bicycle.svg" alt="" />
              </i>
              <p>{t('pages/DetailService.cycling')}</p>
            </li>
            <li>
              <i>
                <img src="/images/Leaf.svg" alt="" />
              </i>
              <p>{t('pages/DetailService.cycling')}</p>
            </li>
            <li>
              <i>
                <img src="/images/history.svg" alt="" />
              </i>
              <p>{t('pages/DetailService.ecological')}</p>
            </li>
            <li>
              <i>
                <img src="/images/Users.svg" alt="" />
              </i>
              <p>{t('pages/DetailService.history')}</p>
            </li>
            <li>
              <i>
                <img src="/images/Student.svg" alt="" />
              </i>
              <p>{t('pages/DetailService.community')}</p>
            </li>
            <li>
              <i>
                <img src="/images/hiking.svg" alt="" />
              </i>
              <p>{t('pages/DetailService.education')}</p>
            </li>
            <li>
              <i>
                <img src="/images/swimming.svg" alt="" />
              </i>
              <p>{t('pages/DetailService.swim')}</p>
            </li>
            <li>
              <i>
                <img src="/images/thuyen.svg" alt="" />
              </i>
              <p>{t('pages/DetailService.rowing')}</p>
            </li>
            <li>
              <i>
                <img src="/images/song.svg" alt="" />
              </i>
              <p>{t('pages/DetailService.wading')}</p>
            </li>
          </ul>
          <ul className={styles.gallery}>
            {currentTour?.listImage.map((image, index) => {
              return (
                <li key={Number(index)}>
                  <div className={styles.img}>
                    <img src={image} alt="" />
                  </div>
                </li>
              );
            })}
          </ul>
          <div className={styles.tabs}>
            <Tabs
              des1={currentTour?.description1 ?? ''}
              des2={currentTour?.description2 ?? ''}
              listSchedule={currentTour?.listSchedule}
              note={currentTour?.note}
              currentTour={currentTour}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailService;
