import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '../../Button';
import Title from '../Title';
import styles from './style.module.scss';

const Donate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div
      className={styles.animal}
      style={{ backgroundImage: `url("./images/backgroundAnimal.png")` }}
    >
      <div className={styles.animal__wrap}>
        <div className={styles.top}>
          <div className={styles.title}>
            <Title
              title={t('donate.join_hands')}
              subTitle={t('donate.to_protect')}
              background={false}
              reverse
            />
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.left}>
            <img src="/images/khi.png" alt="khi" />
          </div>
          <div className={styles.right}>
            <div className={styles.listImage}>
              <img src="/images/vet.png" alt="" />
              <img src="/images/gau.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <img className={styles.glass} src="./images/glass.png" alt="glass" />
    </div>
  );
};

export default Donate;
