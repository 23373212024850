import React from 'react';
import styles from './style.module.scss';

const Button: React.FC<{
  text: string;
  onclick?: () => void;
  type?: 'black' | 'green';
}> = ({ text, type, onclick }) => {
  return (
    <button
      className={type === 'black' ? styles.buttonBlack : styles.button}
      type="button"
      onClick={onclick}
    >
      {text}
      <span style={{ backgroundImage: `url("./images/yellow.png")` }}>
        <img src="/images/ArrowRight.png" alt="ArrowRight" />
      </span>
    </button>
  );
};

export default Button;
