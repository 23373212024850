import React, { useEffect } from 'react';
import { useLocation, Route, Routes, useNavigate } from 'react-router-dom';
import AOS from 'aos';
import Footer from './components/Footer';
import Header from './components/Header';
import Aboutme from './pages/Aboutme';
import Animal from './pages/Animal';
import Contact from './pages/Contact';
import Detail from './pages/Detail';
import DetailAnimal from './pages/DetailAnimal';
import DetailService from './pages/DetailService';
import Gallery from './pages/Gallery';

import Home from './pages/Home';
import News from './pages/News';
import Payment from './pages/Payment';
import Payment2 from './pages/Payment2';
import Service from './pages/Service';
import Documents from './pages/Documents';
import Donate from './pages/Donate';
import 'aos/dist/aos.css';

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    console.log(location.pathname);

    if (location.pathname === '/') navigate('trang-chu');
  }, [location.pathname]);

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <Header />
      <Routes>
        <Route path="/trang-chu" element={<Home />} />
        <Route path="/aboutme" element={<Aboutme />} />
        <Route path="/tin-tuc" element={<News />} />
        <Route path="/chi-tiet/:id" element={<Detail />} />
        <Route path="/dich-vu" element={<Service />} />
        <Route path="/dich-vu/:id" element={<DetailService />} />
        <Route path="/lien-he" element={<Contact />} />
        <Route path="/thanh-toan" element={<Payment />} />
        <Route path="/hoan-thanh" element={<Payment2 />} />
        <Route path="/thu-vien" element={<Gallery />}>
          <Route path="thuc-vat" element={<Animal />} />
          <Route path="dong-vat" element={<Animal />} />
          <Route path="sinh-canh-nui-rung" element={<Animal />} />
          <Route path="sinh-canh-song-nuoc" element={<Animal />} />
        </Route>
        <Route path="/thu-vien/tai-lieu-van-ban" element={<Documents />} />
        <Route path="/chi-tiet-dong-vat" element={<DetailAnimal />} />
        <Route path="/chi-tiet-thuc-vat" element={<DetailAnimal />} />
        <Route path="/donate" element={<Donate />} />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
