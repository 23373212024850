import classNames from 'classnames';
import React from 'react';
import styles from './style.module.scss';

const Title: React.FC<{
  title: string;
  subTitle: string;
  background: boolean;
  reverse: boolean;
  border?: boolean;
}> = ({ title, subTitle, background, reverse, border = true }) => {
  return (
    <div
      className={classNames(styles.title, reverse ? styles.reverse : '')}
      style={{ fontSize: border ? '30px' : '23px' }}
    >
      <div className={styles.wrap}>
        <p dangerouslySetInnerHTML={{ __html: title }} />
        {border ? (
          <span className={styles.yellowText}>{subTitle}</span>
        ) : (
          <span className={styles.yellowTextNoBorder}>{subTitle}</span>
        )}
        {background && (
          <img
            className={styles.backgroundTitle}
            src="./images/backgroundTitle.png"
            alt="backgroundTitle"
          />
        )}
      </div>
    </div>
  );
};

export default Title;
