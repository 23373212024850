import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Item from '../../components/News/Item';
import styles from './style.module.scss';
import { useService } from './service';

const Detail = () => {
  const { news, newsOther } = useService();
  const { t } = useTranslation();

  return (
    <div className={styles.news}>
      <div className={styles.background}>
        <p>{t('pages/News.home_news')}</p>
        <img src="/background/newBg.jpg" alt="newBg" />
      </div>
      <div className={styles.news__wrap}>
        <div className={styles.content}>
          <div className={styles.left}>
            <img src={news?.Image} alt="" />
            <h2 className={styles.title}>{news?.Name}</h2>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  news?.Description.replaceAll(
                    'https://dongnaibr.vn',
                    'http://beta.dongnaibr.vn',
                  ) ?? '',
              }}
            />
            <div className={styles.listIcon}>
              <img src="/background/facebook.svg" alt="facebook" />
              <img src="/background/share.svg" alt="share" />
            </div>
            <p>{t('news.news_other')}</p>
            <ul className={styles.category}>
              {newsOther?.DataList.length &&
                newsOther?.DataList.map((item) => {
                  if (!item.Name) return;
                  return (
                    <li key={item.ID}>
                      <a href={`/chi-tiet/${item.ID}`}>{item.Name}</a>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.backgroundBottom}>
        <img src="/images/maskGroup.png" alt="backgroundNews" />
      </div>
    </div>
  );
};

export default Detail;
