import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { setLanguage } from '../../reducer/languageReducer';
import styles from './style.module.scss';
import useComponentVisible from '../../utils/useComponentVisible';

interface Language {
  [key: string]: string;
}

const Header = () => {
  const [isShowNav, setShowNav] = useState(false);
  const [imgLeng, setImgLeng] = useState('/images/vie.svg');
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const languageImg: Language = {
    vn: '/images/vie.svg',
    en: '/images/eng.svg',
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem('lang');
    if (storedLanguage) {
      setImgLeng(languageImg[storedLanguage]);
    } else {
      localStorage.setItem('lang', 'vn');
      i18n.changeLanguage('vn');
      dispatch(setLanguage('vn'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setShowNav(false);
  }, [location]);

  const handleLanguage = (lan: string, img: string) => {
    setImgLeng(languageImg[lan]);
    i18n.changeLanguage(lan);
    localStorage.setItem('lang', lan);
    dispatch(setLanguage(lan));
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.stopPropagation();
      const query = event.target.value;
      navigate(`/tin-tuc/?search=${query.toLowerCase()}`);
      setIsComponentVisible(false);
    }
  };

  return (
    <>
      <div className={styles.top} />
      <div className={styles.header}>
        <div className={styles.header__wrap}>
          <div className={styles.logo}>
            <div
              onClick={() => navigate('/')}
              onKeyDown={() => navigate('/')}
              role="button"
              tabIndex={0}
            >
              <img src="/images/dongnai.png" alt="dongnai" />
            </div>
            <div
              className={styles.navMobile}
              onClick={() => setShowNav(!isShowNav)}
              onKeyDown={() => setShowNav(!isShowNav)}
              role="button"
              tabIndex={0}
            >
              <div className={styles.hamburger}>
                <span
                  className={classnames(
                    styles.line,
                    styles.line1,
                    isShowNav && styles.active,
                  )}
                />
                <span
                  className={classnames(
                    styles.line,
                    styles.line2,
                    isShowNav && styles.active,
                  )}
                />
                <span
                  className={classnames(
                    styles.line,
                    styles.line3,
                    isShowNav && styles.active,
                  )}
                />
              </div>
            </div>
          </div>
          <div className={classnames(styles.menu, isShowNav && styles.active)}>
            <div className={styles.menu__wrap}>
              <NavLink
                to="/"
                className={({ isActive }) =>
                  classnames(isActive ? styles.active : '', styles.link)
                }
              >
                {t('header.home')}
              </NavLink>
              <NavLink
                to="/aboutme"
                className={({ isActive }) =>
                  classnames(isActive ? styles.active : '', styles.link)
                }
              >
                {t('header.about_us')}
              </NavLink>
              <NavLink
                to="/tin-tuc"
                className={({ isActive }) =>
                  classnames(isActive ? styles.active : '', styles.link)
                }
              >
                {t('header.news')}
              </NavLink>
              <div className={styles.link}>
                {t('header.library')}
                <div className={styles.child}>
                  <NavLink
                    to="/thu-vien/thuc-vat"
                    className={({ isActive }) =>
                      classnames(
                        isActive ? styles.active : '',
                        styles.child__link,
                      )
                    }
                  >
                    {t('header.plants')}
                  </NavLink>
                  <NavLink
                    to="/thu-vien/dong-vat"
                    className={({ isActive }) =>
                      classnames(
                        isActive ? styles.active : '',
                        styles.child__link,
                      )
                    }
                  >
                    {t('header.animals')}
                  </NavLink>
                  <NavLink
                    to="/thu-vien/sinh-canh-nui-rung"
                    className={({ isActive }) =>
                      classnames(
                        isActive ? styles.active : '',
                        styles.child__link,
                      )
                    }
                  >
                    {t('header.landscape')}
                  </NavLink>
                  <NavLink
                    to="/thu-vien/sinh-canh-song-nuoc"
                    className={({ isActive }) =>
                      classnames(
                        isActive ? styles.active : '',
                        styles.child__link,
                      )
                    }
                  >
                    {t('header.culture_history')}
                  </NavLink>
                  <NavLink
                    to="/thu-vien/tai-lieu-van-ban"
                    className={({ isActive }) =>
                      classnames(
                        isActive ? styles.active : '',
                        styles.child__link,
                      )
                    }
                  >
                    {t('header.document')}
                  </NavLink>
                </div>
              </div>
              <NavLink
                to="/dich-vu"
                className={({ isActive }) =>
                  classnames(isActive ? styles.active : '', styles.link)
                }
              >
                {t('header.travel')}
              </NavLink>
              <NavLink
                to="/lien-he"
                className={({ isActive }) =>
                  classnames(isActive ? styles.active : '', styles.link)
                }
              >
                {t('header.contact')}
              </NavLink>
              <NavLink
                to="/donate"
                className={({ isActive }) =>
                  classnames(isActive ? styles.active : '', styles.link)
                }
              >
                {t('header.donate')}
              </NavLink>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <div
              className={styles.searchIcon}
              onClick={() => setIsComponentVisible(true)}
              onKeyDown={() => setIsComponentVisible(true)}
              role="button"
              tabIndex={0}
            >
              <img src="/background/search.svg" alt="search" />
              {isComponentVisible && (
                <div className={styles.inputSearch} ref={ref}>
                  <input
                    type="text"
                    placeholder="Search"
                    onKeyDown={handleKeyDown}
                  />
                </div>
              )}
            </div>
            {/* <div className={styles.search}>
              <button type="button" onClick={() => navigate('/donate')}>
                DONATE
              </button>
            </div> */}
            <div className={styles.lang}>
              <img src={imgLeng} alt="lang" />
              <ul>
                <li
                  tabIndex={-1}
                  role="presentation"
                  onClick={() => handleLanguage('en', '/images/eng.svg')}
                >
                  <img src="/images/eng.svg" alt="" />
                </li>
                <li
                  tabIndex={-1}
                  role="presentation"
                  onClick={() => handleLanguage('vn', '/images/vie.svg')}
                >
                  <img src="/images/vie.svg" alt="" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;
