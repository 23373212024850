import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import GalleryItem from '../../components/GalleryItem';
import styles from './style.module.scss';
import { useService } from './service';
import { Animals } from '../../models/animals';
import Pagination from '../../components/Pagination';
import { TNew } from '../../models/news';

const PAGE_LIMIT = 12;
const OFF_SET_DF = 0;

const Animal = () => {
  const [limit] = useState(PAGE_LIMIT);
  const [offsetDf] = useState(OFF_SET_DF);
  const [data, setData] = useState<TNew[]>([]);
  const [type, setType] = useState('');
  const { t } = useTranslation();

  const location = useLocation();
  const { animals, handleNavigate, animalItem } = useService({
    categoryId: location.state?.id,
    title: location.state?.title,
  });

  const cutDataPerPage = useCallback(
    (dataNews: TNew[], offset?: number) => {
      const valOffset = offset || offsetDf;
      setData(dataNews.slice(valOffset, valOffset + limit));
    },
    [limit, offsetDf],
  );
  useMemo(() => {
    if (!animalItem?.DataList?.length) {
      setData([]);
    }
    if (animalItem?.DataList?.length) {
      cutDataPerPage(animalItem.DataList);
      setType('animalItem');
    } else if (animals?.length) {
      cutDataPerPage(animals);
      setType('animal');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animals, animalItem]);
  return (
    <>
      {data.length > 0 &&
        data.map((animal) => (
          <GalleryItem
            image={animal.Image}
            title={animal.Name}
            id={animal.ID}
            key={animal.ID}
            handleNavigate={handleNavigate}
            type={type}
          />
        ))}
      {data.length > 0 && (
        <Pagination
          pageCount={Math.ceil(
            (type === 'animal'
              ? animals?.length || 0
              : animalItem?.DataList?.length || 0) / limit,
          )}
          onPageChange={(selected: number) => {
            cutDataPerPage(
              type === 'animal' ? animals ?? [] : animalItem?.DataList ?? [],
              selected * limit,
            );
            window.scrollTo(0, 0);
          }}
        />
      )}
      {!data.length && (
        <div className={styles.noData}>
          {t('pages/animals.content_updated')}
        </div>
      )}
    </>
  );
};

export default Animal;
