import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Middleware,
  Store,
} from 'redux';
import thunkMiddleware from 'redux-thunk';
import { languageReducer } from './languageReducer';

// eslint-disable-next-line @typescript-eslint/ban-types
export type AppState = {};

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const defaultMiddlewares = [thunkMiddleware];

const composedMiddlewares = (middlewares: Middleware[]) =>
  compose(applyMiddleware(...defaultMiddlewares, ...middlewares));

const rootReducer = combineReducers<AppState>({
  language: languageReducer,
});
export type RootState = ReturnType<typeof rootReducer>;

/* eslint-disable no-underscore-dangle */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const initialize = (initialState?: AppState, middlewares = []): Store =>
  createStore(
    rootReducer,
    initialState,
    composeEnhancers(composedMiddlewares(middlewares)),
  );
/* eslint-enable */

export default initialize;
