import React from 'react';
import styles from './style.module.scss';

const Item: React.FC<{ text: string; des: string; img: string }> = ({
  text,
  des,
  img,
}) => {
  return (
    <div className={styles.item}>
      <p className={styles.text}>{text}</p>
      <img src={`./images/${img}`} alt={img} />
      <p className={styles.des}>{des}</p>
    </div>
  );
};

export default Item;
