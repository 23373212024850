/* eslint-disable react/no-danger */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { contacts } from './Contact.state';
import styles from './style.module.scss';

const Contact = () => {
  const { t } = useTranslation();
  const renderContact = (contact: {
    name: any;
    address: any;
    email?: string;
    phone?: string;
    img: string;
  }) => {
    return (
      <div className={styles.contactItem} key={contact.name}>
        <h2 className={styles.contactTitle}>{t(contact.name)}</h2>
        <div className={styles.contactWrapItem}>
          <div className={styles.contactLeft}>
            <ul className={styles.contactList}>
              <li>
                <i>
                  <img src="/images/MapPinLine.svg" alt="" />
                </i>
                <span>{t('tab.address')}:&nbsp; </span> {t(contact.address)}
              </li>
              <li>
                <i>
                  <img src="/images/EnvelopeSimple.svg" alt="" />
                </i>
                <span>{t('tab.email')}:&nbsp; </span> dongnaibr@gmail.com
              </li>
              <li>
                <i>
                  <img src="/images/headphone.svg" alt="" />
                </i>
                <span>{t('tab.online_support')}:&nbsp; </span>
                <ul className={styles.contactList__child}>
                  <li>
                    <i>
                      <img src="/images/zalo.svg" alt="" />
                      zalo
                    </i>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/dongnaibiospherereserve">
                      <i>
                        <img src="/images/facebook.svg" alt="" />
                        facebook
                      </i>
                    </a>
                  </li>
                  <li>
                    <i>
                      <img src="/images/messenger.svg" alt="" />
                      messenger
                    </i>
                  </li>
                  <li>
                    <i>
                      <img src="/images/phone.svg" alt="" />
                      (+84) 2513 961
                    </i>
                  </li>
                </ul>
              </li>
              <li>
                <i>
                  <img src="/images/ShareNetworkBlack.svg" alt="" />
                </i>
                <span>{t('tab.share_tour')}:&nbsp; </span>{' '}
                <ul className={styles.contactList__child}>
                  <li>
                    <i>
                      <img src="/images/ShareNetwork.svg" alt="" />
                      {t('tab.share_information')}
                    </i>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className={styles.contactRight}>
            <img src={contact.img} alt="" />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.contact}>
      <div className={styles.contact__wrap}>
        <div className={styles.background}>
          <p>
            {t('header.home')} {'>'} {t('header.contact')}
          </p>
          <img src="/images/contact-images.png" alt="backgroundNews" />
        </div>
        <div className={styles.contacts}>
          {contacts && contacts.map((contact) => renderContact(contact))}
        </div>
      </div>
    </div>
  );
};

export default Contact;
