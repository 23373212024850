import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '../../Button';
import Title from '../Title';
import styles from './style.module.scss';

const Travel = () => {
  const { t } = useTranslation();
  const nagigate = useNavigate();

  const onClick = () => {
    nagigate('/dich-vu');
  };

  return (
    <div className={styles.travel}>
      <div className={styles.travel__wrap}>
        <div className={styles.left}>
          <div className={styles.title}>
            <Title
              title={t('travel.experience_our_tourism_services')}
              subTitle={t('travel.biosphere_reserve')}
              background={false}
              reverse={false}
            />
          </div>
          <div className={styles.button}>
            <Button text={t('travel.book_now')} onclick={onClick} />
          </div>
        </div>
        <div className={styles.right}>
          <img src="/images/ellipse.png" alt="ellipse" />
          <img src="/images/ellipse1.png" alt="ellipse" />
        </div>
      </div>
    </div>
  );
};

export default Travel;
