import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../Button';
import styles from './style.module.scss';
import { TNew } from '../../../models/news';

type Props = {
  news: TNew;
  handleNavigate: (id: number) => void;
};
const Item = ({ news, handleNavigate }: Props) => {
  const { t } = useTranslation();
  return (
    <div
      className={styles.item}
      onClick={() => handleNavigate(news.ID)}
      onKeyDown={() => handleNavigate(news.ID)}
      role="button"
      tabIndex={0}
    >
      <div className={styles.image}>
        <img
          src={news.Image.replace(
            'https://dongnaibr.vn',
            'http://beta.dongnaibr.vn',
          )}
          alt="dongnai"
        />
      </div>
      <div data-aos="fade-right">
        <div className={styles.titleItem}>
          <p>{news.Name}</p>
        </div>
      </div>
      <div className={styles.line} />
      <div data-aos="fade-left">
        <div className={styles.des}>{news.Description}</div>
      </div>
      <div className={styles.button}>
        <Button
          onclick={() => handleNavigate(news.ID)}
          text={t('info.detail')}
        />
      </div>
    </div>
  );
};

export default Item;
